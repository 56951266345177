import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { ContentCopy, Edit, Power, PowerOff } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { Button, Popconfirm } from "antd";
import { MaterialReactTable, MRT_TableInstance, useMaterialReactTable } from "material-react-table";
import React, { useEffect, useState } from "react";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import { Updater, VisibilityState } from "@tanstack/table-core";
import {
  useRouterRuleTableColumns,
} from "@/module/room-configs-module/router-config-module/rule-table/router-rule-table-columns";
import {
  useSysCfgChangeEnabledMutation,
  useSysCfgCreateMutation,
  useSysCfgDeleteMutation,
  useSysCfgQuery,
  useSysCfgUpdateMutation,
} from "@/api/hooks/sys-cfg-hooks";
import {
  ChangeCfgEnabledDomainEnum,
  CreateCfgDomainEnum,
  GetAllCfgsDomainEnum,
  ResultDtoLong,
  SysCfgRegularDto,
} from "@/shared/api";
import { enqueueSnackbar } from "notistack";
import RouterEditModal from "@/components/config-modals/router-edit-modal/router-edit-modal";
import { EditedRoute } from "@/components/config-modals/router-edit-modal/types";
import BulkOperationReportModal from "@/components/bulk-operation-report-modal";
import { useCfgPerm, useIsOwnerOrSuperAdmin } from "@/auth/hooks";
import { assembleNamesFilter, mapSysCfgToEditRoute } from "@/lib/cfg-utils";
import ApplyAuthGroupsTableControl from "@/components/apply-auth-groups-table-control";

const RouterRuleTable = (
  {
    setRowsAmount,
  } : {
    setRowsAmount: (amount: number) => void;
  }
) => {
  const {data: configsRs, isLoading: configsIsLoading, refetch: refetchConfigs} = useSysCfgQuery({
    domain: ROOM_NAMES.SERVICES as GetAllCfgsDomainEnum,
    config_name: ROOM_CONFIGS_NAMES.ROUTER,
    sort: [{key: "data.priority", mode: "ASC"}]
  });
  const cfgPerm = useCfgPerm({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.ROUTER,
  });

  const isOwner = useIsOwnerOrSuperAdmin();

  useEffect(() => {
    setRowsAmount(configsRs?.data.content?.length ?? 0)
  }, [configsRs, setRowsAmount])

  const { mutate: createCfgMutate } = useSysCfgCreateMutation();
  const { mutate: changeEnabledMutate } = useSysCfgChangeEnabledMutation();
  const { mutate: deleteCfgsMutate } = useSysCfgDeleteMutation();
  const { mutate: updateCfgMutate } = useSysCfgUpdateMutation();

  const [bulkOperationResult, setBulkOperationResult] = useState<ResultDtoLong[] | undefined>(undefined);


  //This logic for reset row selection while using filtering/grouping
  const [columnFilters, setColumnFilters] = useState<any>([]);
  const [grouping, setGrouping] = useState<any>([]);
  const [globalFilter, setGlobalFilter] = useState<any>(undefined);

  const [modalMode, setModalMode] = useState<"edit" | "add" | undefined>(undefined);
  const [editItemName, setEditItemName] = useState<string>("");
  const [modalInitValues, setModalInitValues] = useState<SysCfgRegularDto | undefined>(undefined);


  //Storing table setting into localStorage
  const loadTablesSettingsFromLocalStorage = () => {
    const item = localStorage.getItem("tablesSettings");
    return item ? JSON.parse(item) : {};
  }

  const [allTablesUserSetting, setAllTablesUserSetting] = useState<any>(() => loadTablesSettingsFromLocalStorage());

  useEffect(() => {
    localStorage.setItem("tablesSettings", JSON.stringify(allTablesUserSetting))
  }, [allTablesUserSetting]);


  function handleClickSaveNewConfig(config: EditedRoute) {
    createCfgMutate({
      domain: ROOM_NAMES.SERVICES as CreateCfgDomainEnum,
      cfgName: ROOM_CONFIGS_NAMES.ROUTER,
      body: {
        ...config,
        auth_groups: new Set(config.authGroups),
        enabled: false
      }
    }, {
      onSuccess: (response) => {
        enqueueSnackbar(`Element has been created`, {variant: 'success'});
        setModalInitValues(undefined);
        setModalMode(undefined);
        setEditItemName("");
        void refetchConfigs()
      },
      onError: (err) => {
        enqueueSnackbar(`Operation failure: ${err.message}. Contact an administrator`, {variant: 'error'});
        console.error("Adding config failure", err);
      }
    })
  }

  function handleClickUpdateConfig(name: string, config: EditedRoute) {
    updateCfgMutate({
      domain: ROOM_NAMES.SERVICES as CreateCfgDomainEnum,
      cfgName: ROOM_CONFIGS_NAMES.ROUTER,
      name: name,
      body: {
        ...config,
        auth_groups: new Set(config.authGroups),
      }
    }, {
      onSuccess: (response) => {
        enqueueSnackbar(`${response.data} elements has been updated`, {variant: 'success'});
        setModalInitValues(undefined);
        setModalMode(undefined);
        setEditItemName("");
        void refetchConfigs()
      },
      onError: (err) => {
        enqueueSnackbar(`Operation failure: ${err.message}. Contact an administrator`, {variant: 'error'});
        console.error("Config edit failure", err);
      }
    })
  }

  function handleChangeEnableBulk(
    table: MRT_TableInstance<SysCfgRegularDto>,
    enabled: boolean
  ) {
    const names = table.getSelectedRowModel().rows.map((item) => item.original.name);

    changeEnabledMutate({
      domain: ROOM_NAMES.SERVICES as ChangeCfgEnabledDomainEnum,
      cfgName: ROOM_CONFIGS_NAMES.ROUTER,
      enabled: enabled,
      filters: new Set([{key: "name", mode: "STRICT_IN", value: names as unknown as object}])
    }, {
      onSuccess: (response) => {
        enqueueSnackbar(`${response.data} elements has been updated`, {variant: 'success'});
        table.resetRowSelection();
        void refetchConfigs()
      },
      onError: (err) => {
        enqueueSnackbar(`Operation failure: ${err.message}. Contact an administrator`, {variant: 'error'});
        console.error("Change enabled operation failure", err);
      }
    })
  }

  function handleDeleteConfigs(table: MRT_TableInstance<SysCfgRegularDto>) {
    const names = table.getSelectedRowModel().rows.map((item) => item.original.name);

    deleteCfgsMutate({
      domain: ROOM_NAMES.SERVICES as ChangeCfgEnabledDomainEnum,
      cfgName: ROOM_CONFIGS_NAMES.ROUTER,
      filters: new Set([{key: "name", mode: "STRICT_IN", value: names as unknown as object}])
    }, {
      onSuccess: (response) => {
        setBulkOperationResult([response.data]);
        response.data.dataOnError ?
          enqueueSnackbar(`Something went wrong`, {variant: 'error'}) :
          enqueueSnackbar(`Elements has been deleted`, {variant: 'success'});
        table.resetRowSelection();
        void refetchConfigs()
      },
      onError: (err) => {
        enqueueSnackbar(`Operation failure: ${err.message}. Contact an administrator`, {variant: 'error'});
        console.error("Delete operation failure", err);
      }
    })
  }

  const columns = useRouterRuleTableColumns();

  const table = useMaterialReactTable({
    data: configsRs?.data.content ?? [],
    columns: columns,
    getRowId: (originalRow) => originalRow.id,
    initialState: {
      pagination: { pageSize: 20, pageIndex: 0 },
      density: "compact",
    },

    enableRowSelection: true,
    enableGrouping: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableColumnFilterModes: true,
    enableRowActions: true,
    enablePagination: false,
    enableStickyHeader: true,
    enableSorting: true,
    autoResetPageIndex: false,
    state: {
      globalFilter,
      grouping,
      columnFilters,
      columnVisibility: allTablesUserSetting?.router?.columnVisibility ?? {},
      columnOrder: allTablesUserSetting?.router?.columnOrder ?? [],
      isLoading: configsIsLoading,
    },
    muiTablePaperProps: {
      sx: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",

      },
    },
    muiTableContainerProps: {
      sx: {
        flexGrow: 10,
        overflowY: "scroll",
        height: "500px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        whiteSpace: "pre",
      }
    },

    onColumnFiltersChange: setColumnFilters,
    onGroupingChange: setGrouping,
    onGlobalFilterChange: setGlobalFilter,
    onColumnOrderChange: (newColumnOrder) => {
      setAllTablesUserSetting({
        ...allTablesUserSetting,
        router: {...allTablesUserSetting.router, columnOrder: (newColumnOrder as string[])},
      })

    },
    onColumnVisibilityChange: (updaterFn: Updater<VisibilityState> ) => {
      setAllTablesUserSetting((prev: any) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newVisibility = updaterFn(prev.router?.columnVisibility);
        return {
          ...prev,
          router: {...prev.router, columnVisibility: newVisibility},
        };
      })
    },


    renderTopToolbarCustomActions: ({ table }) => {
      const selectedCount = table.getSelectedRowModel().rows.length;
      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          {cfgPerm.write && (
            <Button
              onClick={() => {
                setModalInitValues(undefined);
                setModalMode("add");
              }}
            >
              <PlusOutlined /> New
            </Button>
          )}

          {selectedCount > 0 && (
            <div style={{ marginLeft: 30 }}>
              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="success"
                  onClick={() => handleChangeEnableBulk(table, true)}
                >
                  <Power /> Enable
                </IconButton>
              )}
              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="error"
                  onClick={() => handleChangeEnableBulk(table, false)}
                >
                  <PowerOff /> Disable
                </IconButton>
              )}
              {cfgPerm.delete && (
                <Popconfirm
                  title="Delete items"
                  description="Are you sure to delete these items? (Changes cannot be undone)"
                  onConfirm={() => handleDeleteConfigs(table)}
                >
                  <IconButton
                    size="small"
                    style={{ fontSize: "15px" }}
                    color="error"
                  >
                    <DeleteOutlined /> Delete
                  </IconButton>
                </Popconfirm>
              )}

              {isOwner && (
                <ApplyAuthGroupsTableControl
                  domain={ROOM_NAMES.SERVICES}
                  cfgName={ROOM_CONFIGS_NAMES.ROUTER}
                  table={table}
                  filters={assembleNamesFilter(table)}
                  totalElementsCount={selectedCount}
                  onSuccess={() => void refetchConfigs()}
                />
              )}
            </div>
          )}
        </div>
      );
    },
    renderRowActions: ({ row }) => (
      <Box>
        <IconButton
          onClick={() => {
            setModalInitValues({ ...row.original });
            setModalMode("edit")
            setEditItemName(row.original.name)
          }}
        >
          <Edit />
        </IconButton>

        {cfgPerm.execute && (
          <IconButton
            onClick={() => {
              // Requirement. Router name needs to be unique,
              // but it is not useful for business, that's why is hidden
              setModalInitValues({
                ...row.original,
                data: { ...row.original.data, priority: undefined },
              });
              setModalMode("add")
            }}
          >
            <ContentCopy />
          </IconButton>
        )}
      </Box>
    ),
  });

  useEffect(() => {
    table.resetRowSelection();
  }, [columnFilters, grouping, globalFilter, table]);

  return (
    <>
      <MaterialReactTable table={table} />

      <RouterEditModal
        disabled={!cfgPerm.write}
        open={!!modalMode}
        initialData={mapSysCfgToEditRoute(modalInitValues)}
        onSave={(data) => {
          if (modalMode === "add") return handleClickSaveNewConfig(data);
          if (modalMode === "edit") return handleClickUpdateConfig(editItemName, data);
        }}
        onCancel={() => {
          setModalMode(undefined);
          setModalInitValues(undefined);
          setEditItemName("")
        }}
      />

      {bulkOperationResult  && (
        <BulkOperationReportModal
          resultList={bulkOperationResult}
          title="Operation result"
          showProblematic
          showSuccess
          onCancel={() => setBulkOperationResult(undefined)}
          open={!!bulkOperationResult}
        />
      )}
    </>
  );
};

export default RouterRuleTable;
