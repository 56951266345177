import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import {
  MaterialReactTable, MRT_ColumnFilterFnsState,
  MRT_ColumnFiltersState, MRT_PaginationState,
  MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { COMMON_REFETCH_INTERVAL, ROOM_CONFIGS_NAMES } from "@/lib/constants";


import {
  useSysCfgQuery,
  useUsernamesCreateMutation,
} from "@/api/hooks/sys-cfg-hooks";
import {
  CreateCfgDomainEnum,
  GetAllCfgsDomainEnum, KeyFilter, KeyFilterModeEnum, KeySort,
} from "@/shared/api";
import { enqueueSnackbar } from "notistack";
import UsernameCreateModal from "@/components/config-modals/username-create-modal/username-create-modal";
import { useUsernameTableColumns } from "@/module/room-configs-module/username-config-table/username-table-columns";
import { SearchParams } from "@/types/commonTypes";
import { useSearch } from "@tanstack/react-router";
import { assembleRegularAccountsDefaultFilters, assembleRegularAccountsFiltersFns } from "@/lib/accountsUtils";
import { assembleKeyFilters, assembleSort } from "@/lib/cfg-utils";

const requiredFilters: KeyFilter[] = []
const defaultFilter: KeyFilter = {key: "data.is_used", mode: "equals" as KeyFilterModeEnum, value: "false" as unknown as object}

const UsernameConfigTable = ({
                               room,
                             }: {
                               room: string;
                             }
) => {
  const columns = useUsernameTableColumns();

  const searchParams: SearchParams = useSearch({strict: false});

  const [sorting, setSorting] =
    useState<MRT_SortingState>([{id: "created_at", desc: true}])

  const [columnFilters, setColumnFilters] =
    useState<MRT_ColumnFiltersState>(() => assembleRegularAccountsDefaultFilters(searchParams));

  const [columnFilterFns, setColumnFilterFns] = useState<MRT_ColumnFilterFnsState>(
    assembleRegularAccountsFiltersFns({ columns, searchParams: searchParams })
  )

  const [pagination, setPagination] =
    useState<MRT_PaginationState>({ pageIndex: 0, pageSize: 30 });

  const filters = useMemo<Set<KeyFilter>>(
    () => assembleKeyFilters(columnFilters, columnFilterFns, requiredFilters),
    [columnFilters, columnFilterFns]
  );

  const sort = useMemo<KeySort[]>(() => assembleSort(sorting), [sorting])

  const {
    data: configsRs,
    isLoading: configsIsLoading,
    refetch: refetchConfigs,
    isRefetching: isConfigsRefetching
  } = useSysCfgQuery({
    domain: room as GetAllCfgsDomainEnum,
    config_name: ROOM_CONFIGS_NAMES.USERNAME,
    filters: [...filters].find(el => el.key === defaultFilter.key) ?
      filters :
      filters.add(defaultFilter),
    sort: sort,
}, {
    enabled: true,
    refetchInterval: COMMON_REFETCH_INTERVAL
  });
  
  const { mutate: createUsernamesMutate } = useUsernamesCreateMutation();

  const [isCreateModalOpened, setIsCreateModalOpened] = useState<boolean>(false);



  function handleClickSaveNewConfig(usernames: Set<string>) {
    createUsernamesMutate({
      domain: room as CreateCfgDomainEnum,
      body: usernames,
    }, {
      onSuccess: (response) => {
        enqueueSnackbar(`Elements have been created`, {variant: 'success'});
        setIsCreateModalOpened(false);
        void refetchConfigs()
      },
      onError: (err) => {
        enqueueSnackbar(`Operation failure: ${err.message}. Contact an administrator`, {variant: 'error'});
        console.error("Adding config failure", err);
      }
    })
  }

  const table = useMaterialReactTable({
    data: configsRs?.data.content ?? [],
    columns: columns,
    getRowId: (originalRow) => originalRow.id,
    initialState: {
      pagination: { pageSize: 20, pageIndex: 0 },
      density: "compact",
    },

    enableRowSelection: true,
    enableGrouping: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableColumnFilterModes: true,
    enableRowActions: false,
    enablePagination: true,
    enableStickyHeader: true,
    enableSorting: true,
    autoResetPageIndex: false,

    manualPagination: true,

    state: {
      pagination,
      columnFilterFns,
      columnFilters,
      sorting,
      isLoading: configsIsLoading,
      showProgressBars: isConfigsRefetching,
    },
    muiTablePaperProps: {
      sx: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",

      },
    },
    muiTableContainerProps: {
      sx: {
        flexGrow: 10,
        overflowY: "scroll",
        height: "500px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        whiteSpace: "pre",
      }
    },
    muiTableBodyRowProps: ({ row }) => {
      const data = row.original.data

      return (data as any)?.status !== 0 && !configsIsLoading
        ? { sx: { backgroundColor: "#e9b1cd" }}
        : {};
    },

    onPaginationChange: (value) => {
      setPagination(value)
    },
    onColumnFiltersChange: setColumnFilters,
    onColumnFilterFnsChange: setColumnFilterFns,
    onSortingChange: setSorting,
    selectAllMode: "page",

    renderTopToolbarCustomActions: () => {
      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          <Button
            onClick={() => {
              setIsCreateModalOpened(true);
            }}
          >
            <PlusOutlined />
            New
          </Button>
        </div>
      );
    },
  });

 useEffect(() => {
    table.resetRowSelection();
  }, [table, columnFilters, sorting, pagination]);

  return (
    <>
      <MaterialReactTable table={table} />

      <UsernameCreateModal
        open={isCreateModalOpened}

        onSave={(data) => {
          handleClickSaveNewConfig(data);
        }}
        onCancel={() => {
          setIsCreateModalOpened(false);
        }}
      />
    </>
  );
};

export default UsernameConfigTable;
