import { IconButton } from "@mui/material";
import React from "react";
import { FileDownload } from "@mui/icons-material";
import { ExportCsvDomainEnum, KeyFilter, SysAccountRegularDto } from "@/shared/api";
import { MRT_Row, MRT_TableInstance } from "material-react-table";
import { useSysAccountsExportCsvMutation } from "@/api/hooks/sys-account-hooks";
import { message } from "antd";

import { assembleParticularFilter } from "@/lib/cfg-utils";


const ExportAccountsCsvTableControl = (
  {
    roomName,
    table,
    filters,
    totalElementsCount
  } : {
    roomName: string;
    table: MRT_TableInstance<SysAccountRegularDto>;
    filters: Set<KeyFilter>;
    totalElementsCount: number;
  }
) => {
  const mutation = useSysAccountsExportCsvMutation();

  function handleMutationAction(rows: MRT_Row<SysAccountRegularDto>[]) {
    if(rows.length > 0) {
      mutateParticular(rows)
    } else {
      mutateAll()
    }
  }

  function mutateAll() {
    callMutation(filters)
  }

  function mutateParticular(rows: MRT_Row<SysAccountRegularDto>[]) {
    callMutation(assembleParticularFilter(rows))
  }

  function callMutation(filters: Set<KeyFilter>) {
    mutation.mutate({ domain: roomName as ExportCsvDomainEnum, filters }, {
      onError: (err) => {
        console.error("accounts csv export error", err)
        message.error("Something went wrong during exporting, contact the administrator").then()
      }
    })
  }


  return (
    <IconButton
      size="small"
      style={{ fontSize: 12 }}
      color="secondary"
      onClick={() =>  handleMutationAction(table.getSelectedRowModel().rows)}
    >
      <FileDownload/> CSV
      ({
        table.getSelectedRowModel().rows.length > 0
          ? (table.getSelectedRowModel().rows.length)
          : (totalElementsCount)
      })
    </IconButton>
  );
}

export default ExportAccountsCsvTableControl