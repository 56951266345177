import { useMemo } from "react";
import { MRT_ColumnDef } from "material-react-table";
import { SysCfgRegularDto } from "@/shared/api";


export const useUsernameTableColumns = () => useMemo<MRT_ColumnDef<SysCfgRegularDto>[]>(
  () => [
    {
      id: "data.username",
      header: "Username",
      accessorKey: "data.username",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      id: "data.is_used",
      header: "Is used",
      accessorKey: "data.is_used",
      accessorFn: (dto)=> (dto.data as any)?.is_used.toString() ?? "-",
      sortingFn: "alphanumeric",
      filterFn: "equals",
    }
  ],
  []
);