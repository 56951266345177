import { createFileRoute } from '@tanstack/react-router'
import AccountsPage from '@/pages/accounts-page'
import { z } from 'zod'
import CommandJournalAccessTemplate from "@/template/command-journal-access-template";

const TABS = {
  regularAccounts: 0,
  serviceAccounts: 1,
  gameSessions: 2,
  debugSessions: 3,
  serviceSessions: 4,
} as const

const searchParamsSchema = z.object({
  "session_id": z.string().optional()
})

export const Route = createFileRoute('/accounts/$room/$type')({
  component: Component,
  validateSearch: (search) => searchParamsSchema.parse(search),
})

function Component() {
  return (
    <CommandJournalAccessTemplate>
      <AccountsPage />
    </CommandJournalAccessTemplate>
  )
}
