import {useCreateUserMutation, useUpdateUserMutation, useUsersQuery} from "@/api/hooks/user-hooks";
import {keepPreviousData} from "@tanstack/react-query";
import UsersListTable from "@/module/users-list-module/users-list-table";
import {Button, Grid} from "@mui/material";
import {Divider, Typography} from "antd";
import {AddCircleOutline} from "@mui/icons-material";
import CreateUserModal from "@/components/create-user-modal";
import {useMemo, useState} from "react";
import {CreateUserRequestDto, UpdateUserRequestDto} from "@/shared/api";
import {enqueueSnackbar} from "notistack";
import {COMMON_REFETCH_INTERVAL, SUPER_ADMIN_NAME} from "@/lib/constants";
import {useAtom} from "jotai/index";
import {meStore} from "@/store/store";


const UsersListModule = () => {
  const [me] = useAtom(meStore);

  const { data: usersRs, isLoading, isRefetching, refetch } = useUsersQuery({
    placeholderData: keepPreviousData,
    staleTime: 0,
    refetchInterval: COMMON_REFETCH_INTERVAL,
    enabled: !!me
  });

  const users = useMemo(() => {
    return usersRs?.data?.filter(item =>
      me?.username === SUPER_ADMIN_NAME || item.username !== SUPER_ADMIN_NAME)
    ?? []
  }, [me, usersRs?.data]);

  const [createUser, setCreateUser] = useState<boolean>(false);
  const {
    mutate: createUserMutate,
    isPending: createUserIsPending,
  } = useCreateUserMutation();
  const {
    mutate: updateUser,
    isPending: updateUserIsPending
  } = useUpdateUserMutation();

  const handleUpdate = (data: UpdateUserRequestDto) => {
    updateUser(
      {updateUser: data},
      {
        onSuccess: () => {
          refetch().then()
        }
      }
    )
  }

  const onCreateUserHandler = (data: CreateUserRequestDto) => {
    createUserMutate(
      {
        request: data
      },
      {
        onSuccess: () => {
          setCreateUser(false)
          enqueueSnackbar("user successfully created!", { variant: "success" })
          refetch().then()
        },
        onError: (err) => {
          console.error(err);
          enqueueSnackbar(`Error creating user: ${err.message}`, { variant: "error" })
        }
      }
    )
  }

  return (
    <>
      <Grid container spacing={2} height="100%" direction="column" flexWrap="nowrap">
        <Grid item>
          <Typography.Title level={2}>Users</Typography.Title>
        </Grid>
        <Grid item>
          <Divider style={{marginTop: 0}}/>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutline width={24}/>}
            onClick={() => setCreateUser(true)}
          >Create user</Button>
        </Grid>
        <Grid item xs={10} >
          <UsersListTable
            users={users}
            usersIsLoading={isLoading}
            userIsRefetching={isRefetching || updateUserIsPending}
            onUpdate={data => handleUpdate(data)}
            onDeleted={() => refetch()}
          />
        </Grid>
      </Grid>
      <CreateUserModal
        open={createUser}
        onClose={() => setCreateUser(false)}
        onSave={(data) => onCreateUserHandler(data)}
        isLoading={createUserIsPending}
      />
    </>
  )
};

export default UsersListModule;
