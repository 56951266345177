import React, { useMemo } from "react";
import { MRT_ColumnDef } from "material-react-table";
import { Checkbox } from "antd";
import { SysCfgRegularDto } from "@/shared/api";
import { AnyObj, NilObj } from "@/types/commonTypes";


export const useRouterRuleTableColumns = () => useMemo<MRT_ColumnDef<SysCfgRegularDto>[]>(
  () => [
    {
      id: "enabled",
      header: "Enabled",
      accessorFn: ({ enabled }) => enabled ?? "-",
      Cell: ({ row: { original } }) => <Checkbox checked={original.enabled} disabled/>
    },
    {
      header: "Priority",
      accessorKey: "priority",
      accessorFn: ({ data }) => (data as NilObj)?.priority ?? "-",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "Comment",
      accessorFn: ({ data }) => (data as NilObj)?.comment ?? "-",
      filterFn: "contains",
    },
    {
      header: "Room",
      accessorFn: (row) => {
        const data = row.data as AnyObj;
        if(Array.isArray(data?.room)) {
          return data?.room.join(", ")
        }
        return "-"
      },
      filterFn: "contains",
    },
    {
      header: "Technology",
      accessorFn: (row) => {
        const data = row.data as AnyObj;
        if(Array.isArray(data?.technology)) {
          return data?.technology.join(", ")
        }
        return "-"
      },
      filterFn: "contains",
    },
    {
      header: "Partner AI Key",
      accessorFn: (row) => {
        const data = row.data as AnyObj;
        if(Array.isArray(data?.partner_ai_key)) {
          return data?.partner_ai_key.join(", ")
        }
        return "-"
      },
      filterFn: "contains",
    },
    {
      header: "Game Type",
      accessorFn: (row) => {
        const data = row.data as AnyObj;
        if(Array.isArray(data?.game_type)) {
          return data?.game_type.join(", ")
        }
        return "-"
      },
      filterFn: "contains",
    },
    {
      header: "Size",
      accessorFn: (row) => {
        const data = row.data as AnyObj;
        if(Array.isArray(data?.size)) {
          return data?.size.join(", ")
        }
        return "-"
      },
      filterFn: "contains",
    },
    {
      header: "AID",
      accessorFn: (row) => {
        const data = row.data as AnyObj;
        if(Array.isArray(data?.aid)) {
          return data?.aid.join(", ")
        }
        return "-"
      },
      filterFn: "contains",
    },
    {
      header: "CID",
      accessorFn: (row) => {
        const data = row.data as AnyObj;
        if(Array.isArray(data?.cid)) {
          return data?.cid.join(", ")
        }
        return "-"
      },
      filterFn: "contains",
    },
    {
      header: "Game Group",
      accessorFn: (row) => {
        const data = row.data as AnyObj;
        if(Array.isArray(data?.game_group)) {
          return data?.game_group.join(", ")
        }
        return "-"
      },
      filterFn: "contains",
    },
    {
      header: "Limit",
      accessorFn: (row) => {
        const data = row.data as AnyObj;
        if(Array.isArray(data?.limit)) {
          return data?.limit.join(", ")
        }
        return "-"
      },
      filterFn: "contains",
    },
    {
      header: "Straddle",
      accessorFn: (row) => {
        const data = row.data as AnyObj;
        if(Array.isArray(data?.straddle)) {
          return data?.straddle.join(", ")
        }
        return "-"
      },
      filterFn: "contains",
    },
    {
      header: "Ante",
      accessorFn: (row) => {
        const data = row.data as AnyObj;
        if(Array.isArray(data?.ante)) {
          return data?.ante.join(", ")
        }
        return "-"
      },
      filterFn: "contains",
    },
    {
      header: "Players count",
      accessorFn: (row) => {
        const data = row.data as AnyObj;
        if(Array.isArray(data?.players_count)) {
          return data?.players_count.join(", ")
        }
        return "-"
      },
      filterFn: "contains",
    },
    {
      header: "Group Pattern",
      accessorFn: (row) => {
        const data = row.data as AnyObj;
        if(Array.isArray(data?.group_pattern)) {
          return data?.group_pattern.join(", ")
        }
        return "-"
      },
      filterFn: "contains",
    },
    {
      header: "Only Bot",
      accessorFn: ({ data }) => (data as NilObj)?.only_bot ?? "-",
      filterFn: "contains",
    },
    {
      header: "AI Type",
      accessorFn: ({ data }) => (data as NilObj)?.ai_type ?? "-",
      filterFn: "contains",
    },
    {
      header: "Instances",
      filterFn: "contains",
      accessorFn: (row) => {
        const data = row.data as AnyObj;
        if(Array.isArray(data?.instances)) {
          return data?.instances.map(it => it?.instance).join("\n")
        }
        return "-"
      },
    },
    {
      header: "Probability",
      filterFn: "contains",
      accessorFn: (row) => {
        const data = row.data as AnyObj;
        if(Array.isArray(data?.instances)) {
          return data?.instances.map(it => it?.probability).join("\n")
        }
        return "-"
      },
    },
    {
      header: "Always active",
      filterFn: "contains",
      accessorFn: (row) => {
        const data = row.data as AnyObj;
        if(Array.isArray(data?.instances)) {
          return data?.instances.map(it => it?.always_active).join("\n")
        }
        return "-"
      },
    },
    {
      header: "Single Instance",
      filterFn: "contains",
      accessorFn: ({ data }) => (data as NilObj)?.single_instance == null
        ? "-"
        : `${(data as NilObj)?.single_instance}`,
    },
  ],
  []
);