import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Power, PowerOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Button, Checkbox, Popconfirm } from "antd";
import { MaterialReactTable, MRT_ColumnDef, MRT_TableInstance, useMaterialReactTable } from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import NameConfigAddModal from "@/components/config-modals/name-config-add-modal";
import { ROOM_CONFIGS_NAMES } from "@/lib/constants";
import { Updater, VisibilityState } from "@tanstack/table-core";
import {
  useSysCfgAddNicknamesMutation,
  useSysCfgChangeEnabledMutation,
  useSysCfgDeleteMutation,
  useSysCfgQuery,
} from "@/api/hooks/sys-cfg-hooks";
import {
  AddNicknamesDomainEnum,
  ChangeCfgEnabledDomainEnum,
  DeleteCfgDomainEnum,
  GetAllCfgsDomainEnum, ResultDtoLong,
  SysCfgRegularDto,
} from "@/shared/api";
import { enqueueSnackbar } from "notistack";
import {useCfgPerm} from "@/auth/hooks";
import BulkOperationReportModal from "@/components/bulk-operation-report-modal";

const NameConfigTable = (
  {
    room
  } : {
    room: string
  }
) => {
  const { data: configsRs, isLoading: configsIsLoading, refetch } = useSysCfgQuery({
      domain: room as GetAllCfgsDomainEnum,
      config_name: ROOM_CONFIGS_NAMES.NICKNAME
  });

  const cfgPerm = useCfgPerm({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.NICKNAME,
  });

  const roomConfigsSortedData = React.useMemo(() => {
    return (
      configsRs?.data.content?.sort(
        (a, b) => new Date(b.created_at).getDate() - new Date(a.created_at).getDate()
      ) ?? []
    );
  }, [configsRs]);

  const { mutate: addNicknamesMutate } = useSysCfgAddNicknamesMutation();
  const { mutate: changeEnabledMutate } = useSysCfgChangeEnabledMutation();
  const { mutate: deleteCfgsMutate } = useSysCfgDeleteMutation();

  const [bulkOperationResult, setBulkOperationResult] = useState<ResultDtoLong[] | undefined>(undefined);


  //This logic for reset row selection while using filtering/grouping
  const [addNameConfigModalIsOpen, setAddNameConfigModalIsOpen] = useState<boolean>(false);
  const [columnFilters, setColumnFilters] = useState<any>([]);
  const [grouping, setGrouping] = useState<any>([]);
  const [globalFilter, setGlobalFilter] = useState<any>(undefined);

  //Storing table setting into localStorage
  const loadTablesSettingsFromLocalStorage = () => {
    const item = localStorage.getItem("tablesSettings");
    return item ? JSON.parse(item) : {};
  }

  const [allTablesUserSetting, setAllTablesUserSetting] = useState<any>(() => loadTablesSettingsFromLocalStorage());

  useEffect(() => {
    localStorage.setItem("tablesSettings", JSON.stringify(allTablesUserSetting))
  }, [allTablesUserSetting]);

  function handleClickAddConfig() {
    setAddNameConfigModalIsOpen(true);
  }

  function handleClickSaveNewConfig(value: string[]) {
    addNicknamesMutate({
      domain: room as AddNicknamesDomainEnum,
      nicknames: value
    }, {
      onSuccess: (response) => {
        void refetch()
        enqueueSnackbar(`${response.data.uploaded} nicknames has been added`, {variant: 'success'});
        setAddNameConfigModalIsOpen(false);
      },
      onError: (err) => {
        enqueueSnackbar(`Operation failure: ${err.message}. Contact an administrator`, {variant: 'error'});
        console.error("Adding nicknames failure", err);
      }
    })
  }

  function handleChangeEnableBulk(
    table: MRT_TableInstance<SysCfgRegularDto>,
    enabled: boolean
  ) {
    const names = table.getSelectedRowModel().rows.map((item) => item.original.name);

    changeEnabledMutate({
      domain: room as ChangeCfgEnabledDomainEnum,
      cfgName: ROOM_CONFIGS_NAMES.NICKNAME,
      enabled: enabled,
      filters: new Set([{key: "name", mode: "STRICT_IN", value: names as unknown as object}])
    }, {
      onSuccess: (response) => {
        enqueueSnackbar(`${response.data} elements has been updated`, {variant: 'success'});
        table.resetRowSelection();
        void refetch()
      },
      onError: (err) => {
        enqueueSnackbar(`Operation failure: ${err.message}. Contact an administrator`, {variant: 'error'});
        console.error("Change enabled operation failure", err);
      }
    })
  }

  function handleDeleteConfigs(table: MRT_TableInstance<SysCfgRegularDto>) {
    const names = table.getSelectedRowModel().rows.map((item) => item.original.name);

    deleteCfgsMutate({
      domain: room as DeleteCfgDomainEnum,
      cfgName: ROOM_CONFIGS_NAMES.NICKNAME,
      filters: new Set([{key: "name", mode: "STRICT_IN", value: names as unknown as object}])
    }, {
      onSuccess: (response) => {
        setBulkOperationResult([response.data]);
        response.data.dataOnError ?
          enqueueSnackbar(`Something went wrong`, {variant: 'error'}) :
          enqueueSnackbar(`Elements has been deleted`, {variant: 'success'});
        table.resetRowSelection();
        void refetch()
      },
      onError: (err) => {
        enqueueSnackbar(`Operation failure: ${err.message}. Contact an administrator`, {variant: 'error'});
        console.error("Delete operation failure", err);
      }
    })
  }

  const columns = useMemo<MRT_ColumnDef<SysCfgRegularDto>[]>(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        filterFn: "contains",
      },
      {
        header: "Is used",
        accessorFn: (row) => `${(row.data as any)?.is_used ?? "-"}`,
        filterFn: "contains",
      },
      {
        header: "Enabled",
        filterFn: "contains",
        enableEditing: false,
        accessorFn: (row) => row.enabled ?? "-",
        Cell: ({ row }) => (
          <Checkbox disabled checked={row.original.enabled} />
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    data: roomConfigsSortedData ?? [],
    columns: columns,
    getRowId: (originalRow) => originalRow.id,
    initialState: {
      pagination: { pageSize: 20, pageIndex: 0 },
      density: "compact",
      isLoading: configsIsLoading
    },
    state: {
      columnFilters,
      globalFilter,
      grouping,
      columnVisibility: allTablesUserSetting?.name?.columnVisibility ?? {},
      columnOrder: allTablesUserSetting?.name?.columnOrder ?? [],
    },
    enableRowSelection: true,
    enableGrouping: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableRowActions: true,
    enablePagination: true,
    enableStickyHeader: true,
    enableRowVirtualization: true,
    rowVirtualizerOptions: { overscan: 20 },
    muiTablePaperProps: {
      sx: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
      },
    },
    muiTableContainerProps: {
      sx: {
        flexGrow: 10,
        overflowY: "scroll",
        height: "500px",
      },
    },
    onColumnFiltersChange: setColumnFilters,
    onGroupingChange: setGrouping,
    onGlobalFilterChange: setGlobalFilter,
    onColumnOrderChange: (newColumnOrder) => {
      setAllTablesUserSetting({
        ...allTablesUserSetting,
        name: {...allTablesUserSetting.name, columnOrder: (newColumnOrder as string[])},
      })

    },
    onColumnVisibilityChange: (updaterFn: Updater<VisibilityState> ) => {
      setAllTablesUserSetting((prev: any) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newVisibility = updaterFn(prev.name?.columnVisibility);
        return {
          ...prev,
          name: {...prev.name, columnVisibility: newVisibility},
        };
      })
    },


    renderTopToolbarCustomActions: () => {
      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          {cfgPerm.write && (
            <Button onClick={() => handleClickAddConfig()}>
              <PlusOutlined />
              New
            </Button>
          )}

          {table.getSelectedRowModel().rows.length > 0 && (
            <div style={{ marginLeft: 30 }}>
              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="success"
                  onClick={() => handleChangeEnableBulk(table, true)}
                >
                  <Power /> Enable
                </IconButton>
              )}

              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="error"
                  onClick={() => handleChangeEnableBulk(table, false)}
                >
                  <PowerOff /> Disable
                </IconButton>
              )}

              {cfgPerm.delete && (
                <Popconfirm
                  title="Delete items"
                  description="Are you sure to delete these items? (Changes cannot be undone)"
                  onConfirm={() => handleDeleteConfigs(table)}
                >
                  <IconButton
                    size="small"
                    style={{ fontSize: "15px" }}
                    color="error"
                  >
                    <DeleteOutlined /> Delete
                  </IconButton>
                </Popconfirm>
              )}

            </div>
          )}
        </div>
      );
    },
  });

  useEffect(() => {
    table.resetRowSelection();
  }, [columnFilters, grouping, globalFilter, table]);

  return (
    <>
      <MaterialReactTable table={table} />
      {addNameConfigModalIsOpen && cfgPerm.write && (
        <NameConfigAddModal
          title="Add new name"
          open={addNameConfigModalIsOpen}
          onCancel={() => setAddNameConfigModalIsOpen(false)}
          onSave={(config) => handleClickSaveNewConfig(config)}
        />
      )}

      {bulkOperationResult  && (
        <BulkOperationReportModal
          resultList={bulkOperationResult}
          title="Operation result"
          showProblematic
          showSuccess
          onCancel={() => setBulkOperationResult(undefined)}
          open={!!bulkOperationResult}
        />
      )}
    </>
  );
}


export default NameConfigTable;