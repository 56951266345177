import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { IconButton } from "@mui/material";
import { Button, Popconfirm } from "antd";
import { MaterialReactTable, MRT_TableInstance, useMaterialReactTable } from "material-react-table";
import React, { useEffect, useState } from "react";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import { Updater, VisibilityState } from "@tanstack/table-core";


import {
  useSysCfgDeleteMutation,
  useSysCfgQuery, useSysCfgsCreateMutation,
  useSysCfgUpdateMutation,
} from "@/api/hooks/sys-cfg-hooks";
import {
  ChangeCfgEnabledDomainEnum,
  CreateCfgDomainEnum,
  GetAllCfgsDomainEnum,
  SysCfgCreateRequestDto,
  SysCfgRegularDto,
} from "@/shared/api";
import { enqueueSnackbar } from "notistack";
import {
  useEmailDomainTableColumns
} from "@/module/service-configs-module/email-domain-module/email-domain-table-columns";
import EmailDomainCreateModal from "@/components/config-modals/email-domain-edit-modal/email-domain-create-modal";

const EmailDomainConfigTable = (
) => {
  const {data: configsRs, isLoading: configsIsLoading, refetch: refetchConfigs} = useSysCfgQuery({
    domain: ROOM_NAMES.SERVICES as GetAllCfgsDomainEnum,
    config_name: ROOM_CONFIGS_NAMES.EMAIL_DOMAIN,
  });

  const { mutate: createCfgsMutate } = useSysCfgsCreateMutation();
  //const { mutate: createCfgsMutate } = useSysCfgCreateMutation(); //TODO Remove after recieving access to db
  const { mutate: deleteCfgsMutate } = useSysCfgDeleteMutation();
  const { mutate: updateCfgMutate } = useSysCfgUpdateMutation();

  //This logic for reset row selection while using filtering/grouping
  const [columnFilters, setColumnFilters] = useState<any>([]);
  const [grouping, setGrouping] = useState<any>([]);
  const [globalFilter, setGlobalFilter] = useState<any>(undefined);

  const [modalMode, setModalMode] = useState<"edit" | "add" | undefined>(undefined);
  const [editItemName, setEditItemName] = useState<string>("");
  const [modalInitValues, setModalInitValues] = useState<SysCfgRegularDto | undefined>(undefined);


  //Storing table setting into localStorage
  const loadTablesSettingsFromLocalStorage = () => {
    const item = localStorage.getItem("tablesSettings");
    return item ? JSON.parse(item) : {};
  }

  const [allTablesUserSetting, setAllTablesUserSetting] = useState<any>(() => loadTablesSettingsFromLocalStorage());

  useEffect(() => {
    localStorage.setItem("tablesSettings", JSON.stringify(allTablesUserSetting))
  }, [allTablesUserSetting]);


  function handleClickSaveNewConfig(config: SysCfgCreateRequestDto[]) {
    createCfgsMutate({
      domain: ROOM_NAMES.SERVICES as CreateCfgDomainEnum,
      cfgName: ROOM_CONFIGS_NAMES.EMAIL_DOMAIN,
      body: config.map((item) => ({...item, authGroups: item.auth_groups}))
    }, {
      onSuccess: (response) => {
        enqueueSnackbar(`Elements have been created`, {variant: 'success'});
        setModalInitValues(undefined);
        setModalMode(undefined);
        setEditItemName("");
        void refetchConfigs()
      },
      onError: (err) => {
        enqueueSnackbar(`Operation failure: ${err.message}. Contact an administrator`, {variant: 'error'});
        console.error("Adding config failure", err);
      }
    })
  }

  function handleDeleteConfigs(table: MRT_TableInstance<SysCfgRegularDto>) {
    const names = table.getSelectedRowModel().rows.map((item) => item.original.name);

    deleteCfgsMutate({
      domain: ROOM_NAMES.SERVICES as ChangeCfgEnabledDomainEnum,
      cfgName: ROOM_CONFIGS_NAMES.EMAIL_DOMAIN,
      filters: new Set([{key: "name", mode: "STRICT_IN", value: names as unknown as object}])
    }, {
      onSuccess: (response) => {
        enqueueSnackbar(`${response.data} elements has been deleted`, {variant: 'success'});
        table.resetRowSelection();
        void refetchConfigs()
      },
      onError: (err) => {
        enqueueSnackbar(`Operation failure: ${err.message}. Contact an administrator`, {variant: 'error'});
        console.error("Delete operation failure", err);
      }
    })
  }

  const columns = useEmailDomainTableColumns();

  const table = useMaterialReactTable({
    data: configsRs?.data.content ?? [],
    columns: columns,
    getRowId: (originalRow) => originalRow.id,
    initialState: {
      pagination: { pageSize: 20, pageIndex: 0 },
      density: "compact",
    },

    enableRowSelection: true,
    enableGrouping: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableColumnFilterModes: true,
    enableRowActions: false,
    enablePagination: false,
    enableStickyHeader: true,
    enableSorting: true,
    autoResetPageIndex: false,
    state: {
      globalFilter,
      grouping,
      columnFilters,
      columnVisibility: allTablesUserSetting?.router?.columnVisibility ?? {},
      columnOrder: allTablesUserSetting?.router?.columnOrder ?? [],
      isLoading: configsIsLoading,
    },
    muiTablePaperProps: {
      sx: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",

      },
    },
    muiTableContainerProps: {
      sx: {
        flexGrow: 10,
        overflowY: "scroll",
        height: "500px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        whiteSpace: "pre",
      }
    },

    onColumnFiltersChange: setColumnFilters,
    onGroupingChange: setGrouping,
    onGlobalFilterChange: setGlobalFilter,
    onColumnOrderChange: (newColumnOrder) => {
      setAllTablesUserSetting({
        ...allTablesUserSetting,
        email_domain: {...allTablesUserSetting.router, columnOrder: (newColumnOrder as string[])},
      })

    },
    onColumnVisibilityChange: (updaterFn: Updater<VisibilityState> ) => {
      setAllTablesUserSetting((prev: any) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newVisibility = updaterFn(prev.email_domain?.columnVisibility);
        return {
          ...prev,
          email_domain: {...prev.email_domain, columnVisibility: newVisibility},
        };
      })
    },


    renderTopToolbarCustomActions: () => {
      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          <Button
            onClick={() => {
              setModalInitValues(undefined);
              setModalMode("add");
            }}
          >
            <PlusOutlined />
            New
          </Button>
          {table.getSelectedRowModel().rows.length > 0 && (
            <div style={{ marginLeft: 30 }}>
                <Popconfirm
                    title="Delete items"
                    description="Are you sure to delete these items? (Changes cannot be undone)"
                    onConfirm={() => handleDeleteConfigs(table)}
                >
                    <IconButton
                        size="small"
                        style={{ fontSize: "15px" }}
                        color="error"
                    >
                        <DeleteOutlined /> Delete
                    </IconButton>
                </Popconfirm>
            </div>
          )}
        </div>
      );
    },
  });


  useEffect(() => {
    table.resetRowSelection();
  }, [columnFilters, grouping, globalFilter, table]);

  return (
    <>
      <MaterialReactTable table={table} />

      <EmailDomainCreateModal
        open={!!modalMode}

        onSave={(data) => {
          handleClickSaveNewConfig(data);
        }}
        onCancel={() => {
          setModalMode(undefined);
          setModalInitValues(undefined);
          setEditItemName("")
        }}
      />
    </>
  );
};

export default EmailDomainConfigTable;
