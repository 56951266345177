import {createFileRoute, Navigate} from '@tanstack/react-router'
import AuthGroupsPage from "@/pages/auth-groups-page";
import {useAtom} from "jotai/index";
import {meStore} from "@/store/store";
import {isOwner} from "@/lib/auth-predicates";

export const Route = createFileRoute('/auth-groups/')({
  component: Component,
})

function Component() {
  const [me] = useAtom(meStore);

  if(!isOwner(me)) {
    return <Navigate to={'/'}/>
  }

  return <AuthGroupsPage/>;
}
