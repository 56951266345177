import { useMemo } from "react";
import { MRT_ColumnDef } from "material-react-table";
import { ResultStatsDto } from "@/shared/api";


export const useStatserviceTableColumns = () => useMemo<MRT_ColumnDef<ResultStatsDto>[]>(
  () => [
    {
      id: "pid",
      header: "PID",
      accessorKey: "player.pid",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "CID",
      id: "cid",
      accessorKey: "player.cid",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "AID",
      id: "aid",
      accessorKey: "player.aid",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "Name",
      id: "name",
      accessorKey: "player.name",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "Game group",
      id: "gameGroup",
      accessorKey: "player.gameGroup",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "Room",
      id: "room",
      accessorKey: "player.room",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "Limit",
      id: "limit",
      accessorKey: "player.limit",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "Game type",
      id: "gameType",
      accessorKey: "player.gameType",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "Technology",
      id: "technology",
      accessorKey: "player.technology",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "handsCount",
      id: "handsCount",
      accessorKey: "handsCount",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "vpip",
      id: "vpip",
      accessorKey: "vpip",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "pfr",
      id: "pfr",
      accessorKey: "pfr",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "wtsd",
      id: "wtsd",
      accessorKey: "wtsd",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "thirdBet",
      id: "thirdBet",
      accessorKey: "thirdBet",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaisePotBet",
      id: "openRaisePotBet",
      accessorKey: "openRaisePotBet",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise05Sb",
      id: "openRaise05Sb",
      accessorKey: "openRaise05Sb",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise0667Sb",
      id: "openRaise0667Sb",
      accessorKey: "openRaise0667Sb",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise10Sb",
      id: "openRaise10Sb",
      accessorKey: "openRaise10Sb",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise05Bb",
      id: "openRaise05Bb",
      accessorKey: "openRaise05Bb",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise0667Bb",
      id: "openRaise0667Bb",
      accessorKey: "openRaise0667Bb",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise10Bb",
      id: "openRaise10Bb",
      accessorKey: "openRaise10Bb",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise05Bt",
      id: "openRaise05Bt",
      accessorKey: "openRaise05Bt",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise0667Bt",
      id: "openRaise0667Bt",
      accessorKey: "openRaise0667Bt",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise10Bt",
      id: "openRaise10Bt",
      accessorKey: "openRaise10Bt",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise05Co",
      id: "openRaise05Co",
      accessorKey: "openRaise05Co",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise0667Co",
      id: "openRaise0667Co",
      accessorKey: "openRaise0667Co",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise10Co",
      id: "openRaise10Co",
      accessorKey: "openRaise10Co",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise05Mp",
      id: "openRaise05Mp",
      accessorKey: "openRaise05Mp",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise0667Mp",
      id: "openRaise0667Mp",
      accessorKey: "openRaise0667Mp",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise10Mp",
      id: "openRaise10Mp",
      accessorKey: "openRaise10Mp",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise05Mp1",
      id: "openRaise05Mp1",
      accessorKey: "openRaise05Mp1",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise0667Mp1",
      id: "openRaise0667Mp1",
      accessorKey: "openRaise0667Mp1",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise10Mp1",
      id: "openRaise10Mp1",
      accessorKey: "openRaise10Mp1",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise05Mp2",
      id: "openRaise05Mp2",
      accessorKey: "openRaise05Mp2",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise0667Mp2",
      id: "openRaise0667Mp2",
      accessorKey: "openRaise0667Mp2",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise10Mp2",
      id: "openRaise10Mp2",
      accessorKey: "openRaise10Mp2",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise05Utg",
      id: "openRaise05Utg",
      accessorKey: "openRaise05Utg",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise05Utg1",
      id: "openRaise05Utg1",
      accessorKey: "openRaise05Utg1",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise05Utg2",
      id: "openRaise05Utg2",
      accessorKey: "openRaise05Utg2",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise0667Utg",
      id: "openRaise0667Utg",
      accessorKey: "openRaise0667Utg",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise0667Utg1",
      id: "openRaise0667Utg1",
      accessorKey: "openRaise0667Utg1",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise0667Utg2",
      id: "openRaise0667Utg2",
      accessorKey: "openRaise0667Utg2",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise10Utg",
      id: "openRaise10Utg",
      accessorKey: "openRaise10Utg",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise10Utg1",
      id: "openRaise10Utg1",
      accessorKey: "openRaise10Utg1",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openRaise10Utg2",
      id: "openRaise10Utg2",
      accessorKey: "openRaise10Utg2",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openLimpSb",
      id: "openLimpSb",
      accessorKey: "openLimpSb",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openLimpBb",
      id: "openLimpBb",
      accessorKey: "openLimpBb",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openLimpBt",
      id: "openLimpBt",
      accessorKey: "openLimpBt",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openLimpCo",
      id: "openLimpCo",
      accessorKey: "openLimpCo",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openLimpMp",
      id: "openLimpMp",
      accessorKey: "openLimpMp",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openLimpMp1",
      id: "openLimpMp1",
      accessorKey: "openLimpMp1",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openLimpMp2",
      id: "openLimpMp2",
      accessorKey: "openLimpMp2",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openLimpUtg",
      id: "openLimpUtg",
      accessorKey: "openLimpUtg",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openLimpUtg1",
      id: "openLimpUtg1",
      accessorKey: "openLimpUtg1",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "openLimpUtg2",
      id: "openLimpUtg2",
      accessorKey: "openLimpUtg2",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betFlop0001To015",
      id: "betFlop0001To015",
      accessorKey: "betFlop0001To015",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betFlop015To029",
      id: "betFlop015To029",
      accessorKey: "betFlop015To029",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betFlop029To038",
      id: "betFlop029To038",
      accessorKey: "betFlop029To038",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betFlop038To045",
      id: "betFlop038To045",
      accessorKey: "betFlop038To045",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betFlop045To055",
      id: "betFlop045To055",
      accessorKey: "betFlop045To055",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betFlop055To062",
      id: "betFlop055To062",
      accessorKey: "betFlop055To062",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betFlop062To071",
      id: "betFlop062To071",
      accessorKey: "betFlop062To071",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betFlop071To08",
      id: "betFlop071To08",
      accessorKey: "betFlop071To08",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betFlop08To095",
      id: "betFlop08To095",
      accessorKey: "betFlop08To095",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betFlop095To105",
      id: "betFlop095To105",
      accessorKey: "betFlop095To105",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betFlop105To12",
      id: "betFlop105To12",
      accessorKey: "betFlop105To12",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betFlop12To100",
      id: "betFlop12To100",
      accessorKey: "betFlop12To100",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betTurn0001To015",
      id: "betTurn0001To015",
      accessorKey: "betTurn0001To015",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betTurn015To029",
      id: "betTurn015To029",
      accessorKey: "betTurn015To029",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betTurn029To038",
      id: "betTurn029To038",
      accessorKey: "betTurn029To038",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betTurn038To045",
      id: "betTurn038To045",
      accessorKey: "betTurn038To045",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betTurn045To055",
      id: "betTurn045To055",
      accessorKey: "betTurn045To055",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betTurn055To062",
      id: "betTurn055To062",
      accessorKey: "betTurn055To062",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betTurn062To071",
      id: "betTurn062To071",
      accessorKey: "betTurn062To071",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betTurn071To08",
      id: "betTurn071To08",
      accessorKey: "betTurn071To08",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betTurn08To095",
      id: "betTurn08To095",
      accessorKey: "betTurn08To095",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betTurn095To105",
      id: "betTurn095To105",
      accessorKey: "betTurn095To105",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betTurn105To12",
      id: "betTurn105To12",
      accessorKey: "betTurn105To12",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betTurn12To100",
      id: "betTurn12To100",
      accessorKey: "betTurn12To100",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betRiver0001To015",
      id: "betRiver0001To015",
      accessorKey: "betRiver0001To015",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betRiver015To029",
      id: "betRiver015To029",
      accessorKey: "betRiver015To029",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betRiver029To038",
      id: "betRiver029To038",
      accessorKey: "betRiver029To038",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betRiver038To045",
      id: "betRiver038To045",
      accessorKey: "betRiver038To045",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betRiver045To055",
      id: "betRiver045To055",
      accessorKey: "betRiver045To055",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betRiver055To062",
      id: "betRiver055To062",
      accessorKey: "betRiver055To062",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betRiver062To071",
      id: "betRiver062To071",
      accessorKey: "betRiver062To071",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betRiver071To08",
      id: "betRiver071To08",
      accessorKey: "betRiver071To08",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betRiver08To095",
      id: "betRiver08To095",
      accessorKey: "betRiver08To095",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betRiver095To105",
      id: "betRiver095To105",
      accessorKey: "betRiver095To105",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betRiver105To12",
      id: "betRiver105To12",
      accessorKey: "betRiver105To12",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betRiver12To100",
      id: "betRiver12To100",
      accessorKey: "betRiver12To100",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "riverRaise",
      id: "riverRaise",
      accessorKey: "riverRaise",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "flopRaise",
      id: "flopRaise",
      accessorKey: "flopRaise",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "turnRaise",
      id: "turnRaise",
      accessorKey: "turnRaise",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "sbHands",
      id: "sbHands",
      accessorKey: "sbHands",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "bbHands",
      id: "bbHands",
      accessorKey: "bbHands",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "stHands",
      id: "stHands",
      accessorKey: "stHands",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "btHands",
      id: "btHands",
      accessorKey: "btHands",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "coHands",
      id: "coHands",
      accessorKey: "coHands",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "mpHands",
      id: "mpHands",
      accessorKey: "mpHands",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "mp1Hands",
      id: "mp1Hands",
      accessorKey: "mp1Hands",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "mp2Hands",
      id: "mp2Hands",
      accessorKey: "mp2Hands",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "utgHands",
      id: "utgHands",
      accessorKey: "utgHands",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "utg1Hands",
      id: "utg1Hands",
      accessorKey: "utg1Hands",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "utg2Hands",
      id: "utg2Hands",
      accessorKey: "utg2Hands",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "threeBetBar",
      id: "threeBetBar",
      accessorKey: "threeBetBar",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "wrVsHero",
      id: "wrVsHero",
      accessorKey: "wrVsHero",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "wr",
      id: "wr",
      accessorKey: "wr",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "winRate",
      id: "winRate",
      accessorKey: "winRate",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "wrBb",
      id: "wrBb",
      accessorKey: "wrBb",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "group",
      id: "group",
      accessorKey: "group",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betFlopTopDiap001To029",
      id: "betFlopTopDiap001To029",
      accessorKey: "betFlopTopDiap001To029",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "betFlop",
      id: "betFlop",
      accessorKey: "betFlop",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "foldFlop",
      id: "foldFlop",
      accessorKey: "foldFlop",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "foldTurn",
      id: "foldTurn",
      accessorKey: "foldTurn",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "foldRiver",
      id: "foldRiver",
      accessorKey: "foldRiver",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "abnormalRaise",
      id: "abnormalRaise",
      accessorKey: "abnormalRaise",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "wrVsHero100",
      id: "wrVsHero100",
      accessorKey: "wrVsHero100",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "minRaiseVsBetFlopExp",
      id: "minRaiseVsBetFlopExp",
      accessorKey: "minRaiseVsBetFlopExp",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "minRaiseVsBetTurnExp",
      id: "minRaiseVsBetTurnExp",
      accessorKey: "minRaiseVsBetTurnExp",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "minRaiseVsBetRiverExp",
      id: "minRaiseVsBetRiverExp",
      accessorKey: "minRaiseVsBetRiverExp",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "raiseVsPotBetFlop",
      id: "raiseVsPotBetFlop",
      accessorKey: "raiseVsPotBetFlop",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "raiseVsPotBetTurn",
      id: "raiseVsPotBetTurn",
      accessorKey: "raiseVsPotBetTurn",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "raiseVsPotBetRiver",
      id: "raiseVsPotBetRiver",
      accessorKey: "raiseVsPotBetRiver",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "raiseVsPotBetPosFlop",
      id: "raiseVsPotBetPosFlop",
      accessorKey: "raiseVsPotBetPosFlop",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "raiseVsPotBetPosTurn",
      id: "raiseVsPotBetPosTurn",
      accessorKey: "raiseVsPotBetPosTurn",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "raiseVsPotBetPosRiver",
      id: "raiseVsPotBetPosRiver",
      accessorKey: "raiseVsPotBetPosRiver",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "raiseVsPotBetFlopCount",
      id: "raiseVsPotBetFlopCount",
      accessorKey: "raiseVsPotBetFlopCount",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "raiseVsPotBetTurnCount",
      id: "raiseVsPotBetTurnCount",
      accessorKey: "raiseVsPotBetTurnCount",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "raiseVsPotBetRiverCount",
      id: "raiseVsPotBetRiverCount",
      accessorKey: "raiseVsPotBetRiverCount",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "raiseVsPotBetFlopPosCount",
      id: "raiseVsPotBetFlopPosCount",
      accessorKey: "raiseVsPotBetFlopPosCount",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "raiseVsPotBetTurnPosCount",
      id: "raiseVsPotBetTurnPosCount",
      accessorKey: "raiseVsPotBetTurnPosCount",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "raiseVsPotBetRiverPosCount",
      id: "raiseVsPotBetRiverPosCount",
      accessorKey: "raiseVsPotBetRiverPosCount",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "fingerPrint01",
      id: "fingerPrint01",
      accessorKey: "fingerPrint01",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "hugeOpenRaise",
      id: "hugeOpenRaise",
      accessorKey: "hugeOpenRaise",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "hugeLimpRaise",
      id: "hugeLimpRaise",
      accessorKey: "hugeLimpRaise",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      header: "huge3Bet",
      id: "huge3Bet",
      accessorKey: "huge3Bet",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },

  ],
  []
);