/* tslint:disable */

/* eslint-disable */

/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { AxiosInstance, AxiosPromise, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";

// @ts-ignore
import {
  BASE_PATH,
  BaseAPI,
  COLLECTION_FORMATS,
  RequestArgs,
  RequiredError,
  operationServerMap,
} from "../base";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  createRequestFunction,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from "../common";
import type { Configuration } from "../configuration";
// @ts-ignore
import { PlatformServiceRawDto } from "../models";
// @ts-ignore
import { PlatformServiceRegularDto } from "../models";

/**
 * PlatformServiceControllerApi - axios parameter creator
 * @export
 */
export const PlatformServiceControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {GetAllPlatformServicesDomainEnum} domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPlatformServices: async (
      domain: GetAllPlatformServicesDomainEnum,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("getAllPlatformServices", "domain", domain);
      const localVarPath = `/api/v2/platform-service/combined/{domain}/find-all`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPlatformServicesFromAllRooms: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/platform-service/combined/all-rooms/find-all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GetGSListDomainEnum} domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGSList: async (
      domain: GetGSListDomainEnum,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("getGSList", "domain", domain);
      const localVarPath = `/api/v2/platform-service/GS/{domain}/find-all`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GetGSRawDomainEnum} domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGSRaw: async (
      domain: GetGSRawDomainEnum,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("getGSRaw", "domain", domain);
      const localVarPath = `/api/v2/platform-service/GS/{domain}/find-all/raw`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GetTESListDomainEnum} domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTESList: async (
      domain: GetTESListDomainEnum,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("getTESList", "domain", domain);
      const localVarPath = `/api/v2/platform-service/TES/{domain}/find-all`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GetTESRawDomainEnum} domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTESRaw: async (
      domain: GetTESRawDomainEnum,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("getTESRaw", "domain", domain);
      const localVarPath = `/api/v2/platform-service/TES/{domain}/find-all/raw`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PlatformServiceControllerApi - functional programming interface
 * @export
 */
export const PlatformServiceControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PlatformServiceControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {GetAllPlatformServicesDomainEnum} domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllPlatformServices(
      domain: GetAllPlatformServicesDomainEnum,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<PlatformServiceRegularDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPlatformServices(
        domain,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["PlatformServiceControllerApi.getAllPlatformServices"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllPlatformServicesFromAllRooms(
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<PlatformServiceRegularDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllPlatformServicesFromAllRooms(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap[
          "PlatformServiceControllerApi.getAllPlatformServicesFromAllRooms"
        ]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {GetGSListDomainEnum} domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGSList(
      domain: GetGSListDomainEnum,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<PlatformServiceRegularDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGSList(domain, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["PlatformServiceControllerApi.getGSList"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {GetGSRawDomainEnum} domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGSRaw(
      domain: GetGSRawDomainEnum,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlatformServiceRawDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGSRaw(domain, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["PlatformServiceControllerApi.getGSRaw"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {GetTESListDomainEnum} domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTESList(
      domain: GetTESListDomainEnum,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<PlatformServiceRegularDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTESList(domain, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["PlatformServiceControllerApi.getTESList"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {GetTESRawDomainEnum} domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTESRaw(
      domain: GetTESRawDomainEnum,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlatformServiceRawDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTESRaw(domain, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["PlatformServiceControllerApi.getTESRaw"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * PlatformServiceControllerApi - factory interface
 * @export
 */
export const PlatformServiceControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PlatformServiceControllerApiFp(configuration);
  return {
    /**
     *
     * @param {GetAllPlatformServicesDomainEnum} domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPlatformServices(
      domain: GetAllPlatformServicesDomainEnum,
      options?: any
    ): AxiosPromise<Array<PlatformServiceRegularDto>> {
      return localVarFp
        .getAllPlatformServices(domain, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPlatformServicesFromAllRooms(
      options?: any
    ): AxiosPromise<Array<PlatformServiceRegularDto>> {
      return localVarFp
        .getAllPlatformServicesFromAllRooms(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {GetGSListDomainEnum} domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGSList(
      domain: GetGSListDomainEnum,
      options?: any
    ): AxiosPromise<Array<PlatformServiceRegularDto>> {
      return localVarFp.getGSList(domain, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {GetGSRawDomainEnum} domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGSRaw(
      domain: GetGSRawDomainEnum,
      options?: any
    ): AxiosPromise<Array<PlatformServiceRawDto>> {
      return localVarFp.getGSRaw(domain, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {GetTESListDomainEnum} domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTESList(
      domain: GetTESListDomainEnum,
      options?: any
    ): AxiosPromise<Array<PlatformServiceRegularDto>> {
      return localVarFp
        .getTESList(domain, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {GetTESRawDomainEnum} domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTESRaw(
      domain: GetTESRawDomainEnum,
      options?: any
    ): AxiosPromise<Array<PlatformServiceRawDto>> {
      return localVarFp.getTESRaw(domain, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * PlatformServiceControllerApi - object-oriented interface
 * @export
 * @class PlatformServiceControllerApi
 * @extends {BaseAPI}
 */
export class PlatformServiceControllerApi extends BaseAPI {
  /**
   *
   * @param {GetAllPlatformServicesDomainEnum} domain
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlatformServiceControllerApi
   */
  public getAllPlatformServices(
    domain: GetAllPlatformServicesDomainEnum,
    options?: RawAxiosRequestConfig
  ) {
    return PlatformServiceControllerApiFp(this.configuration)
      .getAllPlatformServices(domain, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlatformServiceControllerApi
   */
  public getAllPlatformServicesFromAllRooms(options?: RawAxiosRequestConfig) {
    return PlatformServiceControllerApiFp(this.configuration)
      .getAllPlatformServicesFromAllRooms(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GetGSListDomainEnum} domain
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlatformServiceControllerApi
   */
  public getGSList(domain: GetGSListDomainEnum, options?: RawAxiosRequestConfig) {
    return PlatformServiceControllerApiFp(this.configuration)
      .getGSList(domain, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GetGSRawDomainEnum} domain
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlatformServiceControllerApi
   */
  public getGSRaw(domain: GetGSRawDomainEnum, options?: RawAxiosRequestConfig) {
    return PlatformServiceControllerApiFp(this.configuration)
      .getGSRaw(domain, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GetTESListDomainEnum} domain
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlatformServiceControllerApi
   */
  public getTESList(domain: GetTESListDomainEnum, options?: RawAxiosRequestConfig) {
    return PlatformServiceControllerApiFp(this.configuration)
      .getTESList(domain, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GetTESRawDomainEnum} domain
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlatformServiceControllerApi
   */
  public getTESRaw(domain: GetTESRawDomainEnum, options?: RawAxiosRequestConfig) {
    return PlatformServiceControllerApiFp(this.configuration)
      .getTESRaw(domain, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * @export
 */
export const GetAllPlatformServicesDomainEnum = {
  HhEco: "HH_ECO",
  PwEco: "PW_ECO",
  WpcEco: "WPC_ECO",
  AaEco: "AA_ECO",
  Services: "SERVICES",
} as const;
export type GetAllPlatformServicesDomainEnum =
  (typeof GetAllPlatformServicesDomainEnum)[keyof typeof GetAllPlatformServicesDomainEnum];
/**
 * @export
 */
export const GetGSListDomainEnum = {
  HhEco: "HH_ECO",
  PwEco: "PW_ECO",
  WpcEco: "WPC_ECO",
  AaEco: "AA_ECO",
  Services: "SERVICES",
} as const;
export type GetGSListDomainEnum =
  (typeof GetGSListDomainEnum)[keyof typeof GetGSListDomainEnum];
/**
 * @export
 */
export const GetGSRawDomainEnum = {
  HhEco: "HH_ECO",
  PwEco: "PW_ECO",
  WpcEco: "WPC_ECO",
  AaEco: "AA_ECO",
  Services: "SERVICES",
} as const;
export type GetGSRawDomainEnum = (typeof GetGSRawDomainEnum)[keyof typeof GetGSRawDomainEnum];
/**
 * @export
 */
export const GetTESListDomainEnum = {
  HhEco: "HH_ECO",
  PwEco: "PW_ECO",
  WpcEco: "WPC_ECO",
  AaEco: "AA_ECO",
  Services: "SERVICES",
} as const;
export type GetTESListDomainEnum =
  (typeof GetTESListDomainEnum)[keyof typeof GetTESListDomainEnum];
/**
 * @export
 */
export const GetTESRawDomainEnum = {
  HhEco: "HH_ECO",
  PwEco: "PW_ECO",
  WpcEco: "WPC_ECO",
  AaEco: "AA_ECO",
  Services: "SERVICES",
} as const;
export type GetTESRawDomainEnum =
  (typeof GetTESRawDomainEnum)[keyof typeof GetTESRawDomainEnum];
