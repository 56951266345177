import { useSessionsQuery } from "@/api/hooks/sys-accont-sessions-hooks";
import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  MRT_ColumnFilterFnsState,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import { FindDomainEnum, KeyFilter, KeySort } from "@/shared/api";
import useSessionsColumns from "@/module/accounts-module/hooks/session-tables-columns-hook";
import { useSessionsTable } from "@/module/accounts-module/hooks/sessions-table-hook";
import { AccountSessionType } from "@/types/accountTypes";
import ServiceSessionsTopToolbar from "@/module/accounts-module/components/service-sessions-top-toolbar";
import { assembleSessionFilterFns, assembleSessionsDefaultFilters } from "@/lib/accountsUtils";
import { COMMON_REFETCH_INTERVAL } from "@/lib/constants";
import { Route } from "@/routes/accounts/$room.$type";
import { assembleKeyFilters, assembleSort } from "@/lib/cfg-utils";

const requiredFilters: KeyFilter[] = [
  {
    key: "$.session_type",
    mode: "STRICT_IN",
    value: [
      AccountSessionType.GRABBER,
      AccountSessionType.MANAGER,
      AccountSessionType.SCANNER
    ]
  }
]

const ServiceSessionsTable = (
  {
    roomName,
    isOpen = true
  } : {
    roomName: string;
    isOpen?: boolean;
  }
) => {
  const columns = useSessionsColumns({roomName});

  const searchParams = Route.useSearch();

  const sessionId = useMemo(() => {
    const param = searchParams["session_id"];
    return param != null ? JSON.parse(param) : null;
  }, [searchParams]);

  const [sorting, setSorting] =
    useState<MRT_SortingState>([])

  const [columnFilters, setColumnFilters] =
    useState<MRT_ColumnFiltersState>(() => assembleSessionsDefaultFilters({ sessionId: sessionId }));

  const [showColumnFilters, setShowColumnFilters] = useState<boolean>(columnFilters.length > 0)

  const [columnFilterFns, setColumnFilterFns] = useState<MRT_ColumnFilterFnsState>(
    assembleSessionFilterFns({ columns, sessionId: sessionId })
  )

  useEffect(() => {
    if(sessionId !== null) {
      setColumnFilters((prevState) => {
        return [
          ...prevState.filter(item => item.id !== "$.username"),
          { id: "$.username", value: sessionId }
        ]
      })

      setColumnFilterFns(prevState => {
        const fns = {...prevState}
        fns["$.username"] = "equals"
        return fns
      })
    }
  }, [sessionId]);

  useEffect(() => {
    setShowColumnFilters(prevState => prevState || columnFilters.length > 0)
  }, [columnFilters]);

  const [pagination, setPagination] =
    useState<MRT_PaginationState>({ pageIndex: 0, pageSize: 30 });

  const filters = useMemo<Set<KeyFilter>>(
    () => assembleKeyFilters(columnFilters, columnFilterFns, [...requiredFilters]),
    [columnFilters, columnFilterFns]
  );

  const sort = useMemo<KeySort[]>(() => assembleSort(sorting), [sorting])

  const {
    data: sysSessionsResponse,
    isError: isSysSessionsError,
    isLoading: isSysSessionsLoading,
    isRefetching: isSysSessionsRefetching,
    refetch: refetchSysSessions
  } = useSessionsQuery(
    {
      domain: roomName as FindDomainEnum,
      filters: filters,
      pagination,
      sort: sort,
    },
    {
      enabled: isOpen,
      refetchInterval: COMMON_REFETCH_INTERVAL
    }
  );

  const table = useSessionsTable({
    columns: columns,
    data: sysSessionsResponse?.data.content ?? [],
    isDataLoadingError: isSysSessionsError,
    loadingErrorMessage: sysSessionsResponse?.statusText,
  }, {
    rowCount: sysSessionsResponse?.data.totalElements ?? 0,
    pageCount: sysSessionsResponse?.data.totalPages ?? 0,
    state: {
      isLoading: isSysSessionsLoading,
      showProgressBars: isSysSessionsRefetching,
      pagination: pagination,
      columnFilters: columnFilters,
      columnFilterFns: columnFilterFns,
      sorting: sorting,
      showColumnFilters: showColumnFilters,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onColumnFilterFnsChange: setColumnFilterFns,
    onSortingChange: setSorting,
    onShowColumnFiltersChange: setShowColumnFilters,
    renderTopToolbarCustomActions: ({ table }) =>
      <ServiceSessionsTopToolbar
        table={table}
        roomName={roomName}
        filters={filters}
        totalElementsCount={sysSessionsResponse?.data.totalElements ?? 0}
        onSuccess={() => refetchSysSessions()}
      />,
  });

  useEffect(() => {
    table.resetRowSelection();
  }, [table, columnFilters, columnFilterFns, sorting])

  return <MaterialReactTable table={table} />;
}


export default ServiceSessionsTable