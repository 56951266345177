import { message, Popconfirm } from "antd";
import { IconButton } from "@mui/material";
import { Power, PowerOff } from "@mui/icons-material";
import React from "react";
import { MRT_Row, MRT_TableInstance } from "material-react-table";
import { ExportCsvDomainEnum, KeyFilter } from "@/shared/api";
import { useSysCfgChangeEnabledMutation } from "@/api/hooks/sys-cfg-hooks";
import { ROOM_CONFIGS_NAMES } from "@/lib/constants";
import { AxiosResponse } from "axios";
import { NameIdentityItem } from "@/types/commonTypes";
import { assembleParticularFilter } from "@/lib/cfg-utils";


const ChangeEnabledConfigsTableControl = <T extends NameIdentityItem> (
  {
    roomName,
    table,
    enabled,
    filters,
    onSuccess,
    totalElementsCount
  } : {
    roomName: string;
    table: MRT_TableInstance<T>;
    enabled: boolean;
    filters: Set<KeyFilter>;
    onSuccess?: (rowsCount: number) => void;
    totalElementsCount: number;
  }
) => {

  const mutation = useSysCfgChangeEnabledMutation();

  function handleMutationAction(rows: MRT_Row<T>[]) {
    if(rows.length > 0) {
      mutateParticular(rows)
    } else {
      mutateAll()
    }
  }

  function mutateAll() {
    callMutation(filters)
  }

  function mutateParticular(rows: MRT_Row<T>[]) {
    callMutation(assembleParticularFilter(rows))
  }

  function callMutation(filters: Set<KeyFilter>) {
    const op = enabled ? "enabling" : "disabling";
    const fop = enabled ? "enabled" : "disabled";
    mutation.mutate(
      {
        domain: roomName as ExportCsvDomainEnum,
        cfgName: ROOM_CONFIGS_NAMES.ACCOUNT,
        enabled,
        filters
      }, {
        onSuccess: (rs: AxiosResponse<number>) => {
          message.success(`${rs.data} configs successfully ${fop}`).then()
          onSuccess && onSuccess(rs.data)
        },
        onError: (err) => {
          console.error(`configs ${op} error`, err)
          message.error(`Something went wrong during ${op}, contact an administrator`).then()
        }
      }
    )
  }


  return (
    <Popconfirm
      title={`${enabled ? "Enable" : "Disable"} items`}
      description={`Are you sure to ${enabled ? "enable" : "disable"} these items?`}
      onConfirm={() => handleMutationAction(table.getSelectedRowModel().rows)}
    >
      <IconButton size="small" style={{ fontSize: 12 }} color={enabled ? "success" : "error"}>
        {enabled ? (
          <>
            <Power /> Enable
          </>
        ) : (
          <>
            <PowerOff /> Disable
          </>
        )}
        ({
          table.getSelectedRowModel().rows.length > 0
            ? (table.getSelectedRowModel().rows.length)
            : (totalElementsCount)
        })
      </IconButton>
    </Popconfirm>
  )
}


export default ChangeEnabledConfigsTableControl;