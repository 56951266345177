import { ConfigDto } from "@/shared/api";
import { useCallback, useMemo } from "react";
import { MaterialReactTable, MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import { CheckboxElement } from "react-hook-form-mui";
import { Divider, IconButton, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { ClearAll, DoneAll, HelpOutlined } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";

export type ColumnName = 'read' | 'write' | 'delete' | 'execute' | 'exclusive' | 'disable_auth_filters';

const UserConfigsForm = (
  {
    name = "",
    configs,
    onSelectColumn,
    onClearColumn,
    onSelectRow,
    onClearRow,
    onSelectAll,
    onClearAll
  } : {
    name?: string;
    configs: ConfigDto[],
    onSelectColumn?: (column: ColumnName) => void,
    onClearColumn?: (column: ColumnName) => void,
    onSelectRow?: (configName: string) => void,
    onClearRow?: (configName: string) => void,
    onSelectAll?: () => void;
    onClearAll?: () => void;
  }
) => {
  const SelectAllItem = useCallback((columnName: ColumnName, closeMenu: () => void) => [
    <MenuItem
      key="select-all"
      onClick={() => {
        onSelectColumn && onSelectColumn(columnName)
        closeMenu()
      }}
    >
      <ListItemIcon><DoneAll/></ListItemIcon>
      <ListItemText>Select All</ListItemText>
    </MenuItem>,
    <MenuItem
      key="clear-all"
      onClick={() => {
        onClearColumn && onClearColumn(columnName)
        closeMenu()
      }}
    >
      <ListItemIcon><ClearAll/></ListItemIcon>
      <ListItemText>Clear All</ListItemText>
    </MenuItem>,
    <Divider key="select-all-divider"/>
  ], [onClearColumn, onSelectColumn])

  const columns = useMemo<MRT_ColumnDef<ConfigDto>[]>(() => [
    {
      header: "Collection name",
      editEnabled: false,
      accessorFn: (row) => row.name,
    },
    {
      header: "Read",
      accessorFn: (row) => `${row.read}`,
      renderColumnActionsMenuItems: ({ internalColumnMenuItems, closeMenu }) => [
        ...SelectAllItem('read', closeMenu),
        ...internalColumnMenuItems,
      ],
      Cell: ({ row }) => (
        <CheckboxElement name={`${name}.${row.index}.read`} checked={row.original.read}/>
      )
    },
    {
      header: "Write",
      accessorFn: (row) => `${row.write}`,
      renderColumnActionsMenuItems: ({ internalColumnMenuItems, closeMenu }) => [
        ...SelectAllItem('write', closeMenu),
        ...internalColumnMenuItems,
      ],
      Cell: ({row}) => (
        <CheckboxElement name={`${name}.${row.index}.write`} checked={row.original.write}/>
      )
    },
    {
      header: "Delete",
      accessorFn: (row) => row.delete,
      renderColumnActionsMenuItems: ({ internalColumnMenuItems, closeMenu }) => [
        ...SelectAllItem('delete', closeMenu),
        ...internalColumnMenuItems,
      ],
      Cell: ({row}) => (
        <CheckboxElement name={`${name}.${row.index}.delete`} checked={row.original.delete}/>
      )
    },
    {
      header: "Execute",
      accessorFn: (row) => row.execute,
      renderColumnActionsMenuItems: ({ internalColumnMenuItems, closeMenu }) => [
        ...SelectAllItem('execute', closeMenu),
        ...internalColumnMenuItems,
      ],
      Cell: ({row}) => (
        <CheckboxElement name={`${name}.${row.index}.execute`} checked={row.original.execute}/>
      )
    },
    {
      header: "Migrate",
      accessorFn: (row) => row.exclusive,
      renderColumnActionsMenuItems: ({ internalColumnMenuItems, closeMenu }) => [
        ...SelectAllItem('exclusive', closeMenu),
        ...internalColumnMenuItems,
      ],
      Cell: ({row}) => (
        <CheckboxElement name={`${name}.${row.index}.exclusive`} checked={row.original.exclusive}/>
      )
    },
    {
      header: "DAF",
      accessorFn: (row) => row.disable_auth_filters,
      renderColumnActionsMenuItems: ({ internalColumnMenuItems, closeMenu }) => [
        ...SelectAllItem('disable_auth_filters', closeMenu),
        ...internalColumnMenuItems,
      ],
      Header: () => (
        <Tooltip title="Disable Auth Filters For Configs">
          <div style={{ display: "flex" }}>
            <span>DAF</span>
            <span>&nbsp;</span>
            <HelpOutlined sx={{width: "12px", height: "12px", color: "rgba(0,0,0,0.44)"}} />
          </div>
        </Tooltip>
      ),
      Cell: ({row}) => (
        <CheckboxElement name={`${name}.${row.index}.disable_auth_filters`} checked={row.original.disable_auth_filters}/>
      )
    },
  ], [SelectAllItem, name]);

  const table = useMaterialReactTable({
    data: configs,
    columns: columns,
    enableColumnActions: true,
    enableRowActions: true,
    getRowId: (originalRow) => originalRow.name,
    enableStickyHeader: true,
    enablePagination: false,
    enableGrouping: true,
    enableColumnFilterModes: true,
    enableColumnDragging: false,
    initialState: {
      density: "compact",
      pagination: { pageSize: 50, pageIndex: 0 },
    },
    muiTableContainerProps: {
      sx: {
        flexGrow: 10,
        overflowY: "scroll",
        height: "500px",
      },
    },
    renderTopToolbarCustomActions: () => (
      <div>
        <Tooltip title="Enable All">
          <IconButton
            size="small"
            onClick={() => onSelectAll && onSelectAll()}
          >
            <DoneAll/>
          </IconButton>
        </Tooltip>
        <Tooltip title="Clear All">
          <IconButton
            size="small"
            onClick={() => onClearAll && onClearAll()}
          >
            <ClearAll/>
          </IconButton>
        </Tooltip>
      </div>
    ),
    renderRowActions: ({ row: { original } }) => (
      <>
        <Tooltip title="Enable All">
          <IconButton
            size="small"
            onClick={() => onSelectRow && onSelectRow(original.name)}
          >
            <DoneAll/>
          </IconButton>
        </Tooltip>
        <Tooltip title="Clear All">
          <IconButton
            size="small"
            onClick={() => onClearRow && onClearRow(original.name)}
          >
            <ClearAll/>
          </IconButton>
        </Tooltip>
      </>
    )
  });

  return (
    <MaterialReactTable table={table}/>
  )
}

export default UserConfigsForm