import {createFileRoute, Navigate} from '@tanstack/react-router'
import UserPage from "@/pages/user-page";
import {useAtom} from "jotai/index";
import {meStore} from "@/store/store";
import {isOwner} from "@/lib/auth-predicates";

export const Route = createFileRoute('/users/$id')({
  component: Component,
})

function Component() {
  const [me] = useAtom(meStore);
  const { id } = Route.useParams();

  if(!isOwner(me)) {
    return <Navigate to="/"/>
  }

  return <UserPage userId={id} />
}