import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useState } from "react";



import { COMMON_REFETCH_INTERVAL } from "@/lib/constants";
import { Updater, VisibilityState } from "@tanstack/table-core";


import { useSysRouterInstanceQuery } from "@/api/hooks/sys-router-instances-hooks";
import {
  useRouterInstanceTableColumns,
} from "@/module/room-configs-module/router-config-module/instance-table/router-instance-table-columns";

const RouterInstanceTable = (
  {
    setRowsAmount,
  } : {
    setRowsAmount: (amount: number) => void;
  }
) => {
  //This logic for reset row selection while using filtering/grouping
  const [columnFilters, setColumnFilters] = useState<any>([]);
  const [grouping, setGrouping] = useState<any>([]);
  const [globalFilter, setGlobalFilter] = useState<any>(undefined);

  //Storing table setting into localStorage
  const loadTablesSettingsFromLocalStorage = () => {
    const item = localStorage.getItem("tablesSettings");
    return item ? JSON.parse(item) : {};
  }

  const [allTablesUserSetting, setAllTablesUserSetting] = useState<any>(() => loadTablesSettingsFromLocalStorage());

  useEffect(() => {
    localStorage.setItem("tablesSettings", JSON.stringify(allTablesUserSetting))
  }, [allTablesUserSetting]);


  const {
    data: routerInstances, isLoading: configsIsLoading
  } = useSysRouterInstanceQuery({
      refetchInterval: COMMON_REFETCH_INTERVAL
  });

  useEffect(() => {
    setRowsAmount( Object.values(routerInstances?.data ?? {}).length)
  }, [routerInstances, setRowsAmount])

  const table = useMaterialReactTable({
    data: Object.values(routerInstances?.data ?? {}),
    columns: useRouterInstanceTableColumns(),
    initialState: {
      pagination: { pageSize: 20, pageIndex: 0 },
      density: "compact",
      sorting: [{id: "priority", desc: false}],
    },
    enableGrouping: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableColumnFilterModes: true,
    enablePagination: false,
    enableStickyHeader: true,
    enableSorting: true,
    autoResetPageIndex: false,
    state: {
      globalFilter,
      grouping,
      columnFilters,
      columnVisibility: allTablesUserSetting?.router?.columnVisibility ?? {},
      columnOrder: allTablesUserSetting?.router?.columnOrder ?? [],
      isLoading: configsIsLoading,
    },
    muiTablePaperProps: {
      sx: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",

      },
    },
    muiTableContainerProps: {
      sx: {
        flexGrow: 10,
        overflowY: "scroll",
        height: "500px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        whiteSpace: "pre",
      }
    },

    onColumnFiltersChange: setColumnFilters,
    onGroupingChange: setGrouping,
    onGlobalFilterChange: setGlobalFilter,
    onColumnOrderChange: (newColumnOrder) => {
      setAllTablesUserSetting({
        ...allTablesUserSetting,
        router: {...allTablesUserSetting.router, columnOrder: (newColumnOrder as string[])},
      })

    },
    onColumnVisibilityChange: (updaterFn: Updater<VisibilityState> ) => {
      setAllTablesUserSetting((prev: any) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newVisibility = updaterFn(prev.router?.columnVisibility);
        return {
          ...prev,
          router: {...prev.router, columnVisibility: newVisibility},
        };
      })
    },
  });


  useEffect(() => {
    table.resetRowSelection();
  }, [columnFilters, grouping, globalFilter, table]);

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};

export default RouterInstanceTable;
