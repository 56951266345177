import { useSysCfgApplyGroupsMutation } from "@/api/hooks/sys-cfg-hooks";
import { KeyFilter } from "@/shared/api";
import { Groups } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { NameIdentityItem } from "@/types/commonTypes";
import { MRT_Row, MRT_TableInstance } from "material-react-table";
import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import ApplyAuthGroupsModal from "@/components/apply-auth-groups-modal";
import { AxiosResponse } from "axios";
import { message } from "antd";
import Tooltip from "@mui/material/Tooltip";
import { assembleParticularFilter } from "@/lib/cfg-utils";


const ApplyAuthGroupsTableControl = <T extends NameIdentityItem>({
  domain,
  cfgName,
  table,
  filters,
  totalElementsCount,
  onSuccess
} : {
  domain: string;
  cfgName: string;
  table: MRT_TableInstance<T>;
  filters: Set<KeyFilter>;
  totalElementsCount: number;
  onSuccess?: (rowsCount: number) => void;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const mutation = useSysCfgApplyGroupsMutation();

  const {data: authGroupsRs, isLoading: authGroupsIsLoading } =
    useAuthGroupsQuery({enabled: isOpen});

  function handleMutationAction(rows: MRT_Row<T>[], authGroups: string[]) {
    if(rows.length > 0) {
      mutateParticular(rows, authGroups)
    } else {
      mutateAll(authGroups)
    }
  }

  function mutateAll(authGroups: string[]) {
    callMutation(filters, authGroups)
  }

  function mutateParticular(rows: MRT_Row<T>[], authGroups: string[]) {
    callMutation(assembleParticularFilter(rows), authGroups)
  }

  const callMutation = (filters: Set<KeyFilter>, authGroups: string[]) => {
    mutation.mutate({ domain, cfgName, filters, authGroups }, {
      onSuccess: (rs: AxiosResponse<number>) => {
        message.success(`${rs.data} configs successfully updated`).then()
        setIsOpen(false)
        onSuccess && onSuccess(rs.data)
      },
      onError: (err) => {
        console.error("configs update error", err)
        message.error("Something went wrong during updating data, contact an administrator").then()
      }
    })
  }

  return (
    <>
      <Tooltip title="Set Auth Groups">
        <IconButton
          size="small"
          style={{ fontSize: 12 }}
          color="info"
          onClick={() => setIsOpen(true)}
        >
          <Groups /> &nbsp; Set AG
          ({
            table.getSelectedRowModel().rows.length > 0
              ? (table.getSelectedRowModel().rows.length)
              : (totalElementsCount)
          })
        </IconButton>
      </Tooltip>

      <ApplyAuthGroupsModal
        onClose={() => setIsOpen(false)}
        onSave={(data) => handleMutationAction(table.getSelectedRowModel().rows, data.auth_groups)}
        authGroups={authGroupsRs?.data.map(i => i.name) ?? []}
        authGroupsIsLoading={authGroupsIsLoading}
        confirmLoading={mutation.isPending}
        open={isOpen}
        maxWidth="xs"
        fullWidth
      />
    </>
  )
}

export default ApplyAuthGroupsTableControl