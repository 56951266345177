import AError from "../components/AError";
import {Col, Row} from "antd";


const ErrorPage = ({ errorMessage } : { errorMessage: string | undefined}) => {
  return (
    <Row style={{height: "100%"}} align="middle">
      <Col span={24}>
        <div style={{display: "flex", justifyContent: "space-around"}}>
          <AError errorMessage={errorMessage ?? "Something went wrong"}/>
        </div>
      </Col>
    </Row>
  )
}

export default ErrorPage;