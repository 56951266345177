import { Divider, Typography } from "antd";
import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import AccountsModule from "../module/accounts-module/accounts-module";
import { useAtom } from "jotai/index";
import { useNavigate } from "@tanstack/react-router";
import { Route } from "@/routes/accounts/$room.$type"
import { appCurrentRoom } from "@/store/store";


const AccountsPage = () => {
  const { type } = Route.useParams();
  const [currentRoom] = useAtom(appCurrentRoom);
  const navigate = useNavigate();

  useEffect(() => {
    if(!currentRoom) {
      void navigate({to: "/"})
    } else {
      void navigate({to: `/accounts/${currentRoom.name}/${type}`})
    }
  }, [currentRoom, navigate]);

  return (<>
    <Grid
      container
      spacing={2}
      height="100%"
      direction="column"
      flexWrap="nowrap"
      style={{overflowY:'auto',overflowX:'auto'}}
    >
      <Grid item>
        <Typography.Title level={2}>Accounts</Typography.Title>
      </Grid>

      <Grid item>
        <Divider style={{marginTop: 0}}/>
      </Grid>

      <Grid item xs={10}>
        <AccountsModule />
      </Grid>
    </Grid>
  </>)
}


export default AccountsPage;