import { green, red } from "@ant-design/colors";
import { CheckCircleFilled, CopyOutlined, InfoCircleFilled } from "@ant-design/icons";
import { Button, Divider, List, Modal, Tooltip, message } from "antd";
import React, { useState } from "react";
import { Link } from "@tanstack/react-router"

import { ResultDto } from "@/types/commonTypes";
import { AccountType } from "@/module/accounts-module/accounts-module";

const BulkOperationReportModal = ({
  title = undefined,
  resultList,
  showSuccess = false,
  showProblematic = false,
  open,
  onCancel,
}: {
  title?: string;
  resultList: ResultDto<any>[];
  showSuccess?: boolean;
  showProblematic?: boolean;
  open?: boolean;
  onCancel: () => void;
}) => {
  const copyToClipboard = (data: string) => {
    navigator.clipboard
      .writeText(data)
      .then(() => message.success("Text has been successfully copied to clipboard"));
  };
  console.log(resultList)
  const successList = resultList.filter((item) => item.status === "SUCCESS");
  const errorList = resultList.filter((item) => item.status === "ERROR");

  const [successListIsShown, setSuccessListIsShown] = useState<boolean>(false);
  const [errorListIsShown, setErrorListIsShown] = useState<boolean>(false);

  const joinListNamesToString = (data: ResultDto<any>[]) => {
    return data.map((proxy) => proxy.name).join("\n");
  };

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <Modal
        open={open}
        onCancel={() => onCancel()}
        maskClosable={false}
        width={1000}
        title={title}
        okText={undefined}
        footer={[]}
      >
        <Divider />

        {showSuccess && successList.length > 0 && (
          <Button onClick={() => setSuccessListIsShown(!successListIsShown)} type="link">
            {successListIsShown ? "Hide" : `Show success (${successList.length})`}
          </Button>
        )}

        {successList.length > 0 && (
          <div hidden={!successListIsShown}>
            <List
              bordered
              header={
                <List.Item
                  style={{ padding: 0 }}
                  actions={[
                    <Tooltip key="1" title="Copy all values">
                      <Button
                        icon={<CopyOutlined />}
                        onClick={() => copyToClipboard(joinListNamesToString(successList))}
                      />
                    </Tooltip>,
                  ]}
                >
                  <List.Item.Meta title={`Success elements (${successList.length})`} />
                </List.Item>
              }
              size="small"
              dataSource={successList}
              renderItem={(item: ResultDto<any>) => (
                <List.Item
                  actions={[
                    <Tooltip key="1" title="Copy value">
                      <Button
                        icon={<CopyOutlined />}
                        onClick={() => copyToClipboard(item?.name ?? "")}
                      />
                    </Tooltip>,
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <div>
                        <CheckCircleFilled style={{ color: green.primary }} />
                        &nbsp;&nbsp;
                        {item.data?.name ?? item.name}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
        )}

        {showSuccess && showProblematic && successList.length > 0 && errorList.length > 0 && (
          <Divider />
        )}

        {showProblematic && errorList.length > 0 && (
          <Button onClick={() => setErrorListIsShown(!errorListIsShown)} type="link">
            {errorListIsShown ? "Hide" : `Show problematic (${errorList.length})`}
          </Button>
        )}

        {errorList.length > 0 && (
          <div hidden={!errorListIsShown}>
            <List
              bordered
              header={
                <List.Item
                  style={{ padding: 0 }}
                  actions={[
                    <Tooltip key="1" title="Copy all values">
                      <Button
                        icon={<CopyOutlined />}
                        onClick={() => copyToClipboard(joinListNamesToString(errorList))}
                      />
                    </Tooltip>,
                  ]}
                >
                  <List.Item.Meta title={`Problematic elements (${errorList.length})`} />
                </List.Item>
              }
              size="small"
              dataSource={errorList}
              renderItem={(item: ResultDto<any>) => (
                <List.Item
                  key={item.name}
                  actions={[
                    <Tooltip key="1" title="Copy value">
                      <Button
                        icon={<CopyOutlined />}
                        onClick={() => copyToClipboard(item?.name ?? "")}
                      />
                    </Tooltip>,
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <div>
                        <InfoCircleFilled style={{ color: red.primary }} />
                        &nbsp;&nbsp;
                        {item.name}
                      </div>
                    }
                    description={
                      <>
                        {item.message}
                        <br />
                        {item.dataOnError &&
                          Object.entries(item.dataOnError).map(
                            ([key, value]: [string, any]) => (
                              <div key={key}>
                                <div>
                                  <strong>Collection Name:</strong> {value.collectionName}
                                </div>
                                <div>
                                  <strong>Deletable entity value:</strong> {value.foreignKeyValue}
                                </div>
                                <div>
                                  <strong>Room:</strong> {value.roomName}
                                </div>
                                <div>
                                  <strong>Amount of Dependencies:</strong>{" "}
                                  {value.collectionName === "account" ? (
                                    <Link
                                      to="/accounts/$room/$type"
                                      params={{
                                        room: value.roomName,
                                        type: AccountType.regular
                                      }}

                                      search={{
                                        [value.foreignKeyFieldName]: value.foreignKeyValue,
                                      }}
                                    >
                                      {value.amountOfDependencies}
                                    </Link>
                                  ) : (
                                    "value.amountOfDependencies"
                                  )}
                                </div>
                                <br />
                              </div>
                            )
                          )}
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default BulkOperationReportModal;
