import { IconButton } from "@mui/material";
import React, { useState } from "react";
import JoinClubModal from "@/module/accounts-module/components/join-leave-club-modal";
import { MRT_Row, MRT_TableInstance } from "material-react-table";
import { JoinAccountsToClubsDomainEnum, KeyFilter, SysAccountSessionRegularDto } from "@/shared/api";
import { useLeaveClubsMutation } from "@/api/hooks/command-hooks";
import { message } from "antd";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { assembleParticularFilterCt } from "@/lib/cfg-utils";


const JoinClubsTableControl = (
  {
    roomName,
    table,
    filters,
    totalElementsCount,
    onSuccess,
  } : {
    roomName: string;
    table: MRT_TableInstance<SysAccountSessionRegularDto>;
    filters: Set<KeyFilter>;
    totalElementsCount: number;
    onSuccess?: () => void;
  }
) => {
  const mutation = useLeaveClubsMutation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function handleMutationAction(rows: MRT_Row<SysAccountSessionRegularDto>[], clubs: string[]) {
    if(rows.length > 0) {
      mutateParticular(rows, clubs)
    } else {
      mutateAll(clubs)
    }
  }

  function mutateAll(clubs: string[]) {
    callMutation(filters, clubs)
  }

  function mutateParticular(rows: MRT_Row<SysAccountSessionRegularDto>[], clubs: string[]) {
    callMutation(assembleParticularFilterCt({
      rows: rows, keyName: "id", keySupplier: (val) => val.id
    }), clubs)
  }

  function callMutation(filters: Set<KeyFilter>, clubs: string[]) {
    mutation.mutate({
      domain: roomName as JoinAccountsToClubsDomainEnum,
      filters: filters,
      clubs: new Set(clubs)
    }, {
      onSuccess: () => {
        message.success(`leave clubs command successfully sent`).then()
        setIsOpen(false);
        onSuccess && onSuccess()
      },
      onError: (err) => {
        console.error("leave clubs command error", err)
        message.error("Something went wrong during clubs is being leaved, contact the administrator").then()
      }
    })
  }


  return (
    <>
      <IconButton
        size="small"
        style={{ fontSize: 12 }}
        color="error"
        onClick={() => setIsOpen(true)}
      >
        <ArrowDownwardIcon /> Leave clubs
        ({
          table.getSelectedRowModel().rows.length > 0
            ? (table.getSelectedRowModel().rows.length)
            : (totalElementsCount)
        })
      </IconButton>
      <JoinClubModal
        isOpen={isOpen}
        onCancel={() => setIsOpen(false)}
        title="Join club(s)"
        onSave={(clubIds) => handleMutationAction(table.getSelectedRowModel().rows, clubIds)}
      />
    </>
  )
}

export default JoinClubsTableControl;