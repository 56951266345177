import { Configuration } from "@/shared/api/configuration";
import { SysAccountControllerApi } from "@/shared/api/apis/sys-account-controller-api";
import { useMemo } from "react";
import { SysCfgControllerApi } from "@/shared/api/apis/sys-cfg-controller-api";
import { CommandControllerApi } from "@/shared/api/apis/command-controller-api";
import { SysAccountSessionControllerApi } from "@/shared/api/apis/sys-account-session-controller-api";
import { CommandJournalControllerApi } from "@/shared/api/apis/command-journal-controller-api";
import { UtilControllerApi } from "@/shared/api/apis/util-controller-api";
import { AuthGroupsControllerApi } from "@/shared/api/apis/auth-groups-controller-api";
import { UserControllerApi } from "@/shared/api/apis/user-controller-api";
import { SysRouterInstanceControllerApi } from "@/shared/api/apis/sys-router-instance-controller-api";
import { RoomConfigControllerApi } from "@/shared/api/apis/room-config-controller-api";
import { MeControllerApi } from "@/shared/api/apis/me-controller-api";
import { SysDomainControllerApi } from "@/shared/api/apis/sys-domain-controller-api";
import { SysDataMainControllerApi } from "@/shared/api/apis/sys-data-main-controller-api";
import { PlatformServiceControllerApi } from "@/shared/api/apis/platform-service-controller-api";
import { JsonFormControllerApi } from "@/shared/api/apis/json-form-controller-api";
import {
    SysClusteringPatternExternalDataControllerApi
} from "@/shared/api/apis/sys-clustering-pattern-external-data-controller-api";
import { SysStatserviceControllerApi } from "@/shared/api/apis/sys-statservice-controller-api";


const configuration = new Configuration({ basePath: "" });

const sysAccountApi = new SysAccountControllerApi(configuration);
const sysCfgApi = new SysCfgControllerApi(configuration);
const commandApi = new CommandControllerApi(configuration);
const sysAccountSessionsApi = new SysAccountSessionControllerApi(configuration);
const commandJournalApi = new CommandJournalControllerApi(configuration);
const utilApi = new UtilControllerApi(configuration);
const sysRouterInstanceControllerApi = new SysRouterInstanceControllerApi(configuration);
const authGroupsApi = new AuthGroupsControllerApi(configuration);
const userApi = new UserControllerApi(configuration);
const roomConfigApi = new RoomConfigControllerApi(configuration);
const meApi = new MeControllerApi(configuration);
const sysDomainApi = new SysDomainControllerApi(configuration);
const sysDataCommonApi = new SysDataMainControllerApi(configuration);
const platformServiceApi = new PlatformServiceControllerApi(configuration);
const jsonFormApi = new JsonFormControllerApi(configuration);
const sysClusteringPatternExternalDataApi = new SysClusteringPatternExternalDataControllerApi(configuration);
const sysStatserviceControllerApi = new SysStatserviceControllerApi(configuration);

export const useApi = () => useMemo(
  () => ({
    sysAccountApi,
    sysCfgApi,
    commandApi,
    sysAccountSessionsApi,
    commandJournalApi,
    utilApi,
    sysRouterInstanceControllerApi,
    authGroupsApi,
    userApi,
    roomConfigApi,
    meApi,
    sysDomainApi,
    sysDataCommonApi,
    platformServiceApi,
    jsonFormApi,
    sysClusteringPatternExternalDataApi,
    sysStatserviceControllerApi
  }),
  []
)