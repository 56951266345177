import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useApi } from "@/shared/api/use-api";
import { SysDomainRegularDto } from "@/shared/api";


export const useSysDomainQuery = (
  onlyRooms = false,
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<SysDomainRegularDto[]>>, "queryKey">
) => {
  const { sysDomainApi } = useApi();

  return useQuery({
    queryKey: ["/domain", onlyRooms],
    queryFn: ({ signal }) => sysDomainApi.getDomains(onlyRooms, { signal }),
    ...queryOptions
  })
}