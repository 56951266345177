import { IconButton } from "@mui/material";
import React, { useMemo, useState } from "react";
import ASelectPopover from "@/components/ASelectPopover";
import { RocketLaunch } from "@mui/icons-material";
import { ExploitationStage } from "@/lib/constants";
import { MRT_Row, MRT_TableInstance } from "material-react-table";
import { KeyFilter, RunAccountsDomainEnum, SysAccountRegularDto } from "@/shared/api";
import { keepPreviousData } from "@tanstack/react-query";
import { useRunAccountsMutation } from "@/api/hooks/command-hooks";
import { message } from "antd";
import { useGetAllTESRawQuery } from "@/api/hooks/platform-service-hooks";
import { assembleParticularFilter } from "@/lib/cfg-utils";


const RunAccountsTableControl = (
  {
    roomName,
    table,
    filters,
    onSuccessRun,
    totalElementsCount
  } : {
    roomName: string;
    table: MRT_TableInstance<SysAccountRegularDto>;
    filters: Set<KeyFilter>;
    onSuccessRun?: () => void;
    totalElementsCount: number;
  }
) => {
  const [open, setOpen] = useState<boolean>(false);
  const [tesInstanceId, setTesInstanceId] = useState<string | undefined>(undefined);

  const { data: gsListRs, isLoading: tesListIsLoading } = useGetAllTESRawQuery(
    {roomName},
    {refetchInterval: 15000, enabled: open, placeholderData: keepPreviousData}
  )

  const tesList = useMemo(() => {
    return gsListRs?.data ?? []
  }, [gsListRs]);

  const mutation = useRunAccountsMutation();

  const selectOptions = useMemo(() => {
    return tesList
      ?.filter(item => item.mode !== ExploitationStage.UNAVAILABLE)
      .map(item => ({ value: item.id, label: item.id })) ?? []
  }, [tesList]);


  function handleRunAccountsActionIsDone() {
    setTesInstanceId(undefined);
    setOpen(false);
  }

  function handleMutationAction(rows: MRT_Row<SysAccountRegularDto>[]) {
    if(rows.length > 0) {
      mutateParticular(rows)
    } else {
      mutateAll()
    }
  }

  function mutateAll() {
    callMutation(filters)
  }

  function mutateParticular(rows: MRT_Row<SysAccountRegularDto>[]) {
    callMutation(assembleParticularFilter(rows))
  }

  function callMutation(filters: Set<KeyFilter>) {
    if(!tesInstanceId || tesInstanceId === "") {
      console.error("run accounts command error: there's no tes instance id")
      message.error("Something went wrong during running accounts, contact the administrator").then()
    } else {
      mutation.mutate({ domain: roomName as RunAccountsDomainEnum, tesId: tesInstanceId, filters }, {
        onSuccess: () => {
          message.success(`run accounts command successfully sent`).then()
          handleRunAccountsActionIsDone();
          onSuccessRun && onSuccessRun()
        },
        onError: (err) => {
          console.error("run accounts command error", err)
          message.error("Something went wrong during account is being ran, contact the administrator").then()
        }
      })
    }
  }


  return (
    <ASelectPopover
      loading={ mutation.isPending }
      containerStyle={{ display: "flex" }}
      buttonText="Ok"
      disabled={!tesInstanceId || tesInstanceId.trim() === ""}
      onOk={() => handleMutationAction(table.getSelectedRowModel().rows)}
      popoverProps={{
        title: "Choose a tes instance",
        open: open,
        trigger: "click",
        onOpenChange: (visible) => {
          if (!visible) {
            handleRunAccountsActionIsDone();
          }
        },
      }}
      selectProps={{
        style: { width: 350 },
        value: tesInstanceId,
        onChange: (value) => setTesInstanceId(value),
        allowClear: true,
        options: selectOptions,
        placeholder: "Choose tes instance",
        loading: tesListIsLoading,
      }}
    >
      <IconButton
        size="small"
        style={{ fontSize: 12 }}
        color="primary"
        onClick={() => setOpen(true)}
      >
        <RocketLaunch /> Run
        ({
          table.getSelectedRowModel().rows.length > 0
            ? (table.getSelectedRowModel().rows.length)
            : (totalElementsCount)
        })
      </IconButton>
    </ASelectPopover>
  )
}


export default RunAccountsTableControl