import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Edit, Power, PowerOff } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { Button, Checkbox, Popconfirm } from "antd";
import { MaterialReactTable, MRT_ColumnDef, MRT_TableInstance, useMaterialReactTable } from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import RegularConfigCreateModal from "@/components/config-modals/regular-config-create-modal";
import RegularConfigEditModal from "@/components/config-modals/regular-config-edit-modal";
import { Updater, VisibilityState } from "@tanstack/table-core";
import {
  useSysCfgChangeEnabledMutation,
  useSysCfgCreateMutation,
  useSysCfgDeleteMutation,
  useSysCfgQuery,
  useSysCfgUpdateMutation,
} from "@/api/hooks/sys-cfg-hooks";
import {
  ChangeCfgEnabledDomainEnum,
  CreateCfgDomainEnum,
  DeleteCfgDomainEnum,
  GetAllCfgsDomainEnum,
  PatchCfgDataDomainEnum, ResultDtoLong,
  SysCfgCreateRequestDto,
  SysCfgRegularDto,
  SysCfgUpdateRequestDto,
} from "@/shared/api";
import { ROOM_CONFIGS_NAMES } from "@/lib/constants";
import { enqueueSnackbar } from "notistack";
import CommonConfigWriteModal from "@/components/config-modals/common-config-write-modal";
import { useGetJsonForm } from "@/module/room-configs-module/hooks/room-configs-hooks";
import BulkOperationReportModal from "@/components/bulk-operation-report-modal";
import { useCfgPerm, useIsOwnerOrSuperAdmin } from "@/auth/hooks";
import ApplyAuthGroupsTableControl from "@/components/apply-auth-groups-table-control";
import { assembleNamesFilter } from "@/lib/cfg-utils";


const GameGroupConfigTable = ({
  room,
}: {
  room: string;
}) => {

  const {data: configsRs, isLoading: configsIsLoading, refetch} = useSysCfgQuery({
    domain: room as GetAllCfgsDomainEnum,
    config_name: ROOM_CONFIGS_NAMES.GAME_GROUP
  });

  const cfgPerm = useCfgPerm({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.GAME_GROUP,
  });

  const roomConfigsSortedData = React.useMemo(() => {
    return (
      configsRs?.data.content?.sort(
        (a, b) => new Date(b.created_at).getDate() - new Date(a.created_at).getDate()
      ) ?? []
    );
  }, [configsRs]);

  const isOwner = useIsOwnerOrSuperAdmin();

  const { data: jsonFormSchema } = useGetJsonForm({
    roomName: room,
    configName: ROOM_CONFIGS_NAMES.GAME_GROUP,
  });

  const { mutate: createCfgMutate } = useSysCfgCreateMutation();
  const { mutate: changeEnabledMutate } = useSysCfgChangeEnabledMutation();
  const { mutate: deleteCfgsMutate } = useSysCfgDeleteMutation();
  const { mutate: updateCfgMutate } = useSysCfgUpdateMutation();

  //This logic for reset row selection while using filtering/grouping
  const [columnFilters, setColumnFilters] = useState<any>([]);
  const [grouping, setGrouping] = useState<any>([]);
  const [globalFilter, setGlobalFilter] = useState<any>(undefined);
  const [editingConfig, setEditingConfig] = useState<SysCfgRegularDto | undefined>(undefined);
  const [addConfig, setAddConfig] = useState<boolean>(false);
  const [bulkOperationResult, setBulkOperationResult] = useState<ResultDtoLong[] | undefined>(undefined);


  //Storing table setting into localStorage
  const loadTablesSettingsFromLocalStorage = () => {
    const item = localStorage.getItem("tablesSettings");
    return item ? JSON.parse(item) : {};
  }

  const [allTablesUserSetting, setAllTablesUserSetting] = useState<any>(() => loadTablesSettingsFromLocalStorage());

  useEffect(() => {
    localStorage.setItem("tablesSettings", JSON.stringify(allTablesUserSetting))
  }, [allTablesUserSetting]);
  //





  function handleClickAddConfig() {
    setAddConfig(true);
  }

  function handleClickSaveNewConfig(config: SysCfgCreateRequestDto) {
    createCfgMutate({
      domain: room as CreateCfgDomainEnum,
      cfgName: ROOM_CONFIGS_NAMES.GAME_GROUP,
      body: {...config, auth_groups: config.auth_groups ?? new Set<string>() }
    }, {
      onSuccess: (response) => {
        enqueueSnackbar(`Config successfully saved`, {variant: 'success'});
        setAddConfig(false)
        void refetch()
      },
      onError: (err) => {
        enqueueSnackbar(`Operation failure: ${err.message}. Contact an administrator`, {variant: 'error'});
        console.error("Adding config failure", err);
      }
    })
  }

  function handleClickUpdateConfig(name: string, config: SysCfgUpdateRequestDto) {
    updateCfgMutate({
      domain: room as PatchCfgDataDomainEnum,
      cfgName: ROOM_CONFIGS_NAMES.GAME_GROUP,
      name: name,
      body: config
    }, {
      onSuccess: () => {
        enqueueSnackbar(`Config successfully updated`, {variant: 'success'});
        setEditingConfig(undefined)
        void refetch()
      },
      onError: (err) => {
        enqueueSnackbar(`Operation failure: ${err.message}. Contact an administrator`, {variant: 'error'});
        console.error("Config edit failure", err);
      }
    })
  }

  function handleChangeEnableBulk(
    table: MRT_TableInstance<SysCfgRegularDto>,
    enabled: boolean
  ) {
    const names = table.getSelectedRowModel().rows.map((item) => item.original.name);

    changeEnabledMutate({
      domain: room as ChangeCfgEnabledDomainEnum,
      cfgName: ROOM_CONFIGS_NAMES.GAME_GROUP,
      enabled: enabled,
      filters: new Set([{key: "name", mode: "STRICT_IN", value: names as unknown as object}])
    }, {
      onSuccess: (response) => {
        enqueueSnackbar(`${response.data} elements has been updated`, {variant: 'success'});
        table.resetRowSelection();
        void refetch()
      },
      onError: (err) => {
        enqueueSnackbar(`Operation failure: ${err.message}. Contact an administrator`, {variant: 'error'});
        console.error("Change enabled operation failure", err);
      }
    })
  }

  function handleDeleteConfigs(table: MRT_TableInstance<SysCfgRegularDto>) {
    const names = table.getSelectedRowModel().rows.map((item) => item.original.name);

    deleteCfgsMutate({
      domain: room as DeleteCfgDomainEnum,
      cfgName: ROOM_CONFIGS_NAMES.GAME_GROUP,
      filters: new Set([{key: "name", mode: "STRICT_IN", value: names as unknown as object}])
    }, {
      onSuccess: (response) => {
        setBulkOperationResult([response.data]);
        response.data.dataOnError ?
          enqueueSnackbar(`Something went wrong`, {variant: 'error'}) :
          enqueueSnackbar(`Elements has been deleted`, {variant: 'success'});
        table.resetRowSelection();
        void refetch()
      },
      onError: (err) => {
        enqueueSnackbar(`Operation failure: ${err.message}. Contact an administrator`, {variant: 'error'});
        console.error("Delete operation failure", err);
      }
    })
  }

  const columns = useMemo<MRT_ColumnDef<SysCfgRegularDto>[]>(
    () => [
      {
        header: "Enabled",
        filterFn: "contains",
        enableEditing: false,
        accessorFn: (row) => row.enabled ?? "-",
        size: 80,
        Cell: ({ row }) => (
          <Checkbox disabled checked={row.original.enabled} />
        ),
      },
      {
        header: "Name",
        accessorKey: "name",
        filterFn: "contains",
        size: 120,
      },
      {
        header: "Gold",
        accessorKey: "data.chat_hub.gold",
        filterFn: "contains",
        accessorFn: (row) => (row.data as any).chat_hub?.gold ?? "-",
        size: 120,
      },
      {
        header: "Alc",
        accessorFn: (row) => (row.data as any).play_alc?.toString(),
        filterFn: "contains",
        size: 120,
      },
    ],
    []
  );



  const table = useMaterialReactTable({
    data: roomConfigsSortedData ?? [],
    columns: columns,
    getRowId: (originalRow) => originalRow.id,
    selectAllMode: "all",
    initialState: {
      pagination: { pageSize: 20, pageIndex: 0 },
      density: "compact",
    },
    state: {
      isLoading: configsIsLoading,
      columnFilters,
      globalFilter,
      grouping,
      columnVisibility: allTablesUserSetting?.gameGroup?.columnVisibility ?? {},
      columnOrder: allTablesUserSetting?.gameGroup?.columnOrder ?? [],
    },
    enableSelectAll: true,
    enableRowSelection: true,
    enableGrouping: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableColumnFilterModes: true,
    enableRowActions: true,
    enablePagination: true,
    enableStickyHeader: true,
    autoResetPageIndex: false,
    muiTablePaperProps: {
      sx: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
      },
    },
    muiTableContainerProps: {
      sx: {
        flexGrow: 10,
        overflowY: "scroll",
        height: "500px",
      },
    },
    onColumnFiltersChange: setColumnFilters,
    onGroupingChange: setGrouping,
    onGlobalFilterChange: setGlobalFilter,
    onColumnOrderChange: (newColumnOrder) => {
      setAllTablesUserSetting({
        ...allTablesUserSetting,
        gameGroup: {...allTablesUserSetting.gameGroup, columnOrder: (newColumnOrder as string[])},
      })

    },
    onColumnVisibilityChange: (updaterFn: Updater<VisibilityState> ) => {
      setAllTablesUserSetting((prev: any) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newVisibility = updaterFn(prev.gameGroup?.columnVisibility);
        return {
          ...prev,
          gameGroup: {...prev.gameGroup, columnVisibility: newVisibility},
        };
      })
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const selectedCount = table.getSelectedRowModel().rows.length;

      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>

          {cfgPerm.write && (
            <Button onClick={() => handleClickAddConfig()}>
              <PlusOutlined />
              New
            </Button>
          )}


          {selectedCount > 0 && (
            <div style={{ marginLeft: 30 }}>
              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="success"
                  onClick={() => handleChangeEnableBulk(table, true)}
                >
                  <Power /> Enable
                </IconButton>
              )}

              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="error"
                  onClick={() => handleChangeEnableBulk(table, false)}
                >
                  <PowerOff /> Disable
                </IconButton>
              )}

              {cfgPerm.delete && (
                <Popconfirm
                  title="Delete items"
                  description="Are you sure to delete these items? (Changes cannot be undone)"
                  onConfirm={() => handleDeleteConfigs(table)}
                >
                  <IconButton
                    size="small"
                    style={{ fontSize: "15px" }}
                    color="error"
                  >
                    <DeleteOutlined /> Delete
                  </IconButton>
                </Popconfirm>
              )}

              {isOwner && (
                <ApplyAuthGroupsTableControl
                  domain={room}
                  cfgName={ROOM_CONFIGS_NAMES.GAME_GROUP}
                  table={table}
                  filters={assembleNamesFilter(table)}
                  totalElementsCount={selectedCount}
                  onSuccess={() => void refetch()}
                />
              )}
            </div>
          )}
        </div>
      );
    },
    renderRowActions: ({ row }) => (
      <Box>
        <IconButton onClick={() => setEditingConfig({ ...row.original })}>
          <Edit />
        </IconButton>
      </Box>
    ),
  });


  useEffect(() => {
    table.resetRowSelection();
  }, [columnFilters, grouping, globalFilter, table]);

  return (
    <>
      <MaterialReactTable table={table} />

      {addConfig && (
        <>
          {jsonFormSchema ? (
            <RegularConfigCreateModal
              title="Create config"
              open={!!(addConfig && jsonFormSchema)}
              uiSchema={jsonFormSchema}
              onSave={(config) => handleClickSaveNewConfig(config)}
              onCancel={() => setAddConfig(false)}
            />
          ) : (
            <CommonConfigWriteModal
              mode="add"
              isOpen={addConfig}
              onSave={(config) => handleClickSaveNewConfig(config)}
              onCancel={() => setAddConfig(false)}
              disabled={!cfgPerm.write}
            />
          )}
        </>
      )}

      {editingConfig && (
        <>
          {jsonFormSchema ? (
            <RegularConfigEditModal
              title="Edit config"
              open={!!(editingConfig && jsonFormSchema)}
              uiSchema={jsonFormSchema}
              config={editingConfig}
              onSave={(config) => handleClickUpdateConfig(config.name, config)}
              onCancel={() => setEditingConfig(undefined)}
              disabled={!cfgPerm.write}
            />
          ) : (
            <CommonConfigWriteModal
              mode="edit"
              isOpen={!!editingConfig}
              defaultValues={editingConfig}
              onCancel={() => setEditingConfig(undefined)}
              onSave={(config) => handleClickUpdateConfig(config.name, config)}
              disabled={!cfgPerm.write}
            />
          )}
        </>
      )}

      {bulkOperationResult  && (
        <BulkOperationReportModal
          resultList={bulkOperationResult}
          title="Operation result"
          showProblematic
          showSuccess
          onCancel={() => setBulkOperationResult(undefined)}
          open={!!bulkOperationResult}
        />
      )}
    </>
  );
};

export default GameGroupConfigTable;
