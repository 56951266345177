import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-lua";
import "ace-builds/src-noconflict/theme-dracula";
import "ace-builds/webpack-resolver";
import { Input, Modal, Typography, message, Select, Form, Checkbox } from "antd";
import React, { useEffect, useMemo, useRef } from "react";
import AceEditor from "react-ace";



import {
  EditClusteringPattern,
  EditedClusteringPattern,
} from "@/components/config-modals/clustering-pattern-edit-modal/types";
import {
  beforeSendNormalizers,
  formInitState,
} from "@/components/config-modals/clustering-pattern-edit-modal/constants";
import { arrayNumberNormalizer } from "@/lib/common-utils";
import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import {useAtom} from "jotai/index";
import {meStore} from "@/store/store";
import {isOwner} from "@/lib/auth-predicates";

const ClusteringPatternCreateModal = ({
  open,
  mode,
  initialData,
  onSave,
  onCancel,
  confirmLoading = false,
  disabled = false
}: {
  open: boolean,
  mode?: string,
  initialData?: EditClusteringPattern;
  onSave?: (data: EditedClusteringPattern) => void;
  onCancel?: () => void,
  confirmLoading?: boolean,
  disabled?: boolean
}) => {
  const [me] = useAtom(meStore);

  const {
    data: authGroupsRs,
    isLoading: authGroupsIsLoading
  } = useAuthGroupsQuery()

  const defaultValues = useMemo<EditedClusteringPattern>(() => {
    let authGroups = initialData?.auth_groups ? Array.from(initialData.auth_groups) : []

    if(authGroupsRs?.data.length === 1 && !isOwner(me)) {
      authGroups = authGroupsRs.data.map(it => it.name)
    }

    return initialData ? {
      ...initialData,
      enabled: true,
      authGroups
    } : {
      ...formInitState,
      authGroups
    };
  }, [initialData]);


  const authGroupsOptions = useMemo(() => {
    return authGroupsRs?.data.map(i => ( {label: i.name, value: i.name } ))
  }, [authGroupsRs?.data]);

  const [form] = Form.useForm<EditedClusteringPattern>();

  useEffect(() => {
    form.setFieldsValue({...defaultValues});
  }, [defaultValues, open, form]);

  const stringArrayNumberNormalizer = (values: any): string[] => {
    return arrayNumberNormalizer(values).map(number => number.toString());
  }

  function handleSubmit() {
    form.validateFields()
      .then(value => {
        if(!onSave) return;
        let result = value;
        beforeSendNormalizers.forEach(normalizer => result = normalizer(result));
        onSave(result);
      })
      .catch((reason) => {
        message.error("Form data is invalid. Fix the errors and try again.").then();
        console.error("Form data is invalid. Fix the errors and try again.", reason);
      });
  }

  const editorRef = useRef(null);

  // Custom validator function for AceEditor
  const validateEditorContent = (value: any) => {
    // @ts-ignore
    const errors = editorRef.current?.editor.getSession().getAnnotations()

    if (errors.length > 0) {
      console.log(errors)
      return Promise.reject(
        new Error(
          errors.map((item: { column: string; row: string; text: string; }) => {
                return `column : ${item.column}, row : ${item.row}, text : "${item.text}"`;
          })
        )
      );
    }
    return Promise.resolve();
  };

  return (
    <Modal
      open={open}
      okText="Submit"
      title={<Typography.Title level={3}>{mode === "add" ? "Create Clustering Pattern" : "Edit Clustering Patten"}</Typography.Title>}
      width={900}
      maskClosable={false}
      onOk={() => handleSubmit()}
      confirmLoading={confirmLoading}
      onCancel={() => onCancel && onCancel()}
      okButtonProps={{ disabled: disabled }}
    >
      <Form size="large"
            className="a-common-form"
            disabled={disabled}
            form={form}
            autoComplete="off"
            style={{ maxWidth: "100%" }}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 20 }}
            initialValues={{...defaultValues, data: {...defaultValues.data}}}>

        <Form.Item
          name="name"
          label="Name"

          rules={[
            {required: true}
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>

        <Form.Item
          name="authGroups"
          label="Auth Groups"
          rules={[
            { required: !isOwner(me) }
          ]}
        >
          <Select
            options={authGroupsOptions}
            disabled={disabled || (authGroupsRs?.data.length === 1 && !isOwner(me))}
            loading={authGroupsIsLoading}
            style={{width: "100%"}}
            mode="multiple"
            placeholder="Auth Groups"
          />
        </Form.Item>

        {mode === "add" && (<Form.Item name="enabled" label="Enabled" valuePropName="checked" initialValue={false}>
          <Checkbox
            style={{ width: 25, height: 25 }}
            defaultChecked={false}
          />
        </Form.Item>)}

        <Form.Item name={["data", "category"]} label="Category">
          <Input
            placeholder="Category"
          />
        </Form.Item>

        <Form.Item name={["data", "pids"]} label="Pids"
                   normalize={(value) => stringArrayNumberNormalizer(value)}>
          <Select mode="tags" placeholder="Enter here"/>
        </Form.Item>

        <Form.Item
          name={["data", "script"]}
          label="Script"
          valuePropName="value"
          rules={[{ validator: validateEditorContent }]}
        >
          <AceEditor
            readOnly={disabled}
            name="name"
            mode="lua"
            placeholder="script"
            theme="dracula"
            width="100%"
            ref={editorRef}

            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
            }}
          />
        </Form.Item>
      </Form>

    </Modal>
  );
};

export default ClusteringPatternCreateModal;
