import ConfigsEditTableControl from "@/components/configs-edit-table-control";
import { MRT_TableInstance } from "material-react-table";
import {
  GetAllCfgsRawCombinedDomainEnum,
  KeyFilter,
  SysAccountRegularDto,
  SysCfgRawDto,
} from "@/shared/api";
import { useMemo, useState } from "react";
import { ACCOUNT_RELATION_CONFIGS_NAMES, ACCOUNT_RELATION_SERVICE_CONFIGS_NAMES, STALE_TIME_60 } from "@/lib/constants";
import { mapAccountBulkEditInputs } from "@/lib/accountsUtils";
import { keepPreviousData } from "@tanstack/react-query";
import { useSysCfgCombinedQuery } from "@/api/hooks/sys-cfg-hooks";


const EditAccountsTableControl = (
  {
    title,
    roomName,
    table,
    filters,
    onSuccess,
    totalElementsCount
  } : {
    title?: string;
    roomName: string;
    table: MRT_TableInstance<SysAccountRegularDto>;
    filters: Set<KeyFilter>;
    onSuccess?: (rowsCount: number) => void;
    totalElementsCount: number;
  }
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    data: relConfigs
  } = useSysCfgCombinedQuery(
    {
      domain: roomName as GetAllCfgsRawCombinedDomainEnum,
      config_names: [...ACCOUNT_RELATION_CONFIGS_NAMES]
    },
    { enabled: isOpen, placeholderData: keepPreviousData, staleTime: STALE_TIME_60 }
  );

  const {data: relServiceConfigsRs} = useSysCfgCombinedQuery({
    domain: GetAllCfgsRawCombinedDomainEnum.Services,
    config_names: ACCOUNT_RELATION_SERVICE_CONFIGS_NAMES,
  }, {
    enabled: isOpen
  });

  const fields = useMemo(() => {
    const configsMap = relConfigs?.data.concat(relServiceConfigsRs?.data ?? []).reduce(
      (acc, current) => acc.set(current.config_name, current.configs),
      new Map<string, SysCfgRawDto[]>()
    );
    return mapAccountBulkEditInputs(configsMap)
  }, [relConfigs, relServiceConfigsRs]);

  const handleOnSuccess = (count: number) => {
    setIsOpen(false)
    onSuccess && onSuccess(count)
  }

  return (
    <ConfigsEditTableControl
      title={title}
      roomName={roomName}
      table={table}
      filters={filters}
      isOpen={isOpen}
      onSuccess={handleOnSuccess}
      setIsOpen={(val) => setIsOpen(val)}
      fields={fields}
      totalElementsCount={totalElementsCount}
    />
  )
}


export default EditAccountsTableControl;