import { useApi } from "@/shared/api/use-api";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { AccountsAndSessionCountByTypeDomainEnum, AccountsAndSessionsCountByTypeDto } from "@/shared/api";


export const useAccountAndSessionsCountByTypeQuery = (
  {
    domain
  } : {
    domain: AccountsAndSessionCountByTypeDomainEnum
  },
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<AccountsAndSessionsCountByTypeDto>>, "queryKey">
) => {
  const { utilApi } = useApi()
  return useQuery({
    queryKey: [`/util/${domain}/account-and-session-count/by-types`],
    queryFn: ({ signal }) => utilApi.accountsAndSessionCountByType(domain, { signal }),
    ...queryOptions
  })
}