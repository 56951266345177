import {
  GetGSListDomainEnum,
  GetTESListDomainEnum, PlatformServiceRawDto,
  PlatformServiceRegularDto,
} from "@/shared/api";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useApi } from "@/shared/api/use-api";

export const usePlatformServicesFromAllRoomsQuery = (
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<PlatformServiceRegularDto[]>>, "queryKey">
) => {
  const { platformServiceApi } = useApi();

  return useQuery({
    queryKey: [`/platform-service/combined/all-rooms/find-all`],
    queryFn: ({ signal }) => platformServiceApi.getAllPlatformServicesFromAllRooms({ signal }),
    ...queryOptions
  });
}

export const useGetAllGSQuery = (
  {roomName} : {roomName: string},
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<PlatformServiceRegularDto[]>>, "queryKey">
) => {
  const { platformServiceApi } = useApi();

  return useQuery({
    queryKey: [`/platform-service/GS/${roomName}/find-all`],
    queryFn: ({ signal }) => platformServiceApi.getGSList(roomName as GetGSListDomainEnum, { signal }),
    ...queryOptions
  });
}

export const useGetAllTESQuery = (
  {roomName} : {roomName: string},
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<PlatformServiceRegularDto[]>>, "queryKey">
) => {
  const { platformServiceApi } = useApi();

  return useQuery({
    queryKey: [`/platform-service/TES/${roomName}/find-all`],
    queryFn: ({ signal }) => platformServiceApi.getTESList(roomName as GetTESListDomainEnum, { signal }),
    ...queryOptions
  });
}


export const useGetAllGSRawQuery = (
  {roomName} : {roomName: string},
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<PlatformServiceRawDto[]>>, "queryKey">
) => {
  const { platformServiceApi } = useApi();

  return useQuery({
    queryKey: [`/platform-service/GS/${roomName}/find-all/raw`],
    queryFn: ({ signal }) => platformServiceApi.getGSRaw(roomName as GetGSListDomainEnum, { signal }),
    ...queryOptions
  });
}

export const useGetAllTESRawQuery = (
  {roomName} : {roomName: string},
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<PlatformServiceRawDto[]>>, "queryKey">
) => {
  const { platformServiceApi } = useApi();

  return useQuery({
    queryKey: [`/platform-service/TES/${roomName}/find-all/raw`],
    queryFn: ({ signal }) => platformServiceApi.getTESRaw(roomName as GetTESListDomainEnum, { signal }),
    ...queryOptions
  });
}