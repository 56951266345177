import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-lua";
import "ace-builds/src-noconflict/theme-dracula";
import "ace-builds/webpack-resolver";
import React, { useEffect } from "react";


import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";


import CloseIcon from "@mui/icons-material/Close";
import { EmailDomainForm } from "@/components/config-modals/email-domain-edit-modal/types";
import { AutocompleteElement, TextFieldElement } from "react-hook-form-mui";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import { SysCfgCreateRequestDto } from "@/shared/api";


export default function EmailDomainCreateModal ({
  open,
  onSave,
  onCancel,
  confirmLoading = false,
} : {
  open: boolean,
  mode?: string,
  initialData?: EmailDomainForm;
  onSave?: (data: SysCfgCreateRequestDto[]) => void;
  onCancel?: () => void,
  confirmLoading?: boolean,
}) {

const schema = z.object({
  names: z
    .string()
    .transform((val) => val.split("\n").map((str) => str.trim()))
    .refine((arr) => arr.every((str) => /^[a-zA-Z0-9-]*\.[a-zA-Z0-9-]+$/.test(str) && str.length <= 20), {
      message: "Each domain must consist of letters, hyphens, numbers, and one dot",
    }),
  authGroups: z.array(z.string()).nonempty("Auth groups are required"),
});

  const { data: authGroupsRs, isLoading: authGroupsIsLoading } = useAuthGroupsQuery({
    enabled: open
  });

  const {
    register,
    control,
    reset,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm<EmailDomainForm>({
    mode: "onTouched",
    resolver: zodResolver(schema),
    //defaultValues: defaultValues
  })

  useEffect(() => {
    reset()
  }, [open,reset])


  const onSubmit: SubmitHandler<EmailDomainForm> = (data) => {
    const result = data.names.map((name) => {
      return {
        auth_groups: new Set(data.authGroups),
        name: name,
        enabled: true,
      }
    })

    if(!onSave) return;
    onSave(result);

    onCancel && onCancel() //purifying form and close dialog
  }

  return (
    <>
      <Dialog
        open={open}
        key="email-domain-modal"
      >
        <DialogTitle>
          {"Add email domains" }
          <IconButton
            aria-label="close"
            onClick={() => onCancel && onCancel()}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)} style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px", /* Расстояние между элементами */
            width: "500px", /* Ширина формы */
          }}>
            <AutocompleteElement
              control={control}
              loading={authGroupsIsLoading}
              name="authGroups"
              multiple
              options={authGroupsRs?.data?.map(i => i.name) ?? []}
              label="Auth Groups"
              textFieldProps={{
                error: !!errors.authGroups,
                helperText: errors.authGroups?.message ?? " ",
                variant: "standard"
              }}
            />

            <TextFieldElement
              name="names"
              control={control}
              label="Domains"
              placeholder="Write each domain on a new line"
              multiline
              error={!!errors.name?.message}
              helperText={errors.name?.message}
              minRows={7}
            />


            <div style={{display: "flex",
              justifyContent: "flex-end"}}>
              <Button type="button" style={{marginRight: "5px"}} onClick={() => onCancel && onCancel()}>Cancel</Button>
              <Button variant="contained" type="submit">Submit</Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      </>
  );
};