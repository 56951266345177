import { COMMON_PERM, ExploitationStage, PLAYHUB_CONFIGS, ROOM_NAMES } from "./constants";
import { isArray, uniq } from "lodash";
import { message } from "antd";
import { AxiosResponse } from "axios";
import { ConfigDto, RoomDto, SysDomainRegularDto, UserResponseRegularDto } from "@/shared/api";
import prettier from "prettier";
import parserJson from "prettier/parser-babel";


export const formatJson = (json: any) => {
  try {
    return prettier.format(json, {
      parser: "json",
      plugins: [parserJson],
    });
  } catch (error) {
    console.error("Invalid JSON format:", error);
    return json;
  }
}


export const getPublicUrl = () => {
  return window.location.origin;
}

export const trimSplitCsvRecord = (value: string, delimiter: string) => {
  const recordValues = value.split(delimiter);

  for(let i = 0; i < recordValues.length; i++) {
    recordValues[i] = recordValues[i].trim();
  }

  return recordValues;
}

export const isValidPort = (value: unknown): boolean => {
  const portNumber = Number(value);
  return Number.isInteger(portNumber) && portNumber >= 1 && portNumber <= 65535;
}
export const mapExploitationStageToStringName = (stage: ExploitationStage) => {
  switch (stage) {
    case ExploitationStage.PRODUCTION: return "Production";
    case ExploitationStage.TEST: return "Test";
    case ExploitationStage.UNAVAILABLE: return "Unavailable";
    default: return "Unknown value";
  }
}
export const getEnumNumberValues = (enumObj: any) : number[] => {
  if(!enumObj) return [];
  return Object.values(enumObj)
    .filter(item => typeof item === "number") as number[]
}

export const arrayNumberNormalizer = (values: any): number[] => {
  if(!isArray(values)) return [];
  const numbers = values.map(item => parseFloat(item)).filter(item => {
    const check = !isNaN(item);
    if(!check) message.error("Integers only").then();
    return check;
  }).map(item => Number(item.toFixed(1)));
  return uniq(numbers);
}

export const mapCommonRoomsToRoomDto = (domains: SysDomainRegularDto[], user: UserResponseRegularDto): RoomDto[] => {
  return domains.filter(domain => domain.is_room)
    .map( domain => {
      const room = user.rooms.find(it => it.room_name === domain.name);
      return {
        room_name: domain.name,
        configs: domain.config_names.map(it => assembleConfigsDto(it, room?.configs))
      }
    });
}

export const mapPlayhubRoomsToRoomDto = (domains: SysDomainRegularDto[], user: UserResponseRegularDto) => {
  return domains.filter(domain => domain.is_room)
    .map( domain => {
      const room = user.playhub.find(it => it.room_name === domain.name);
      return {
        room_name: domain.name,
        configs: PLAYHUB_CONFIGS.map(it => assembleConfigsDto(it.name, room?.configs))
      }
    } )
}

export const mapServiceRoomToRoomDto = (
  domains: SysDomainRegularDto[],
  user: UserResponseRegularDto
): RoomDto => {
  const servicesDomain = domains.find(it => it.name === ROOM_NAMES.SERVICES);

  if(servicesDomain) {
    return {
     room_name: servicesDomain.name,
     configs: servicesDomain.config_names.map(it => assembleConfigsDto(it, user.service.configs))
    }
  }

  return {
    room_name: ROOM_NAMES.SERVICES,
    configs: []
  }
}

const assembleConfigsDto = (configName: string, configs?: ConfigDto[]) => {
  return (configs ?? []).find(uit => uit.name === configName) ?? { name: configName, ...COMMON_PERM }
}







export const downloadFile = (response: AxiosResponse<BlobPart>) => {
  const blob = new Blob([response.data])
  const contentDisposition = response?.headers?.get
    && typeof response.headers.get == "function"
    && response.headers.get("content-disposition");

  let filename = "download.txt";

  if (contentDisposition) {
    const value = contentDisposition.toString();
    const startAt = value.replaceAll(" ", "").lastIndexOf("filename=");
    const newFilename = startAt !== -1 ? value.substring(startAt)
        .replaceAll("filename=", "")
        .replaceAll("\"", "")
        .replaceAll("'", "")
      : ""

    filename = newFilename && newFilename.trim() !== "" ? newFilename : filename;
  }

  const url = window.URL.createObjectURL(blob)
  const tempLink = document.createElement("a");

  tempLink.href = url

  tempLink.setAttribute("download", filename)
  document.body.appendChild(tempLink)
  tempLink.click()
  document.body.removeChild(tempLink)
  window.URL.revokeObjectURL(url)
}

