import { message, Popconfirm } from "antd";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import React from "react";
import { MRT_Row, MRT_TableInstance } from "material-react-table";
import { ExportCsvDomainEnum, KeyFilter, ResultDtoLong } from "@/shared/api";
import { useSysCfgDeleteMutation } from "@/api/hooks/sys-cfg-hooks";
import { ROOM_CONFIGS_NAMES } from "@/lib/constants";
import { AxiosResponse } from "axios";
import { NameIdentityItem } from "@/types/commonTypes";
import { assembleParticularFilter } from "@/lib/cfg-utils";


const DeleteConfigsTableControl = <T extends NameIdentityItem> (
  {
    roomName,
    table,
    filters,
    onSuccessDelete,
    totalElementsCount,
  } : {
    roomName: string;
    table: MRT_TableInstance<T>;
    filters: Set<KeyFilter>;
    onSuccessDelete?: (rowsCount: ResultDtoLong) => void;
    totalElementsCount: number;
  }
) => {

  const mutation = useSysCfgDeleteMutation();

  function handleMutateAction(rows: MRT_Row<T>[]) {
    if(rows.length > 0) {
      mutateParticular(rows)
    } else {
      mutateAll()
    }
    table.resetRowSelection()
  }

  function mutateAll() {
    callMutation(filters)
  }

  function mutateParticular(rows: MRT_Row<T>[]) {
    callMutation(assembleParticularFilter(rows))
  }

  function callMutation(filters: Set<KeyFilter>) {
    mutation.mutate(
      {
        domain: roomName as ExportCsvDomainEnum,
        cfgName: ROOM_CONFIGS_NAMES.ACCOUNT,
        filters
      }, {
        onSuccess: (rs: AxiosResponse<ResultDtoLong>) => {
          message.success(`${rs.data.data} configs successfully deleted`).then()
          onSuccessDelete && onSuccessDelete(rs.data)
        },
        onError: (err) => {
          console.error("configs deletion error", err)
          message.error("Something went wrong during deleting, contact an administrator").then()
        }
      }
    )
  }


  return (
    <Popconfirm
      title="Delete items"
      description="Are you sure to delete these items? (Changes cannot be undone)"
      onConfirm={() => handleMutateAction(table.getSelectedRowModel().rows)}
    >
      <IconButton size="small" style={{ fontSize: 12 }}>
        <Delete /> Delete
        ({
          table.getSelectedRowModel().rows.length > 0
            ? (table.getSelectedRowModel().rows.length)
            : (totalElementsCount)
        })
      </IconButton>
    </Popconfirm>
  )
}


export default DeleteConfigsTableControl;