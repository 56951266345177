import React, { useMemo } from "react";
import { MRT_ColumnDef } from "material-react-table";
import { SysAccountRegularDto } from "@/shared/api";
import { NilObj } from "@/types/commonTypes";
import { Tooltip } from "antd";
import { ACCOUNT_STATUS, AccountTypeEnum, APP_GMT_DATE_FORMAT } from "@/lib/constants";
import moment from "moment/moment";
import { Checkbox } from "@mui/material";
import { getTabTypeBySessionType } from "@/lib/accountsUtils";
import { Link } from "@tanstack/react-router";


export const useServiceAccountsTableColumns = (
  {
    room
  } : {
    room: string
  }
) => useMemo<MRT_ColumnDef<SysAccountRegularDto>[]>(() => [
  {
    id: "name",
    header: "Name",
    accessorFn: (row) => row.name ?? "-"
  },
  {
    id: "data.source",
    header: "Source",
    accessorFn: ({data}) => (data as NilObj)?.source ?? "-",
  },
  {
    id: "data.account_type",
    header: "Account Type",
    // accessorFn: ({data}) => (data as NilObj)?.account_type ?? "-",
    accessorFn: ({data}) => {
      const accountType = (data as NilObj)?.account_type;
      const accountTypeStr = Object.keys(AccountTypeEnum)
        .find(key => {
          const k = key as keyof typeof AccountTypeEnum
          return AccountTypeEnum[k] === accountType as unknown as number
        });

      return accountType
        ? `${accountType}${accountTypeStr ? " (" + accountTypeStr + ")" : ""}`
        : "-"
    }
  },
  {
    id: "data.cid",
    header: "Cid",
    accessorFn: ({data}) => (data as NilObj)?.cid ?? "-",
  },
  {
    id: "data.status",
    header: "Status",
    accessorFn: ({data}) => (data as NilObj)?.status ?? "-",
    Cell: ({ row: { original } }) => {
      const status = (original.data as NilObj)?.status as unknown;

      return status !== undefined && typeof status === "number" ? (
        <Tooltip title={ACCOUNT_STATUS.get(status)}>{status}</Tooltip>
      ) : "-";
    }
  },
  {
    id: "created_at",
    header: "Created at",
    accessorFn: (row) => (row.created_at ? new Date(row.created_at) : 0),
    Cell: ({ row: { original: { created_at } } }) => created_at
      ? moment(created_at).utc(false).format(APP_GMT_DATE_FORMAT)
      : "-",
  },
  {
    id: "data.comment",
    header: "Comment",
    accessorFn: ({data}) => (data as NilObj)?.comment ?? "-",
  },
  {
    id: "enabled",
    header: "Enabled",
    accessorFn: ({ enabled }) => enabled ?? "-",
    Cell: ({ row: { original: { enabled } } }) => (
      <Checkbox checked={enabled} disabled />
    ),
  },
  {
    id: "session",
    header: "Session",
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ row: { original: { session } } }) => (
      session ? (
        <Link
          to="/account/$room/$type"
          params={{
            room: room,
            type: getTabTypeBySessionType(session.session_type),
          }}
          search={{
            session_id: session.id
          }}
        />
      ) : ( "-" )
    )
  }
], [])