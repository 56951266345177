import React from "react";
import {getPublicUrl} from "@/lib/common-utils";
import {Col, Row, Typography} from "antd";

const HomePage = () => {
  return (<>
    <Row gutter={[16, 16]}  align="middle" justify="center" dir="col" style={{height: "100%"}}>
      <Col>

        <Row gutter={[16, 16]} align="middle" justify="center">
          <Col>
            <img src={`${getPublicUrl()}/main_logo.png`} alt="Main logo" style={{width: "100%", maxWidth: "350px"}}/>
          </Col>
        </Row>

        <Row gutter={[16, 16]} align="middle" justify="center">
          <Col>
            <Typography.Title level={1}>Asia Project Admin Panel</Typography.Title>
          </Col>
        </Row>
      </Col>
    </Row>
  </>)
};

export default HomePage;