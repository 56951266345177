import { ClubRegularDto, KeyFilter, KeyFilterModeEnum, KeySort, ResultStatsDto, SysCfgRegularDto } from "@/shared/api";
import {
  ICmsAggregatorData,
  IRoomConfigByDataStructureResponse,
  IRoomConfigRawResponse,
  SysCfgCmsAggregator,
} from "@/types/roomConfigTypes";
import { AnyObj, NameIdentityItem } from "@/types/commonTypes";
import {
  MRT_ColumnFilterFnsState,
  MRT_ColumnFiltersState,
  MRT_Row,
  MRT_RowData,
  MRT_SortingState, MRT_TableInstance,
} from "material-react-table";
import { EditCompensator, EditCompensatorData } from "@/components/config-modals/compensator-edit-modal/types";
import { EditRoute } from "@/components/config-modals/router-edit-modal/types";
import { aiType } from "@/components/config-modals/router-edit-modal/constants";
import { EditClusteringType } from "@/components/config-modals/clusterizer-edit-modal/types";
import { EditClusteringPattern } from "@/components/config-modals/clustering-pattern-edit-modal/types";

export const mapRoomConfigByDataStructureToMap = (configs: IRoomConfigByDataStructureResponse[]): Map<string, IRoomConfigRawResponse[]> => {
  const map = new Map<string, IRoomConfigRawResponse[]>;

  if (!configs) return map;

  configs.forEach(config => {
    map.set(config.dataStructureName, config.configs);
  });

  return map;
};

export const mapSysCfgToEditRoute = (value: SysCfgRegularDto | undefined): EditRoute | undefined => {
  if (!value) return undefined;

  const data = value.data as AnyObj;

  return {
    name: value.name,
    auth_groups: value.auth_groups ?? new Set<string>(),
    data: {
      priority: data?.priority,
      room: data?.room ?? [],
      technology: data?.technology ?? [],
      only_bot: data?.only_bot ?? "",
      partner_ai_key: data?.partner_ai_key ?? [],
      game_type: data?.game_type ?? [],
      size: data?.size ?? [],
      aid: data?.aid ?? [],
      cid: data?.cid ?? [],
      game_group: data?.game_group ?? [],
      limit: data?.limit ?? [],
      straddle: data?.straddle ?? [],
      ante: data?.ante ?? [],
      players_count: data?.players_count ?? [],
      comment: data?.comment ?? "",
      single_instance: data?.single_instance ?? false,
      ai_type: data?.ai_type ?? aiType[0].value,
      group_pattern: data?.group_pattern ?? [],
      instances: data?.instances ?? [],
    },
  };
};

export const mapSysCfgToEditClusteringType = (value: SysCfgRegularDto | undefined): EditClusteringType | undefined => {
  if (!value) return undefined;

  const data = value.data as AnyObj;

  return {
    name: value.name,
    auth_groups: value.auth_groups ?? new Set(),
    data: {
      comment: data?.comment ?? "",
      game_group: data?.game_group ?? [],
      game_type: data?.game_type ?? [],
      limit: data?.limit ?? [],
      pattern: data?.pattern ?? [], //clustering_pattern
      post_flop: data?.post_flop ?? [],
      pre_flop: data?.pre_flop ?? [],
    },
  };
};

export const mapSysCfgToEditClusteringPattern = (value: SysCfgRegularDto | undefined): EditClusteringPattern | undefined => {
  if (!value) return undefined;

  const data = value.data as AnyObj;

  return {
    name: value.name,
    auth_groups: value.auth_groups ?? new Set(),
    data: {
      script: data?.script,
      pids: data?.pids ?? [],
      category: data?.category,
    },
  };
};

export const mapSysCfgToEditCompensator = (value: SysCfgRegularDto | undefined): EditCompensator | undefined => {
  if (!value) return undefined;

  return {
    name: value.name,
    auth_groups: value.auth_groups ?? new Set(),
    data: value?.data as EditCompensatorData,
  };
};

export const assembleParticularFilterCt = <T extends MRT_RowData>(
  {
    rows,
    keyName,
    keySupplier,
  }: {
    rows: MRT_Row<T>[]
    keyName: string
    keySupplier: (val: T) => string
  },
) => {
  const names = rows.map(value => keySupplier(value.original));
  const filter: KeyFilter = { key: keyName, mode: "STRICT_IN", value: names };
  return new Set<KeyFilter>([filter]);
};

export const assembleParticularFilter =
  <T extends NameIdentityItem>(rows: MRT_Row<T>[]) => {
    const names = rows.map(value => value.original.name);
    const filter: KeyFilter = { key: "name", mode: "STRICT_IN", value: names };
    return new Set<KeyFilter>([filter]);
  };

export const assembleParticularFilterForStatserviceCsv =
  (rows: MRT_Row<ResultStatsDto>[]) => {
    const pids = rows.map(value => value.original.player.pid);
    const cids = rows.map(value =>value.original.player.cid);
    const aids = rows.map(value => value.original.player.aid);
    const gameGroups = rows.map(value => value.original.player.gameGroup);
    const rooms = rows.map(value => value.original.player.room);
    const limits = rows.map(value => value.original.player.limit);
    const gameTypes = rows.map(value => value.original.player.gameType);
    const technologies = rows.map(value => value.original.player.technology);

    const filters: KeyFilter[] = [
      { key: "pid", mode: KeyFilterModeEnum.StrictIn, value: pids },
      { key: "cid", mode: KeyFilterModeEnum.StrictIn, value: cids },
      { key: "aid", mode: KeyFilterModeEnum.StrictIn, value: aids },
      { key: "gameGroup", mode: KeyFilterModeEnum.StrictIn, value: gameGroups },
      { key: "room", mode: KeyFilterModeEnum.StrictIn, value: rooms },
      { key: "limit", mode: KeyFilterModeEnum.StrictIn, value: limits },
      { key: "gameType", mode: KeyFilterModeEnum.StrictIn, value: gameTypes },
      { key: "technology", mode: KeyFilterModeEnum.StrictIn, value: technologies },
    ];

    return new Set<KeyFilter>(filters);
  };


export const assembleKeyFilters = (
  columnFilters: MRT_ColumnFiltersState,
  columnFilterFns: MRT_ColumnFilterFnsState,
  mixinFilters: KeyFilter[],    //difference bw mixinFilters and defaultFilters that mixin are obligatory, but default should be replaced if there is a filter with the same key (user filtering exists by these fields)
  defaultFilters: KeyFilter[] = [],
) => {
  const filtersArr: KeyFilter[] = columnFilters.map(ft => ({
    key: ft.id,
    mode: (columnFilterFns[ft.id] === "fuzzy"
      ? "contains"
      : columnFilterFns[ft.id]) as KeyFilterModeEnum,
    value: ft.value as object,
  }))
    .concat(mixinFilters);

  defaultFilters
    .filter(filter => filter.value !== null)
    .forEach(defaultFilter => {
    if (!filtersArr.some(filter => filter.key === defaultFilter.key)) {
      filtersArr.push(defaultFilter);
    }
  });



  return new Set<KeyFilter>(filtersArr);
};

export const assembleSort = (sorting: MRT_SortingState): KeySort[] => {
  return sorting.map(val => ({ key: val.id, mode: val.desc ? "DESC" : "ASC" }));
};

export const enrichClubManagerWithClubData = (
  roomConfigsData: SysCfgRegularDto[],
  clubConfigsData: ClubRegularDto[],
): SysCfgCmsAggregator[] => {
  return roomConfigsData.map(config => {
    const data = config.data as (ICmsAggregatorData | undefined);
    const ccd = clubConfigsData.find(ccd => ccd.username === data?.username);
    return { ...config, data: data, cmsAggregatorRedisInfo: ccd };
  });
};

export const assembleNamesFilter = <T extends NameIdentityItem> (table: MRT_TableInstance<T>): Set<KeyFilter> => {
  const names = table.getSelectedRowModel().rows.map((item) => item.original.name);
  return new Set([{key: "name", mode: "STRICT_IN", value: names as unknown as object}]);
}