import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { ContentCopy, Edit, Power, PowerOff } from "@mui/icons-material";
import { Box, FormControlLabel, IconButton, Switch } from "@mui/material";
import { Button, Checkbox, Popconfirm } from "antd";
import { MaterialReactTable, MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import RegularConfigCreateModal from "@/components/config-modals/regular-config-create-modal";
import RegularConfigEditModal from "@/components/config-modals/regular-config-edit-modal";
import { SysCfgCmsAggregator } from "@/types/roomConfigTypes";
import { DATE_FORMAT_WITH_UTC_TAG, ROOM_CONFIGS_NAMES } from "@/lib/constants";
import moment from "moment";
import { Updater, VisibilityState } from "@tanstack/table-core";
import { GetAllCfgsDomainEnum, ResultDtoLong, SysCfgCreateRequestDto } from "@/shared/api";
import { useSysCfgQuery } from "@/api/hooks/sys-cfg-hooks";
import { useSysDataClubsQuery } from "@/api/hooks/sys-data-hooks";
import {
  useConfigChangeEnabled,
  useDeleteConfigs,
  useGetJsonForm,
  useSaveConfig,
  useUpdateConfig,
} from "@/module/room-configs-module/hooks/room-configs-hooks";
import CommonConfigWriteModal from "@/components/config-modals/common-config-write-modal";
import BulkOperationReportModal from "@/components/bulk-operation-report-modal";
import { useCfgPerm, useIsOwnerOrSuperAdmin } from "@/auth/hooks";
import { assembleNamesFilter, enrichClubManagerWithClubData } from "@/lib/cfg-utils";
import ApplyAuthGroupsTableControl from "@/components/apply-auth-groups-table-control";

const CmsAggregatorConfigTable = ({
  room,
}: {
  room: string;
}) => {
  const {data: configsRs, isLoading: configsIsLoading, refetch} = useSysCfgQuery({
    domain: room as GetAllCfgsDomainEnum,
    config_name: ROOM_CONFIGS_NAMES.CMS_AGGREGATOR
  });

  const { data: clubConfigsData } = useSysDataClubsQuery({
    domain: room as GetAllCfgsDomainEnum
  });

  const cfgPerm = useCfgPerm({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.CMS_AGGREGATOR,
  });

  const isOwner = useIsOwnerOrSuperAdmin();

  const roomConfigsSortedData = React.useMemo(() => {
    const data = enrichClubManagerWithClubData(configsRs?.data.content ?? [], clubConfigsData?.data ?? []);
    return (
      data.sort((a, b) =>
        new Date(b.created_at).getDate() - new Date(a.created_at).getDate()) ?? []
    );
  }, [clubConfigsData, configsRs]);

  const [bulkOperationResult, setBulkOperationResult] = useState<ResultDtoLong[] | undefined>(undefined);


  const { data: jsonFormSchema } = useGetJsonForm({
    roomName: room,
    configName: ROOM_CONFIGS_NAMES.CMS_AGGREGATOR,
  });

  const handleClickSaveNewConfig = useSaveConfig({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.CMS_AGGREGATOR,
    onSuccessCallback: () => {
      void refetch()
      closeAddConfig()
    }
  });

  const handleClickUpdateConfig = useUpdateConfig({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.CMS_AGGREGATOR,
    onSuccessCallback: () => {
      void refetch()
      setEditingConfig(undefined)
    }
  });

  const handleChangeEnableBulk = useConfigChangeEnabled({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.CMS_AGGREGATOR,
    onSuccessCallback: (table) => {
      void refetch()
      table.resetRowSelection()
    },
  });

  const handleDeleteConfigs = useDeleteConfigs({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.CMS_AGGREGATOR,
    onSuccessCallback: (table, response) => {
      setBulkOperationResult([response.data]);
      void refetch()
      table.resetRowSelection()
    },
  })

  //This logic for reset row selection while using filtering/grouping
  const [columnFilters, setColumnFilters] = useState<any>([]);
  const [grouping, setGrouping] = useState<any>([]);
  const [globalFilter, setGlobalFilter] = useState<any>(undefined);
  const [editingConfig, setEditingConfig] = useState<SysCfgCmsAggregator | undefined>(undefined);
  const [addConfig, setAddConfig] = useState<boolean>(false)
  const [addingConfig, setAddingConfig] = useState<SysCfgCreateRequestDto | undefined>(undefined);

  //Storing table setting into localStorage
  const loadTablesSettingsFromLocalStorage = () => {
    const item = localStorage.getItem("tablesSettings");
    return item ? JSON.parse(item) : {};
  }

  const [allTablesUserSetting, setAllTablesUserSetting] = useState<any>(() => loadTablesSettingsFromLocalStorage());

  useEffect(() => {
    localStorage.setItem("tablesSettings", JSON.stringify(allTablesUserSetting))
  }, [allTablesUserSetting]);

  function handleClickAddConfig() {
    setAddConfig(true)
  }

  function closeAddConfig() {
    setAddingConfig(undefined);
    setAddConfig(false)
  }

  const [enableColumnDragging, setEnableColumnDragging] = useState<boolean>(false);
  const [enableColumnSorting, setEnableColumnSorting] = useState<boolean>(false);

  const columns = useMemo<MRT_ColumnDef<SysCfgCmsAggregator>[]>(
    () => [
      {
        header: "Enabled",
        filterFn: "contains",
        enableEditing: false,
        accessorFn: (row) => row.enabled ?? "-",
        size: 80,
        Cell: ({ row }) => <Checkbox checked={row.original.enabled} disabled/>,
      },
      {
        header: "Name",
        accessorFn: (row) => row.name ?? "-",
        filterFn: "contains",
      },

      {
        header: "Username",
        accessorFn: (row) => row.data?.username ?? "-",
        filterFn: "contains",
      },
      {
        header: "Game group",
        accessorFn: (row) => row.data?.game_group ?? "-",
        filterFn: "contains",
        size: 120,
      },
      {
        header: "Cid",
        accessorFn: (row) => row.data?.cid ?? "-",
        filterFn: "contains",
      },
      {
        header: "Aid",
        accessorFn: (row) => row.data?.aid ?? "-",
        filterFn: "contains",
      },
      {
        header: "Is gold",
        accessorFn: (row) => row.data?.is_gold?.toString() ?? "-",
        filterFn: "contains",
      },

      {
        header: "Account gems",
        accessorFn: (row) => row?.cmsAggregatorRedisInfo?.account_gems ?? "-",
        filterFn: "contains",
      },{
        header: "Club gems",
        accessorFn: (row) => row?.cmsAggregatorRedisInfo?.club_gems ?? "-",
        filterFn: "contains",
      },{
        header: "Gold balance",
        accessorFn: (row) => row.cmsAggregatorRedisInfo?.gold_balance ?? "-",
        filterFn: "contains",
      },{
        header: "Alc balance",
        accessorFn: (row) => row.cmsAggregatorRedisInfo?.alc_balance ?? "-",
        filterFn: "contains",
      },{
        header: "Alc issued",
        accessorFn: (row) => row.cmsAggregatorRedisInfo?.alc_issued ?? "-",
        filterFn: "contains",
      },{
        header: "Club level",
        accessorFn: (row) => row.cmsAggregatorRedisInfo?.club_level ?? "-",
        filterFn: "contains",
      },
      {
        header: "Curr. mem-s",
        accessorFn: (row) => row.cmsAggregatorRedisInfo?.cur_members ?? "-",
        filterFn: "contains",
      },
      {
        header: "Max mem-s",
        accessorFn: (row) => row.cmsAggregatorRedisInfo?.max_members ?? "-",
        filterFn: "contains",
      },
      {
        header: "Updated at",
        accessorFn: (row) => row.cmsAggregatorRedisInfo?.updated_at ?? "-",
        Cell: ({row}) => {
          return moment
            .unix(Number(row.original.cmsAggregatorRedisInfo?.updated_at) / 1000)
            .utc(false)
            .format(DATE_FORMAT_WITH_UTC_TAG)
        },
        filterFn: "contains",
      },
      {
        header: "Nlh export timestamp",
        accessorFn: (row) => row.data?.nlh_export_timestamp ?? "-",
        Cell: ({row}) => {
          return moment
            .unix(Number(row.original.data?.nlh_export_timestamp) / 1000)
            .utc(false)
            .format(DATE_FORMAT_WITH_UTC_TAG)
        },
        filterFn: "contains",
      },
      {
        header: "Plo export timestamp",
        accessorFn: (row) => row.data?.plo_export_timestamp ?? "-",
        Cell: ({row}) => {
          return moment
            .unix(Number(row.original.data?.plo_export_timestamp) / 1000)
            .utc(false)
            .format(DATE_FORMAT_WITH_UTC_TAG)
        },
        filterFn: "contains",
      },
      {
        header: "Nlhs export timestamp",
        accessorFn: (row) => row.data?.nlhs_export_timestamp ?? "-",
        Cell: ({row}) => {
          return moment
            .unix(Number(row.original.data?.nlhs_export_timestamp) / 1000)
            .utc(false)
            .format(DATE_FORMAT_WITH_UTC_TAG)
        },
        filterFn: "contains",
      },
      {
        header: "Ofc export timestamp",
        accessorFn: (row) => row.data?.ofc_export_timestamp ?? "-",
        Cell: ({row}) => {
          return moment
            .unix(Number(row.original.data?.ofc_export_timestamp) / 1000)
            .utc(true)
            .format(DATE_FORMAT_WITH_UTC_TAG)
        },
        filterFn: "contains",
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    data: roomConfigsSortedData ?? [],
    columns: columns,
    getRowId: (originalRow) => originalRow.id,
    selectAllMode: "all",
    initialState: {
      pagination: { pageSize: 100, pageIndex: 0 },
      density: "compact",
    },
    state: {
      isLoading: configsIsLoading,
      columnFilters,
      globalFilter,
      grouping,
      columnVisibility: allTablesUserSetting?.cmsAggregator?.columnVisibility ?? {},
      columnOrder: allTablesUserSetting?.cmsAggregator?.columnOrder ?? [],
    },
    enableRowActions: true,
    enableSelectAll: true,
    enableRowSelection: true,
    enableGrouping: true,
    enableColumnDragging: enableColumnDragging,
    enableColumnOrdering: true,
    enableColumnFilterModes: true,
    enablePagination: true,
    enableSorting: enableColumnSorting,
    enableStickyHeader: true,
    autoResetPageIndex: false,
    muiTablePaperProps: {
      sx: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
      },
    },
    muiTableContainerProps: {
      sx: {
        flexGrow: 10,
        overflowY: "scroll",
        height: "600px",
      },
    },

    muiTableHeadCellProps: {
      sx: {
        verticalAlign: "middle",
        '& *': {
          whiteSpace: "normal",
          lineHeight: 1.35
        },
      },
    },

    defaultColumn: {
      enableResizing: false,
      grow: false,
      maxSize: 30,
      minSize: 10,
    },


    onColumnFiltersChange: setColumnFilters,
    onGroupingChange: setGrouping,
    onGlobalFilterChange: setGlobalFilter,
    onColumnOrderChange: (newColumnOrder) => {
      setAllTablesUserSetting({
        ...allTablesUserSetting,
        cmsAggregator: {...allTablesUserSetting.cmsAggregator, columnOrder: (newColumnOrder as string[])},
      })

    },
    onColumnVisibilityChange: (updaterFn: Updater<VisibilityState> ) => {
      setAllTablesUserSetting((prev: any) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newVisibility = updaterFn(prev.cmsAggregator?.columnVisibility);
        return {
          ...prev,
          cmsAggregator: {...prev.cmsAggregator, columnVisibility: newVisibility},
        };
      })
    },

    renderTopToolbarCustomActions: ({ table }) => {
      const selectedCount = table.getSelectedRowModel().rows.length;

      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          {cfgPerm.write && (
            <Button onClick={() => handleClickAddConfig()}>
              <PlusOutlined /> New
            </Button>
          )}


          {selectedCount > 0 && (
            <div style={{ marginLeft: 30 }}>
              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="success"
                  onClick={() => handleChangeEnableBulk(table, true)}
                >
                  <Power /> Enable
                </IconButton>
              )}
              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="error"
                  onClick={() => handleChangeEnableBulk(table, false)}
                >
                  <PowerOff /> Disable
                </IconButton>
              )}

              {cfgPerm.delete && (
                <Popconfirm
                  title="Delete items"
                  description="Are you sure to delete these items? (Changes cannot be undone)"
                  onConfirm={() => handleDeleteConfigs(table)}
                >
                  <IconButton
                    size="small"
                    style={{ fontSize: "15px" }}
                    color="error"
                  >
                    <DeleteOutlined /> Delete
                  </IconButton>
                </Popconfirm>
              )}

              {isOwner && (
                <ApplyAuthGroupsTableControl
                  domain={room}
                  cfgName={ROOM_CONFIGS_NAMES.CMS_AGGREGATOR}
                  table={table}
                  filters={assembleNamesFilter(table)}
                  totalElementsCount={selectedCount}
                  onSuccess={() => void refetch()}
                />
              )}
            </div>
          )}
        </div>
      );
    },
    renderRowActions: ({ row }) => (
      <Box>
        <IconButton onClick={() => setEditingConfig({ ...row.original, auth_groups: row.original.auth_groups ?? new Set() })}>
          <Edit />
        </IconButton>

        {cfgPerm.write && (
          <IconButton
            onClick={() => {
              setAddingConfig({ ...row.original, name: "", auth_groups: new Set() });
            }}
          >
            <ContentCopy />
          </IconButton>
        )}
      </Box>
    ),
    renderBottomToolbarCustomActions: ({ table }) => (
      <>
        <FormControlLabel
          value={enableColumnDragging}
          control={<Switch   size="small" onClick={() => setEnableColumnDragging(!enableColumnDragging)} />}
          label="Columns dragging"
          labelPlacement="start"
        />
        <FormControlLabel
          value={enableColumnSorting}
          control={<Switch size="small" onClick={() => setEnableColumnSorting(!enableColumnSorting)} />}
          label="Columns sorting"
          labelPlacement="start"
        />
      </>
    ),
  });


  useEffect(() => {
    table.resetRowSelection();
  }, [columnFilters, grouping, globalFilter, table]);

  return (
    <>
      <MaterialReactTable table={table} />

      {addConfig && (
        <>
          {jsonFormSchema ? (
            <RegularConfigCreateModal
              title="Create config"
              open={!!(addConfig && jsonFormSchema)}
              uiSchema={jsonFormSchema}
              defaultValues={addingConfig}
              onSave={(config) => handleClickSaveNewConfig(config)}
              onCancel={() => closeAddConfig()}
            />
          ) : (
            <CommonConfigWriteModal
              mode="add"
              isOpen={addConfig}
              defaultValues={addingConfig}
              onCancel={() => closeAddConfig()}
              onSave={(config) => handleClickSaveNewConfig(config)}
              disabled={!cfgPerm.write}
            />
          )}
        </>
      )}

      {editingConfig && (
        <>
          {jsonFormSchema ? (
            <RegularConfigEditModal
              title="Edit config"
              open={!!(editingConfig && jsonFormSchema)}
              uiSchema={jsonFormSchema}
              config={editingConfig}
              onSave={(config) => handleClickUpdateConfig(config.name, config)}
              onCancel={() => setEditingConfig(undefined)}
              disabled={!cfgPerm.write}
            />
          ) : (
            <CommonConfigWriteModal
              mode="edit"
              isOpen={!!editingConfig}
              defaultValues={editingConfig}
              onCancel={() => setAddingConfig(undefined)}
              onSave={(config) => handleClickUpdateConfig(config.name, config)}
              disabled={!cfgPerm.write}
            />
          )}
        </>
      )}
      {bulkOperationResult  && (
        <BulkOperationReportModal
          resultList={bulkOperationResult}
          title="Operation result"
          showProblematic
          showSuccess
          onCancel={() => setBulkOperationResult(undefined)}
          open={!!bulkOperationResult}
        />
      )}
    </>
  );
};

export default CmsAggregatorConfigTable;
