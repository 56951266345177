import { Col, Divider, Form, message, Modal, Row, Select, Typography } from "antd";
import React, { useMemo, useState } from "react";
import { UiSchema } from "../JsonForm/types/wrapperTypes";
import JsonForm from "../JsonForm/JsonForm";
import { SysCfgRegularDto, SysCfgUpdateRequestDto } from "@/shared/api";
import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import {useAtom} from "jotai/index";
import {meStore} from "@/store/store";
import {isOwner} from "@/lib/auth-predicates";
import {red} from "@ant-design/colors";

const RegularConfigEditModal = (
  {
    open,
    uiSchema,
    config,
    title,
    onSave,
    onCancel,
    disabled
  } : {
    open: boolean;
    uiSchema: UiSchema;
    config: SysCfgRegularDto;
    title?: string;
    onSave?: (data: SysCfgUpdateRequestDto) => void;
    onCancel?: () => void;
    disabled?: boolean;
  }
) => {
  const [me] = useAtom(meStore);

  const {
    data: authGroupsRs
  } = useAuthGroupsQuery({
    enabled: open
  })

  const authGroupsOptions = useMemo(() => {
    return authGroupsRs?.data.map(i => ( {label: i.name, value: i.name } ))
  }, [authGroupsRs?.data]);

  const [authGroups, setAuthGroups] = useState<Array<string>>(() => {
    if(authGroupsRs?.data.length === 1 && !isOwner(me)) {
      return authGroupsRs?.data.map(it => it.name)
    }
    return config?.auth_groups ? Array.from(config.auth_groups) : []
  });
  const [configName] = useState<string>(config.name);
  const [dataForm] = Form.useForm<object>();

  const validate = async () => {
    const result = await Promise.all([validateRootFields(), dataForm.validateFields()]);
    return {
      name: configName,
      auth_groups: authGroups ? new Set(authGroups) : new Set<string>(),
      data: result[1]
    };
  }

  const validateRootFields = async () => {
    if(!isOwner(me) && authGroups.length < 1) {
      return Promise.reject("Auth groups field is required")
    }
    return Promise.resolve();
  }

  function handleSubmitClick() {
    validate()
      .then(value => onSave && onSave(value))
      .catch(error => {
        if(typeof error === "string")
          void message.error(error).then();
        else
          void message.error("'Name(s)' is required")
      });
  }

  if(!open) {
    return <></>
  }

  return (
    <Modal
      width="80%"
      open
      okText="Submit"
      maskClosable={false}
      title={title && <Typography.Title level={3}>{title}</Typography.Title>}
      onCancel={onCancel}
      onOk={() => handleSubmitClick()}
      okButtonProps={{
        disabled: disabled,
      }}
    >
      {title && (<Divider style={{marginTop: 10, marginBottom: 30}}/>)}

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Typography.Text style={{fontSize: "16px", marginBottom: "10px", display: "block"}}>
            {!isOwner(me) && (
              <>
                <span style={{color: red.primary}}>*</span>&nbsp;
              </>
            )}
            Auth groups:
          </Typography.Text>
          <Select
            disabled={disabled || (authGroupsRs?.data.length === 1 && !isOwner(me))}
            style={{width: "100%"}}
            mode="multiple"
            options={authGroupsOptions}
            value={authGroups}
            onChange={e => setAuthGroups(e)}
            placeholder="Auth groups"
          />
        </Col>
        <Col span={24}>
          <JsonForm
            ui={uiSchema}
            form={dataForm}
            initData={config.data}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Modal>
  );
}

export default RegularConfigEditModal;