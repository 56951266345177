import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { ContentCopy, Edit, Power, PowerOff } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { Button, Checkbox, Popconfirm } from "antd";
import { MaterialReactTable, MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import RegularConfigCreateModal from "@/components/config-modals/regular-config-create-modal";
import RegularConfigEditModal from "@/components/config-modals/regular-config-edit-modal";
import { ROOM_CONFIGS_NAMES } from "@/lib/constants";
import { AnyObj } from "@/types/commonTypes";
import { Updater, VisibilityState } from "@tanstack/table-core";
import { GetAllCfgsDomainEnum, ResultDtoLong, SysCfgCreateRequestDto, SysCfgRegularDto } from "@/shared/api";
import { useSysCfgQuery } from "@/api/hooks/sys-cfg-hooks";
import {
  useConfigChangeEnabled,
  useDeleteConfigs,
  useGetJsonForm,
  useSaveConfig,
  useUpdateConfig,
} from "@/module/room-configs-module/hooks/room-configs-hooks";
import CommonConfigWriteModal from "@/components/config-modals/common-config-write-modal";
import BulkOperationReportModal from "@/components/bulk-operation-report-modal";
import { useCfgPerm, useIsOwnerOrSuperAdmin } from "@/auth/hooks";
import ApplyAuthGroupsTableControl from "@/components/apply-auth-groups-table-control";
import { assembleNamesFilter } from "@/lib/cfg-utils";

const PlayerGroupConfigTable = ({ room } : { room: string }) => {
  const {data: configsRs, isLoading: configsIsLoading, refetch} = useSysCfgQuery({
    domain: room as GetAllCfgsDomainEnum,
    config_name: ROOM_CONFIGS_NAMES.PLAYER_GROUP
  });

  const cfgPerm = useCfgPerm({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.PLAYER_GROUP,
  });

  const roomConfigsSortedData = React.useMemo(() => {
    return (
      configsRs?.data.content?.sort(
        (a, b) => new Date(b.created_at).getDate() - new Date(a.created_at).getDate()
      ) ?? []
    );
  }, [configsRs]);

  const isOwner = useIsOwnerOrSuperAdmin();

  const [bulkOperationResult, setBulkOperationResult] = useState<ResultDtoLong[] | undefined>(undefined);


  const { data: jsonFormSchema } = useGetJsonForm({
    roomName: room,
    configName: ROOM_CONFIGS_NAMES.PLAYER_GROUP
  });

  const handleClickSaveNewConfig = useSaveConfig({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.PLAYER_GROUP,
    onSuccessCallback: () => {
      void refetch()
      closeAddConfig()
    }
  });

  const handleClickUpdateConfig = useUpdateConfig({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.PLAYER_GROUP,
    onSuccessCallback: () => {
      void refetch()
      setEditingConfig(undefined)
    }
  });

  const handleChangeEnableBulk = useConfigChangeEnabled({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.PLAYER_GROUP,
    onSuccessCallback: (table) => {
      void refetch()
      table.resetRowSelection()
    },
  });

  const handleDeleteConfigs = useDeleteConfigs({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.PLAYER_GROUP,
    onSuccessCallback: (table, response) => {
      setBulkOperationResult([response.data]);
      void refetch()
      table.resetRowSelection()
    },
  });

  //This logic for reset row selection while using filtering/grouping
  const [columnFilters, setColumnFilters] = useState<any>([]);
  const [grouping, setGrouping] = useState<any>([]);
  const [globalFilter, setGlobalFilter] = useState<any>(undefined);
  const [editingConfig, setEditingConfig] = useState<SysCfgRegularDto | undefined>(undefined);
  const [addConfig, setAddConfig] = useState<boolean>(false);
  const [addingConfig, setAddingConfig] = useState<SysCfgCreateRequestDto | undefined>(undefined);

  //Storing table setting into localStorage
  const loadTablesSettingsFromLocalStorage = () => {
    const item = localStorage.getItem("tablesSettings");
    return item ? JSON.parse(item) : {};
  }

  const [allTablesUserSetting, setAllTablesUserSetting] = useState<any>(() => loadTablesSettingsFromLocalStorage());

  useEffect(() => {
    localStorage.setItem("tablesSettings", JSON.stringify(allTablesUserSetting))
  }, [allTablesUserSetting]);

  function closeAddConfig() {
    setAddingConfig(undefined);
    setAddConfig(false)
  }

  function handleClickAddConfig() {
    setAddConfig(true)
  }

  const columns = useMemo<MRT_ColumnDef<SysCfgRegularDto>[]>(
    () => [
      {
        header: "Enabled",
        filterFn: "contains",
        enableEditing: false,
        accessorFn: (row) => row.enabled ?? "-",
        Cell: ({ row }) => <Checkbox checked={row.original.enabled} disabled />
      },
      {
        header: "Name",
        accessorKey: "name",
        filterFn: "contains",
      },
      {
        header: "VPIP",
        filterFn: "contains",
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          return (
            <>
              <span>{`min: ${data?.vpip?.min ?? "-"}`}</span>
              <br />
              <span>{`max: ${data?.vpip?.max ?? "-"}`}</span>
            </>
          );
        },
      },
      {
        header: "PFR",
        filterFn: "contains",
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          return (
            <>
              <span>{`min: ${data?.pfr?.min ?? "-"}`}</span>
              <br />
              <span>{`max: ${data?.pfr?.max ?? "-"}`}</span>
            </>
          );
        },
      },
      {
        header: "Hands",
        filterFn: "contains",
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          return (
            <>
              <span>{`min: ${data?.hands?.min ?? "-"}`}</span>
              <br />
              <span>{`max: ${data?.hands?.max ?? "-"}`}</span>
            </>
          );
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    data: roomConfigsSortedData ?? [],
    columns: columns,
    getRowId: (originalRow) => originalRow.id,
    selectAllMode: "all",
    initialState: {
      pagination: { pageSize: 20, pageIndex: 0 },
      density: "compact",
    },
    enableSelectAll: true,
    enableRowSelection: true,
    enableGrouping: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableColumnFilterModes: true,
    enableRowActions: true,
    enablePagination: true,
    enableStickyHeader: true,
    enableSorting: true,
    autoResetPageIndex: false,
    state: {
      columnFilters,
      globalFilter,
      grouping,
      columnVisibility: allTablesUserSetting?.playerGroup?.columnVisibility ?? {},
      columnOrder: allTablesUserSetting?.playerGroup?.columnOrder ?? [],
      isLoading: configsIsLoading,
    },
    muiTablePaperProps: {
      sx: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
      },
    },
    muiTableContainerProps: {
      sx: {
        flexGrow: 10,
        overflowY: "scroll",
        height: "500px",
      },
    },

    onColumnFiltersChange: setColumnFilters,
    onGroupingChange: setGrouping,
    onGlobalFilterChange: setGlobalFilter,
    onColumnOrderChange: (newColumnOrder) => {
      setAllTablesUserSetting({
        ...allTablesUserSetting,
        playerGroup: {...allTablesUserSetting.playerGroup, columnOrder: (newColumnOrder as string[])},
      })

    },
    onColumnVisibilityChange: (updaterFn: Updater<VisibilityState> ) => {
      setAllTablesUserSetting((prev: any) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newVisibility = updaterFn(prev.playerGroup?.columnVisibility);
        return {
          ...prev,
          playerGroup: {...prev.playerGroup, columnVisibility: newVisibility},
        };
      })
    },


    renderTopToolbarCustomActions: () => {
      const selectedCount = table.getSelectedRowModel().rows.length;
      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          {cfgPerm.write && (
            <Button onClick={() => handleClickAddConfig()}>
              <PlusOutlined /> New
            </Button>
          )}

          {selectedCount > 0 && (
            <div style={{ marginLeft: 30 }}>

              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="success"
                  onClick={() => handleChangeEnableBulk(table, true)}
                >
                  <Power /> Enable
                </IconButton>
              )}

              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="error"
                  onClick={() => handleChangeEnableBulk(table, false)}
                >
                  <PowerOff /> Disable
                </IconButton>
              )}

              {cfgPerm.delete && (
                <Popconfirm
                  title="Delete items"
                  description="Are you sure to delete these items? (Changes cannot be undone)"
                  onConfirm={() => handleDeleteConfigs(table)}
                >
                  <IconButton
                    size="small"
                    style={{ fontSize: "15px" }}
                    color="error"
                  >
                    <DeleteOutlined /> Delete
                  </IconButton>
                </Popconfirm>
              )}

              {isOwner && (
                <ApplyAuthGroupsTableControl
                  domain={room}
                  cfgName={ROOM_CONFIGS_NAMES.PLAYER_GROUP}
                  table={table}
                  filters={assembleNamesFilter(table)}
                  totalElementsCount={selectedCount}
                  onSuccess={() => void refetch()}
                />
              )}
            </div>
          )}
        </div>
      );
    },
    renderRowActions: ({ row }) => (
      <Box>
        <IconButton onClick={() => setEditingConfig({ ...row.original, auth_groups: row.original.auth_groups ?? new Set() })}>
          <Edit />
        </IconButton>
        {cfgPerm.write && (
          <IconButton
            onClick={() => {
              setAddingConfig({ ...row.original, name: "", auth_groups: new Set() });
            }}
          >
            <ContentCopy />
          </IconButton>
        )}
      </Box>
    ),
  });


  useEffect(() => {
    table.resetRowSelection();
  }, [columnFilters, grouping, globalFilter, table]);

  return (
    <>
      <MaterialReactTable table={table} />
      {addConfig && (
        <>
          {jsonFormSchema ? (
            <RegularConfigCreateModal
              title="Create config"
              open={addConfig}
              uiSchema={jsonFormSchema}
              defaultValues={addingConfig}
              onSave={(config) => handleClickSaveNewConfig(config)}
              onCancel={() => closeAddConfig()}
            />
          ) : (
            <CommonConfigWriteModal
              mode="add"
              isOpen={addConfig}
              defaultValues={addingConfig}
              onCancel={() => closeAddConfig()}
              onSave={(config) => handleClickSaveNewConfig(config)}
              disabled={!cfgPerm.write}
            />
          )}
        </>
      )}
      
      {editingConfig && (
        <>
          {jsonFormSchema ? (
            <RegularConfigEditModal
              title="Edit config"
              open={!!(editingConfig && jsonFormSchema)}
              uiSchema={jsonFormSchema}
              config={editingConfig}
              onSave={(config) => handleClickUpdateConfig(config.name, config)}
              onCancel={() => setEditingConfig(undefined)}
              disabled={!cfgPerm.write}
            />
          ) : (
            <CommonConfigWriteModal
              mode="edit"
              isOpen={!!editingConfig}
              defaultValues={editingConfig}
              onCancel={() => closeAddConfig()}
              onSave={(config) => handleClickUpdateConfig(config.name, config)}
              disabled={!cfgPerm.write}
            />
          )}
        </>
      )}

      {bulkOperationResult  && (
        <BulkOperationReportModal
          resultList={bulkOperationResult}
          title="Operation result"
          showProblematic
          showSuccess
          onCancel={() => setBulkOperationResult(undefined)}
          open={!!bulkOperationResult}
        />
      )}
    </>
  );
};

export default PlayerGroupConfigTable;
