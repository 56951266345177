import React, {useMemo, useState} from "react";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  IconButton,
  List, Menu,
  MenuItem,
  Typography,
  useTheme
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import {SysDomainRegularDto} from "@/shared/api";
import SidebarMenuItem, {SidebarMenuItemType} from "@/components/sidebar/sidebar-menu-item-type";
import {ROOM_CONFIGS_NAMES, ROOM_NAMES} from "@/lib/constants";
import {DataObject, Groups, Home, Logout, ManageAccounts} from "@mui/icons-material";
import {isOwner} from "@/lib/auth-predicates";
import {useAtom} from "jotai";
import {RoomSelectTextField} from "@/components/sidebar/styled";
import {useNavigate} from "@tanstack/react-router";
import {appCurrentRoom, meStore} from "@/store/store";
import {useLogout} from "@/auth/hooks";


const Sidebar = ({
  domains,
  dataIsLoading = false
} : {
  domains: SysDomainRegularDto[];
  dataIsLoading?: boolean;
}) => {
  const [me] = useAtom(meStore);

  const navigate = useNavigate();

  const [selectedRoom, setSelectedRoom] = useAtom(appCurrentRoom);

  const rooms = useMemo(() => domains.filter(it => it.is_room) ?? [], [domains]);

  const serviceDomain = useMemo(() => {
    if (!domains.find(it => it.name === ROOM_NAMES.SERVICES)?.config_names.find(it => it === ROOM_CONFIGS_NAMES.STATSERVICE)) { domains.find(it => it.name === ROOM_NAMES.SERVICES)?.config_names.push(ROOM_CONFIGS_NAMES.STATSERVICE) }
    console.log(domains.find(it => it.name === ROOM_NAMES.SERVICES))
    return domains.find(it => it.name === ROOM_NAMES.SERVICES)
  }, [domains])

  const selectedRoomConfigs = useMemo(() => {
    return selectedRoom?.config_names.filter(it => it !== ROOM_CONFIGS_NAMES.ACCOUNT) ?? []
  }, [selectedRoom]);
  const accountIsPresent = useMemo(() =>
    selectedRoom?.config_names.some(it => it === ROOM_CONFIGS_NAMES.ACCOUNT) ?? false
  , [selectedRoom]);

  const theme = useTheme();

  const logout = useLogout();

  const menuItems = useMemo<SidebarMenuItemType[]>(() => {
    return [
      {
        name: "Home",
        icon: <Home/>,
        link: {to: "/"},
      },
      {
        name: 'Accounts',
        icon: <AccountCircleIcon />,
        link: {
          to: "/accounts",
          isDisabled: true
        },
        onClick: () => navigate({
          to: "/accounts/$room/$type",
          params: {room: selectedRoom?.name, type: "regular"}
        }),
        isHiddenAccessor: () => !accountIsPresent
      },
      {
        name: 'Room Settings',
        icon: <DataObject />,
        link: {to: "/room-config"},
        subItemsAccessor: () => selectedRoomConfigs.map(cfg => ({
          name: cfg,
          link: {
            to: "/room-config/$roomName/$configName",
            params: {roomName: selectedRoom?.name, configName: cfg}
          },
        })),
        isHiddenAccessor: () => selectedRoomConfigs.length <= 0,
      },
      {
        name: 'Services',
        icon: <SettingsIcon />,
        link: {to: "/service-config"},
        subItemsAccessor: () => serviceDomain?.config_names.map(cfg => ({
          name: cfg,
          link: {
            to: "/service-config/$configName",
            params: {
              configName: cfg
            }
          }
        })) ?? [],
        isHiddenAccessor: () => !serviceDomain || serviceDomain.config_names.length <= 0
      },
      {
        name: 'User Management',
        link: {
          to: "/users"
        },
        icon: <ManageAccounts />,
        isHiddenAccessor: () => !isOwner(me)
      },
      {
        name: 'Auth Groups',
        link: {
          to: "/auth-groups"
        },
        icon: <Groups />,
        isHiddenAccessor: () => !isOwner(me)
      },
      {
        name: 'Dashboard',
        link: {
          to: "/dashboard"
        },
        icon: <DashboardIcon />,
        isHiddenAccessor: () => !isOwner(me)
      },
    ];
  }, [accountIsPresent, me, navigate, selectedRoom, selectedRoomConfigs, serviceDomain]);

  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleRoomChange = (room: SysDomainRegularDto | null) => {
    if(room != null) {
      setSelectedRoom(room);
    }
  };

  const handleDropdownOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  }

  const handleCollapseToggle = () => {
    setSidebarCollapsed((prev) => !prev);
  };

  return (
    <div style={{
      width: sidebarCollapsed ? 80 : 250,
      transition: 'width 0.3s ease',
    }}>
      <Box
        sx={{
          width: sidebarCollapsed ? 80 : 250,
          position: "fixed",
          height: '100vh',
          backgroundColor: theme.palette.sidebar.main,
          color: theme.palette.sidebar.contrastText,
          display: 'flex',
          flexDirection: 'column',
          transition: 'width 0.3s ease',
          overflowX: 'hidden',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', p: 2, justifyContent: 'center', flexDirection: "column" }}>
          {sidebarCollapsed ? (
            <>
              <Typography
                variant="h5"
                sx={{ fontWeight: 'bold', textAlign: 'center' }}
                onMouseOver={(e) => handleDropdownOpen(e)}
              >
                {
                  selectedRoom?.name.toUpperCase().split("_").map(it => it[0])
                  ?? "R"
                }
              </Typography>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleDropdownClose}
                MenuListProps={{ onMouseLeave: handleDropdownClose }}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              >
                {rooms.map(it => (
                  <MenuItem
                    key={it.name}
                    selected={it.name === selectedRoom?.name}
                    onClick={() => handleRoomChange(it)}
                  >{it.name.toLowerCase().split("_").join(" ")}</MenuItem>
                ))}
              </Menu>
            </>
          ) : (
            <Autocomplete
              loading={dataIsLoading}
              fullWidth
              size="small"
              options={rooms}
              value={selectedRoom === undefined ? null : selectedRoom}
              onChange={(_e, v) => handleRoomChange(v)}
              getOptionLabel={option => option.name.toLowerCase().split("_").join(" ")}
              getOptionKey={option => option.name}
              renderInput={(params) => (
                <RoomSelectTextField
                  {...params}
                  placeholder="Select a Room"
                />
              )}
            />
          )}

        </Box>

        <Divider sx={{ borderColor: theme.palette.sidebar.contrastText }} />

        <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
          <List component="nav" aria-labelledby="nested-list-subheader">
            {menuItems.map((item) => (
              <SidebarMenuItem
                key={item.name}
                item={item}
                sidebarCollapsed={sidebarCollapsed}
              />
            ))}
          </List>
        </Box>



        <Divider sx={{ borderColor: theme.palette.sidebar.contrastText }} />

        <Box
          sx={{
            backgroundColor: theme.palette.sidebar.light,
            textAlign: "center",
            p: 2
          }}
        >
          <div
            onClick={logout}
          >
            {sidebarCollapsed ? (
              <IconButton sx={{ color: '#fff' }}><Logout/></IconButton>
            ) : (
              <Button variant="contained" endIcon={<Logout/>} sx={{ color: '#fff' }}>Log Out</Button>
            )}
          </div>
        </Box>

        <Box
          sx={{
            backgroundColor: theme.palette.sidebar.light,
            width: '100%',
            height: '50px',
            textAlign: 'center',
            cursor: 'pointer',
          }}
          onClick={handleCollapseToggle}
        >
          <IconButton sx={{ color: '#ffffff' }}>
            {sidebarCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </Box>
      </Box>

    </div>
  );
};

export default Sidebar;
