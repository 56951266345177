import { Divider } from "antd";
import MainTable from "@/module/service-configs-module/main-table";
import { Grid, Typography } from "@mui/material";


const ServiceConfigPage = ({config} : {config: string}) => {
  return (<>
    <Grid container spacing={2} height="100%" direction="column" flexWrap="nowrap">
      <Grid item>
        <Typography variant="h3">{
          config.split("_")
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
        }</Typography>
      </Grid>
      <Grid item>
        <Divider style={{marginTop: 0}}/>
      </Grid>
      <Grid item xs={10}>
        <MainTable cfg={config}/>
      </Grid>
    </Grid>
  </>)
}


export default ServiceConfigPage;