import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-lua";
import "ace-builds/src-noconflict/theme-dracula";
import "ace-builds/webpack-resolver";
import React, { useEffect, useMemo } from "react";
import ClearIcon from "@mui/icons-material/Clear";


import { Autocomplete, Button, Dialog, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import { useFieldArray } from "react-hook-form-mui";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  beforeSendNormalizers,
  formInitState,
  ruleRange,
} from "@/components/config-modals/compensator-edit-modal/constants";
import { EditCompensator, EditedCompensator } from "@/components/config-modals/compensator-edit-modal/types";
import CloseIcon from "@mui/icons-material/Close";
import { useSysCfgRawQuery } from "@/api/hooks/sys-cfg-hooks";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import { GetAllCfgsRowDomainEnum } from "@/shared/api";
import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import { useAtom } from "jotai/index";
import { meStore } from "@/store/store";
import { isOwner } from "@/lib/auth-predicates";
import { useSysDomainQuery } from "@/api/hooks/sys-domain-hooks";
import CustomSelectOption from "@/components/custom-select-option";


export default function CompensatorCreateModal ({
  open,
  mode,
  initialData,
  onSave,
  onCancel,
  confirmLoading = false,
  disabled = false
} : {
  open: boolean,
  mode?: string,
  initialData?: EditCompensator;
  onSave?: (data: EditedCompensator) => void;
  onCancel?: () => void,
  confirmLoading?: boolean,
  disabled?: boolean
}) {
  const [me] = useAtom(meStore);

  const {
    data: authGroupsRs,
    isLoading: authGroupsIsLoading
  } = useAuthGroupsQuery()

  const defaultValues = useMemo<EditedCompensator>(() => {
    let authGroups = initialData?.auth_groups ? Array.from(initialData.auth_groups) : []

    if(authGroupsRs?.data.length === 1 && !isOwner(me)) {
      authGroups = authGroupsRs.data.map(it => it.name)
    }

    return initialData ? {
      ...initialData,
      enabled: true,
      authGroups: authGroups
    } : {
      ...formInitState,
      authGroups: authGroups
    }
  }, [initialData, me, authGroupsRs]);

  const { register, control, reset, handleSubmit, watch, formState: { errors } } =
    useForm<EditedCompensator>({ mode: "onTouched", defaultValues: defaultValues })

  useEffect(() => {
    reset({ ...defaultValues })
  }, [defaultValues, open, reset])

  const chosenRoom = watch("data.room");

  const { data: roomsRs, isLoading: roomsIsLoading } = useSysDomainQuery(true);

  const { data: aiProfilesRs, isLoading: aiProfilesIsLoading } = useSysCfgRawQuery({
    domain: ROOM_NAMES.SERVICES as GetAllCfgsRowDomainEnum,
    config_name: ROOM_CONFIGS_NAMES.AI_PROFILE
  }, {
    enabled: open
  });

  const { data: gameGroupsRs } = useSysCfgRawQuery({
    domain: chosenRoom as GetAllCfgsRowDomainEnum,
    config_name: ROOM_CONFIGS_NAMES.GAME_GROUP
  }, {
    enabled: open && !!chosenRoom
  });

  const aiProfilesShorten = useMemo(() => aiProfilesRs?.data ?? [], [aiProfilesRs]);
  const gameGroupsShorten = useMemo(() => gameGroupsRs?.data ?? [], [gameGroupsRs]);

  const onSubmit: SubmitHandler<EditedCompensator> = (data) => {
    beforeSendNormalizers.forEach(normalizer => data = normalizer(data));

    data.name = "" //TODO: move to Back-end
      .concat(data.data?.room ?? "empty")
      .concat("_")
      .concat(data.data?.game_group ?? "empty")
      .concat("_")
      .concat(data.data?.game_type ?? "empty")
      .concat("_")
      .concat(data.data?.limit?.toString() ?? "empty")

    if(!onSave) return;
    onSave(data);

    onCancel && onCancel()
  }

  const send = handleSubmit(onSubmit);

  const { fields, append, remove } = useFieldArray({
    name: "data.comp_min",
    rules: { minLength: 1, required: "At least 1 element required"},
    control
  });

  const { fields: compMaxFields, append: compMaxAppend,  remove: compMaxRemove } = useFieldArray({
    name: "data.comp_max",
    rules: { minLength: 1, required: "At least 1 element required"},
    control
  });

  return (
    <>
      <Dialog
        open={open}
        key="compensator-modal"
      >
        <DialogTitle>
          {mode === "add" ? "Add compensator" : "Edit compensator" }
          <IconButton
            aria-label="close"
            onClick={() => onCancel && onCancel()}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div
             style={{
               display: "flex",
               flexDirection: "column",
               gap: "10px",
               width: "500px",
             }}
          >
            <input
              key="dialog-compensator-name"
              type="hidden"
              {...register("name")}
            />


            <Controller
              control={control}
              name="authGroups"
              rules={{
                required: !isOwner(me)
                  ? "field is required"
                  : undefined
              }}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  multiple
                  disabled={disabled || (authGroupsRs?.data.length === 1 && !isOwner(me))}
                  loading={authGroupsIsLoading}
                  value={field.value}
                  onChange={(_, data) => field.onChange(data)}
                  options={authGroupsRs?.data?.map(i => i.name) ?? []}
                  disableCloseOnSelect
                  renderOption={(props, option, { selected }) => (
                    <CustomSelectOption label={option} selected={selected} props={props}/>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={!isOwner(me) ? "*Auth Groups" : "Auth Groups"}
                      placeholder="Auth Groups"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />


            {mode === "add" && (
              <div style={{ display: "flex", alignContent: "center" }}>
                <input
                  key="dialog-compensator-checkbox"
                  type="checkbox"
                  {...register("enabled")}
                  disabled={disabled}
                />
                <p style={{ marginLeft: "3px" }}>Enabled</p>
              </div>
            )}

            <TextField
              key="dialog-compensator-comment"
              {...register("data.comment")}
              label="Comment"
              placeholder="comment"
              variant="standard"
              disabled={disabled}
              error={!!errors.data?.comment}
              helperText={!!errors.data?.comment && errors.data?.comment.message}
            />


            <p>Comp min</p>
            {fields.map((field, index) => (

              <div key={field.id} style={{ display: "flex" }}>

                <Controller
                  control={control}
                  name={`data.comp_min.${index}.from`}
                  rules={{ required: "field is required" }}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      {...field}
                      sx={{ width: "220px", mr: "10px" }}
                      loading={aiProfilesIsLoading}
                      value={field.value === "" ? null : field.value}
                      onChange={(_, data) => field.onChange(data)}
                      disabled={disabled}
                      options={aiProfilesShorten.map(it => it.name)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="*From"
                          placeholder="From"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={`data.comp_min.${index}.to`}
                  rules={{ required: "field is required" }}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      {...field}
                      sx={{ width: "220px", mr: "10px" }}
                      loading={aiProfilesIsLoading}
                      value={field.value === "" ? null : field.value}
                      disabled={disabled}
                      onChange={(_, data) => field.onChange(data)}
                      options={aiProfilesShorten.map(it => it.name)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="*To"
                          placeholder="To"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />

                <IconButton onClick={() => remove(index)} disabled={disabled}>
                  <ClearIcon />
                </IconButton>

              </div>

            ))}
            <Button
              type="button"
              disabled={disabled}
              onClick={() => append({ from: "", to: "" })}
            >
              Add
            </Button>
            {!!errors?.data?.comp_min?.root?.message && <div><span style={{ color: "red" }}>{errors?.data?.comp_min?.root?.message}</span></div>}


            <p>Comp max</p>
            {compMaxFields.map((field, index) => (
              <div key={field.id} style={{ display: "flex" }}>

                <Controller
                  control={control}
                  name={`data.comp_max.${index}.from`}
                  rules={{ required: "field is required" }}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      {...field}
                      sx={{ width: "220px", mr: "10px" }}
                      loading={aiProfilesIsLoading}
                      value={field.value === "" ? null : field.value}
                      disabled={disabled}
                      onChange={(_, data) => field.onChange(data)}
                      options={aiProfilesShorten.map(it => it.name)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="*From"
                          placeholder="From"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={`data.comp_max.${index}.to`}
                  rules={{ required: "field is required" }}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      {...field}
                      sx={{ width: "220px", mr: "10px" }}
                      loading={aiProfilesIsLoading}
                      value={field.value === "" ? null : field.value}
                      disabled={disabled}
                      onChange={(_, data) => field.onChange(data)}
                      options={aiProfilesShorten.map(it => it.name)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="*To"
                          placeholder="To"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />

                <IconButton onClick={() => compMaxRemove(index)} disabled={disabled}>
                  <ClearIcon />
                </IconButton>
              </div>
            ))}

            <Button
              type="button"
              disabled={disabled}
              onClick={() => compMaxAppend({ from: "", to: "" })}
            >
              Add
            </Button>
            {errors?.data?.comp_max?.root?.message && <div><span style={{ color: "red" }}>{errors?.data?.comp_max?.root?.message}</span></div>}

            <Controller
              control={control}
              name="data.room"
              rules={{ required: "field is required" }}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  loading={roomsIsLoading}
                  value={field.value === "" ? null : field.value}
                  disabled={disabled}
                  onChange={(_, data) => field.onChange(data)}
                  options={roomsRs?.data.map(it => it.name) ?? []}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="*Room"
                      placeholder="Room"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />

            <Controller
              control={control}
              name="data.rule_range"
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  loading={roomsIsLoading}
                  value={field.value === "" ? null : field.value}
                  disabled={disabled}
                  onChange={(_, data) => field.onChange(data)}
                  options={ruleRange.map(it => it.value) ?? []}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Period (Rule range)"
                      placeholder="Period (Rule range)"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />

            <TextField
              key="dialog-input-aid"
              {...register("data.aid", { required: "Aid is required" })}
              label="*Aid"
              placeholder="Aid"
              variant="standard"
              error={!!errors?.data?.aid?.message}
              helperText={errors?.data?.aid?.message}
              disabled={disabled}
            />

            <Controller
              control={control}
              name="data.game_group"
              rules={{ required: "field is required" }}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  loading={roomsIsLoading}
                  value={field.value === "" ? null : field.value}
                  disabled={disabled || !chosenRoom}
                  onChange={(_, data) => field.onChange(data)}
                  options={gameGroupsShorten.map(it => it.name) ?? []}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="*Game Group"
                      placeholder="Game Group"
                      variant="standard"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />

            <TextField
              key="dialog-input-limit"
              {...register("data.limit", {
                required: "Limit is required",
                valueAsNumber: true,
                max: { value: 50000000, message: "Number should be less than 50000000" },
                min: { value: 0, message: "Number should be higher than 0" },
              })}
              label="*Limit"
              placeholder="limit"
              variant="standard"
              error={!!errors.data?.limit?.message}
              helperText={errors?.data?.limit?.message}
              disabled={disabled}
            />

            <TextField
              key="dialog-input-game-type"
              {...register("data.game_type", { required: "Game type is required" })}
              label="*Game type"
              placeholder="Game type"
              variant="standard"
              error={!!errors?.data?.game_type?.message}
              helperText={errors?.data?.game_type?.message}
              disabled={disabled}
            />

            <TextField
              key="dialog-input-raa-min"
              {...register("data.RAA_min",{
                required: "RAA min is required" ,
                valueAsNumber: true,
                max: { value: 50000000, message: "Number should be less than 50000000" },
                min: { value: -50000000, message: "Number should be higher than -50000000" },
              })}
              label="*Result min"
              placeholder="Result min"
              variant="standard"
              error={!!errors?.data?.RAA_min?.message}
              helperText={errors?.data?.RAA_min?.message}
              disabled={disabled}
            />

            <TextField
              key="dialog-input-raa-max"
              {...register("data.RAA_max", {
                required: "RAA max field is required",
                valueAsNumber: true,
                max: { value: 50000000, message: "Number should be less than 50000000" },
                min: { value: -50000000, message: "Number should be higher than -50000000" },
              })}
              label="*Result max"
              placeholder="Result max"
              variant="standard"
              error={!!errors?.data?.RAA_max?.message}
              helperText={errors?.data?.RAA_max?.message}
              disabled={disabled}
            />

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="button" style={{marginRight: "5px"}} onClick={() => onCancel && onCancel()}>Cancel</Button>
              <Button variant="contained" onClick={send} disabled={disabled}>Submit</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};