import { KeyFilter, KeySort, StatserviceQueryDto, StatServiceResponseDto } from "@/shared/api";
import { keepPreviousData, useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useApi } from "@/shared/api/use-api";
import { MRT_PaginationState } from "material-react-table";
import { downloadFile } from "@/lib/common-utils";
import { RequiredError } from "@/shared/api/base";


export const useSysStatserviceQuery = (
  {
    period,
    filters = new Set<KeyFilter>(),
    sort = [],
    pagination,
  }: {
    period: number;
    filters?: Set<KeyFilter>;
    sort?: KeySort[];
    pagination?: MRT_PaginationState
  },
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<StatServiceResponseDto>>, "queryKey">,
) => {
  const { sysStatserviceControllerApi } = useApi();
  return useQuery({
    queryKey: [`/sys-data/statservice`, filters, sort, pagination],
    queryFn: ({ signal }) => {
      const query: StatserviceQueryDto = {
        period: period,
        filters: filters,
        page: pagination ? { page_number: pagination.pageIndex, page_size: pagination.pageSize } : undefined,
        sort: sort,
      };
      return sysStatserviceControllerApi.findStatserviceData(query, { signal });
    },
    placeholderData: keepPreviousData,
    ...queryOptions,
  });
};

export const useSysStatserviceExportCsvMutation = () => {
  const { sysStatserviceControllerApi } = useApi();
  type Options = {
    period: number;
    filters?: Set<KeyFilter>;
  };

  const mutationFn = async ({ period, filters } : Options) => {
    const response = await sysStatserviceControllerApi.exportStatserviceToCsv({period, filters});
    downloadFile(response)
    return response
  }

  return useMutation<AxiosResponse<string>, RequiredError, Options>({
    mutationFn: mutationFn
  })
}