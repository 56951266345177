import React, {useState} from "react";
import {Divider, Form, Input, message, Modal, Radio, Typography} from "antd";

type AddingMode = "one" | "list"

const NameConfigAddModal = ({
  open,
  title,
  confirmLoading = false,
  onCancel,
  onSave,
  disabled = false,
} : {
  open: boolean,
  title?: string,
  confirmLoading?: boolean,
  onCancel?: () => void;
  onSave?: (value: string[]) => void;
  disabled?: boolean;
}) => {
  const [addingMode, setAddingMode] = useState<AddingMode>("one");

  let [form] = Form.useForm();
  let formValues = Form.useWatch([], form);

  function handleChangeAddingMode(value: AddingMode) {
    setAddingMode(value);
  }

  function handleSaveClick() {
    form.validateFields({validateOnly: false})
      .then(() => {
        let values: string[] = formValues[addingMode].trim().split("\n");
        values.forEach(value =>
            validateNotEmpty(value)
        );
        if(onSave) onSave(values);
      })
      .catch(err => console.error(err));
  }

  function validateNotEmpty(value: string) {
    if(!value || !value.trim()) {
      message.error("'Name(s)' are required").then();
      return Promise.reject(new Error("'Name(s)' are required"));
    }

    return Promise.resolve();
  }

  return (<>
    <Modal open={open}
           okText="Add"
           onCancel={() => onCancel && onCancel()}
           maskClosable={false}
           width={600}
           confirmLoading={confirmLoading}
           onOk={() => handleSaveClick()}
           title={title && <Typography.Title level={3} style={{marginTop: 15}}>{title}</Typography.Title>}>

      {title && (<Divider style={{marginTop: 10, marginBottom: 30}}/>)}

      <Form size="large" className="a-common-form">
        <Form.Item label="Choose adding type">
          <Radio.Group onChange={e => handleChangeAddingMode(e.target.value)}
                       value={addingMode}>
            <Radio.Button value="one">One</Radio.Button>
            <Radio.Button value="list">List</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>

      <Divider/>

      <Form
        size="large"
        className="a-common-form"
        form={form}
        autoComplete="off"
        key={addingMode}
      >
        {addingMode === "one" && (
          <Form.Item name="one" label="Name" rules={[
            {required: true},
          ]}>
            <Input placeholder={"Type name"}/>
          </Form.Item>
        )}

        {addingMode === "list" && (
          <Form.Item name="list" label="Names" rules={[
            {
              required: true,
            }
          ]}>
            <Input.TextArea rows={30}
                            placeholder="Insert names one per line"/>
          </Form.Item>
        )}

      </Form>
    </Modal>
  </>)
}

export default NameConfigAddModal;