import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { ContentCopy, Edit, Power, PowerOff } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { Button, Checkbox, Popconfirm } from "antd";
import { MaterialReactTable, MRT_ColumnDef, MRT_TableInstance, useMaterialReactTable } from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";

import RegularConfigCreateModal from "@/components/config-modals/regular-config-create-modal";
import RegularConfigEditModal from "@/components/config-modals/regular-config-edit-modal";
import { Updater, VisibilityState } from "@tanstack/table-core";
import {
  useSysCfgChangeEnabledMutation,
  useSysCfgCreateMutation,
  useSysCfgDeleteMutation,
  useSysCfgQuery,
  useSysCfgUpdateMutation,
} from "@/api/hooks/sys-cfg-hooks";
import {
  ChangeCfgEnabledDomainEnum,
  CreateCfgDomainEnum,
  DeleteCfgDomainEnum,
  GetAllCfgsDomainEnum,
  PatchCfgDataDomainEnum, ResultDtoLong,
  SysCfgCreateRequestDto,
  SysCfgRegularDto,
  SysCfgUpdateRequestDto,
} from "@/shared/api";
import { enqueueSnackbar } from "notistack";
import { AnyObj } from "@/types/commonTypes";
import CommonConfigWriteModal from "@/components/config-modals/common-config-write-modal";
import { useGetJsonForm } from "@/module/room-configs-module/hooks/room-configs-hooks";
import BulkOperationReportModal from "@/components/bulk-operation-report-modal";
import { useCfgPerm, useIsOwnerOrSuperAdmin } from "@/auth/hooks";
import ApplyAuthGroupsTableControl from "@/components/apply-auth-groups-table-control";
import { assembleNamesFilter } from "@/lib/cfg-utils";

const AiProfileConfigTable = () => {
  const {data: configsRs, isLoading: configsIsLoading, refetch: refetchConfigs} = useSysCfgQuery({
    domain: ROOM_NAMES.SERVICES as GetAllCfgsDomainEnum,
    config_name: ROOM_CONFIGS_NAMES.AI_PROFILE
  });

  const cfgPerm = useCfgPerm({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.AI_PROFILE,
  });

  const isOwner = useIsOwnerOrSuperAdmin();

  const roomConfigsSortedData = React.useMemo(() => {
    return (
      configsRs?.data.content?.sort(
        (a, b) => new Date(b.created_at).getDate() - new Date(a.created_at).getDate()
      ) ?? []
    );
  }, [configsRs]);

  const { data: jsonFormSchema } = useGetJsonForm({
    roomName: ROOM_NAMES.SERVICES,
    configName: ROOM_CONFIGS_NAMES.AI_PROFILE,
  });

  const { mutate: createCfgMutate } = useSysCfgCreateMutation();
  const { mutate: changeEnabledMutate } = useSysCfgChangeEnabledMutation();
  const { mutate: deleteCfgsMutate } = useSysCfgDeleteMutation();
  const { mutate: updateCfgMutate } = useSysCfgUpdateMutation();

  //This logic for reset row selection while using filtering/grouping
  const [columnFilters, setColumnFilters] = useState<any>([]);
  const [grouping, setGrouping] = useState<any>([]);
  const [globalFilter, setGlobalFilter] = useState<any>(undefined);
  const [editingConfig, setEditingConfig] = useState<SysCfgRegularDto | undefined>(undefined);
  const [addConfig, setAddConfig] = useState<boolean>(false)
  const [addingConfig, setAddingConfig] = useState<SysCfgCreateRequestDto | undefined>(undefined);
  const [bulkOperationResult, setBulkOperationResult] = useState<ResultDtoLong[] | undefined>(undefined);

  //Storing table setting into localStorage
  const loadTablesSettingsFromLocalStorage = () => {
    const item = localStorage.getItem("tablesSettings");
    return item ? JSON.parse(item) : {};
  }

  const [allTablesUserSetting, setAllTablesUserSetting] = useState<any>(() => loadTablesSettingsFromLocalStorage());

  useEffect(() => {
    localStorage.setItem("tablesSettings", JSON.stringify(allTablesUserSetting))
  }, [allTablesUserSetting]);


  function closeAddConfig() {
    setAddingConfig(undefined);
    setAddConfig(false)
  }

  function handleClickAddConfig() {
    setAddConfig(true)
  }


  function handleClickSaveNewConfig(config: SysCfgCreateRequestDto) {
    createCfgMutate({
      domain: ROOM_NAMES.SERVICES as CreateCfgDomainEnum,
      cfgName: ROOM_CONFIGS_NAMES.AI_PROFILE,
      body: config
    }, {
      onSuccess: (response) => {
        enqueueSnackbar(`${response.data} elements has been updated`, {variant: 'success'});
        closeAddConfig()
        void refetchConfigs()
      },
      onError: (err) => {
        enqueueSnackbar(`Operation failure: ${err.message}. Contact an administrator`, {variant: 'error'});
        console.error("Adding config failure", err);
      }
    })
  }


  function handleClickUpdateConfig(name: string, config: SysCfgUpdateRequestDto) {
    updateCfgMutate({
      domain: ROOM_NAMES.SERVICES as PatchCfgDataDomainEnum,
      cfgName: ROOM_CONFIGS_NAMES.AI_PROFILE,
      name: name,
      body: config
    }, {
      onSuccess: (response) => {
        enqueueSnackbar(`${response.data} elements has been updated`, {variant: 'success'});
        setEditingConfig(undefined)
        void refetchConfigs()
      },
      onError: (err) => {
        enqueueSnackbar(`Operation failure: ${err.message}. Contact an administrator`, {variant: 'error'});
        console.error("Config edit failure", err);
      }
    })
  }

  function handleChangeEnableBulk(
    table: MRT_TableInstance<SysCfgRegularDto>,
    enabled: boolean
  ) {
    const names = table.getSelectedRowModel().rows.map((item) => item.original.name);

    changeEnabledMutate({
      domain: ROOM_NAMES.SERVICES as ChangeCfgEnabledDomainEnum,
      cfgName: ROOM_CONFIGS_NAMES.AI_PROFILE,
      enabled: enabled,
      filters: new Set([{key: "name", mode: "STRICT_IN", value: names as unknown as object}])
    }, {
      onSuccess: (response) => {
        enqueueSnackbar(`${response.data} elements has been updated`, {variant: 'success'});
        table.resetRowSelection();
        void refetchConfigs()
      },
      onError: (err) => {
        enqueueSnackbar(`Operation failure: ${err.message}. Contact an administrator`, {variant: 'error'});
        console.error("Change enabled operation failure", err);
      }
    })
  }

  function handleDeleteConfigs(table: MRT_TableInstance<SysCfgRegularDto>) {
    const names = table.getSelectedRowModel().rows.map((item) => item.original.name);

    deleteCfgsMutate({
      domain: ROOM_NAMES.SERVICES as DeleteCfgDomainEnum,
      cfgName: ROOM_CONFIGS_NAMES.AI_PROFILE,
      filters: new Set([{key: "name", mode: "STRICT_IN", value: names as unknown as object}])
    }, {
      onSuccess: (response) => {
        setBulkOperationResult([response.data]);
        response.data.dataOnError ?
          enqueueSnackbar(`Something went wrong`, {variant: 'error'}) :
          enqueueSnackbar(`Elements has been deleted`, {variant: 'success'});
        table.resetRowSelection();
        void refetchConfigs()
      },
      onError: (err) => {
        enqueueSnackbar(`Operation failure: ${err.message}. Contact an administrator`, {variant: 'error'});
        console.error("Delete operation failure", err);
      }
    })
  }

  const columns = useMemo<MRT_ColumnDef<SysCfgRegularDto>[]>(
    () => [
      {
        header: "Enabled",
        filterFn: "contains",
        enableEditing: false,
        accessorFn: (row) => row.enabled ?? "-",
        size: 80,
        Cell: ({ row }) => <Checkbox disabled checked={row.original.enabled} />
      },
      {
        header: "Name",
        accessorKey: "name",
        filterFn: "contains",
      },
      {
        header: "Game type",
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          if (Array.isArray(data?.profile)) {
            return data?.profile.map((itm) => {
              const gt = itm?.game_type;

              if(typeof gt === "string" || gt instanceof String) {
                return gt;
              }

              return ""
            }).toString()
          }

          return "-"
        },
        filterFn: "contains",
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    data: roomConfigsSortedData ?? [],
    columns: columns,
    getRowId: (originalRow) => originalRow.id,
    selectAllMode: "all",
    initialState: {
      pagination: { pageSize: 20, pageIndex: 0 },
      density: "compact",
    },
    enableSelectAll: true,
    enableRowSelection: true,
    enableGrouping: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableColumnFilterModes: true,
    enableRowActions: true,
    enablePagination: true,
    enableStickyHeader: true,
    enableSorting: true,
    autoResetPageIndex: false,
    state: {
      columnFilters,
      globalFilter,
      grouping,
      columnVisibility: allTablesUserSetting?.aiProfile?.columnVisibility ?? {},
      columnOrder: allTablesUserSetting?.aiProfile?.columnOrder ?? [],
      isLoading: configsIsLoading,
    },
    muiTablePaperProps: {
      sx: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
      },
    },
    muiTableContainerProps: {
      sx: {
        flexGrow: 10,
        overflowY: "scroll",
        height: "500px",
      },
    },
    onColumnFiltersChange: setColumnFilters,
    onGroupingChange: setGrouping,
    onGlobalFilterChange: setGlobalFilter,
    onColumnOrderChange: (newColumnOrder) => {
      setAllTablesUserSetting({
        ...allTablesUserSetting,
        aiProfile: {...allTablesUserSetting.aiProfile, columnOrder: (newColumnOrder as string[])},
      })
    },
    onColumnVisibilityChange: (updaterFn: Updater<VisibilityState> ) => {
      setAllTablesUserSetting((prev: any) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newVisibility = updaterFn(prev.aiProfile?.columnVisibility);
        return {
          ...prev,
          aiProfile: {...prev.aiProfile, columnVisibility: newVisibility},
        };
      })
    },

    renderTopToolbarCustomActions: ({ table }) => {
      const selectedCount = table.getSelectedRowModel().rows.length;
      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          <Button onClick={() => handleClickAddConfig()}>
            <PlusOutlined />
            New
          </Button>
          {selectedCount > 0 && (
            <div style={{ marginLeft: 30 }}>
              <IconButton
                size="small"
                style={{ fontSize: "15px" }}
                color="success"
                onClick={() => handleChangeEnableBulk(table, true)}
              >
                <Power /> Enable
              </IconButton>
              <IconButton
                size="small"
                style={{ fontSize: "15px" }}
                color="error"
                onClick={() => handleChangeEnableBulk(table, false)}
              >
                <PowerOff /> Disable
              </IconButton>
              <Popconfirm
                  title="Delete items"
                  description="Are you sure to delete these items? (Changes cannot be undone)"
                  onConfirm={() => handleDeleteConfigs(table)}
              >
                <IconButton
                    size="small"
                    style={{ fontSize: "15px" }}
                    color="error"
                >
                  <DeleteOutlined /> Delete
                </IconButton>
              </Popconfirm>

              {isOwner && (
                <ApplyAuthGroupsTableControl
                  domain={ROOM_NAMES.SERVICES}
                  cfgName={ROOM_CONFIGS_NAMES.AI_PROFILE}
                  table={table}
                  filters={assembleNamesFilter(table)}
                  totalElementsCount={selectedCount}
                  onSuccess={() => void refetchConfigs()}
                />
              )}
            </div>
          )}
        </div>
      );
    },
    renderRowActions: ({ row }) => (
      <Box>
        <IconButton onClick={() => setEditingConfig({ ...row.original })}>
          <Edit />
        </IconButton>
        <IconButton
          onClick={() => {
            setAddingConfig({ ...row.original, name: "", auth_groups: row.original.auth_groups ?? new Set() })
            setAddConfig(true)
          }}
        >
          <ContentCopy />
        </IconButton>
      </Box>
    ),
  });


  useEffect(() => {
    table.resetRowSelection();
  }, [columnFilters, grouping, globalFilter, table]);

  return (
    <>
      <MaterialReactTable table={table} />

      {addConfig && (
        <>
          {jsonFormSchema ? (
            <RegularConfigCreateModal
              title="Create config"
              open={addConfig}
              uiSchema={jsonFormSchema}
              defaultValues={addingConfig}
              onSave={(config) => handleClickSaveNewConfig(config)}
              onCancel={() => closeAddConfig()}
            />
          ) : (
            <CommonConfigWriteModal
              mode="add"
              isOpen={addConfig}
              defaultValues={addingConfig}
              onSave={(config) => handleClickSaveNewConfig({...config})}
              onCancel={() => closeAddConfig()}
            />
          )}
        </>
      )}

      {editingConfig && (
        <>
          {jsonFormSchema ? (
            <RegularConfigEditModal
              title="Edit config"
              open={!!(editingConfig && jsonFormSchema)}
              uiSchema={jsonFormSchema}
              config={editingConfig}
              onSave={(config) => handleClickUpdateConfig(config.name, config)}
              onCancel={() => setEditingConfig(undefined)}
              disabled={!cfgPerm.write}
            />
          ) : (
            <CommonConfigWriteModal
              mode="edit"
              isOpen={!!editingConfig}
              defaultValues={editingConfig}
              onSave={(config) => handleClickUpdateConfig(config.name, {...config})}
              onCancel={() => setEditingConfig(undefined)}
              disabled={!cfgPerm.write}
            />
          )}
        </>
      )}

      {bulkOperationResult  && (
        <BulkOperationReportModal
          resultList={bulkOperationResult}
          title="Operation result"
          showProblematic
          showSuccess
          onCancel={() => setBulkOperationResult(undefined)}
          open={!!bulkOperationResult}
        />
      )}
    </>
  );
};

export default AiProfileConfigTable;
