import { FormContainer, PasswordElement, useForm } from "react-hook-form-mui";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { ChangePasswordRequestDto } from "@/shared/api";
import { useChangeUserPasswordMutation } from "@/api/hooks/user-hooks";


const ChangeUserPasswordModal = (
  {
    userId,
    onClose,
    onSuccess,
    open = false
  } : {
    userId: number;
    onClose: () => void;
    onSuccess?: () => void;
    open?: boolean
  }
) => {
  const {mutate: changePassword, isPending } = useChangeUserPasswordMutation()
  const form = useForm<ChangePasswordRequestDto>({defaultValues: {id: userId}});

  const handleOnSuccess = (data: ChangePasswordRequestDto) => {
    changePassword({
      userId: userId,
      password: data.password
    }, {
      onSuccess: () => {
        onClose()
        onSuccess && onSuccess()
        enqueueSnackbar("Password successfully changed!", { variant: "success" })
      },
      onError: (err) => {
        console.error("change user password error:", err);
        enqueueSnackbar(`Something went wrong while changing password: ${err.message}`, { variant: "error" })
      }
    })
  }

  useEffect(() => {
    if(!open) {
      form.reset()
    }
  }, [form, open])

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>Change User Password</DialogTitle>

      <FormContainer formContext={form} onSuccess={(data) => handleOnSuccess(data)}>

        <DialogContent>
          {/*<TextFieldElement*/}
          {/*  autoFocus*/}
          {/*  name="passowrd"*/}
          {/*  required*/}
          {/*  label="New password"*/}
          {/*  variant="outlined"*/}
          {/*/>*/}
          <PasswordElement autoFocus name="password" label="Password" required fullWidth/>
        </DialogContent>

        <DialogActions>
          <Button type="submit" endIcon={
            isPending ? <CircularProgress color="inherit" size={20}/> : undefined
          }>Save</Button>
        </DialogActions>

      </FormContainer>
    </Dialog>
  )
}

export default ChangeUserPasswordModal;