import React, { useMemo } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useAccountAndSessionsCountByTypeQuery } from "@/api/hooks/util-hooks";
import { AccountsAndSessionCountByTypeDomainEnum } from "@/shared/api";
import { COMMON_REFETCH_INTERVAL } from "@/lib/constants";
import { AccountSessionType } from "@/types/accountTypes";
import { ACC_TP } from "@/components/config-modals/router-edit-modal/constants";
import { Route } from "@/routes/accounts/$room.$type";
import RegularAccountsTable from "@/module/accounts-module/regular-accounts-table/regular-accounts-table";
import ServiceAccountsTable from "@/module/accounts-module/service-accounts-table/service-accounts-table";
import GameSessionsTable from "@/module/accounts-module/game-sessions-table/game-sessions-table";
import DebugSessionsTable from "@/module/accounts-module/debug-sessions-table/debug-sessions-table";
import ServiceSessionsTable from "@/module/accounts-module/service-sessions-table/service-sessions-table";


export enum AccountType {
  regular = "regular",
  service = "service",
  gameSessions = "game-sessions",
  debugSessions = "debug-sessions",
  serviceSessions = "service-sessions"
}


const AccountsModule = () => {
  const { room, type: unsafeAccountType } = Route.useParams();
  const navigate = Route.useNavigate();

  const accountType = useMemo<AccountType>(() => {
    return Object.values(AccountType).includes(unsafeAccountType as AccountType)
      ? unsafeAccountType as AccountType
      : AccountType.regular;
  }, [unsafeAccountType]);

  const setTabValue = (value: AccountType) => {
    void navigate({
      to: "/accounts/$room/$type",
      params: {room: room, type: value}
    });
  }

  const {data: countDataResponse} = useAccountAndSessionsCountByTypeQuery(
    {
      domain: room as AccountsAndSessionCountByTypeDomainEnum
    },
    {
      refetchInterval: COMMON_REFETCH_INTERVAL
    }
  );

  const accountAmount = useMemo<number>(() => {
    return countDataResponse?.data.accounts[ACC_TP.REGULAR.toString()] ?? 0;
  }, [countDataResponse]);

  const serviceAccountAmount = useMemo<number>(() => {
    return Object.entries(countDataResponse?.data.accounts ?? {}).reduce(
      (previousValue, currentValue) => {
        const [type, count] = currentValue;
        return type === ACC_TP.REGULAR.toString()
          ? previousValue
          : previousValue + count;
      },
    0)
  }, [countDataResponse]);

  const gameSessionsAmount = useMemo<number>(() => {
    return countDataResponse?.data.sessions[AccountSessionType.GAME.toString()] ?? 0;
  }, [countDataResponse]);

  const debugSessionsAmount = useMemo<number>(() => {
    return countDataResponse?.data.sessions[AccountSessionType.DEBUG.toString()] ?? 0;
  }, [countDataResponse]);

  const serviceSessionsAmount = useMemo<number>(() => {
    return Object.entries(countDataResponse?.data.sessions ?? {}).reduce(
      (previousValue, currentValue) => {
        const [type, count] = currentValue;
        return type === AccountSessionType.DEBUG.toString() || type === AccountSessionType.GAME.toString()
          ? previousValue
          : previousValue + count;
      },
      0)
  }, [countDataResponse]);

  return <>
    <div style={{height: "100%", display: "flex", flexDirection: "column"}}>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs
          onChange={(_e, value: AccountType) => setTabValue(value)}
          value={accountType}
        >
          <Tab label={`Accounts (${accountAmount})`} value={AccountType.regular}/>
          <Tab label={`Service accounts (${serviceAccountAmount})`} value={AccountType.service} />
          <Tab label={`Game sess-s (${gameSessionsAmount})`} value={AccountType.gameSessions}/>
          <Tab label={`Debug sess-s (${debugSessionsAmount})`} value={AccountType.debugSessions}/>
          <Tab label={`Service sess-s (${serviceSessionsAmount})`} value={AccountType.serviceSessions}/>
        </Tabs>
      </Box>


      <div style={{ height: "100%" }}>
        <div hidden={accountType !== AccountType.regular} style={{ height: "100%" }}>
          <RegularAccountsTable
            roomName={room}
            isOpen={accountType === AccountType.regular}
          />
        </div>

        <div hidden={accountType !== AccountType.service} style={{ height: "100%" }}>
          <ServiceAccountsTable
            roomName={room}
            isOpen={accountType === AccountType.service}
          />
        </div>

        <div hidden={accountType !== AccountType.gameSessions} style={{ height: "100%" }}>
          <GameSessionsTable
            roomName={room}
            isOpen={accountType === AccountType.gameSessions}
          />
        </div>

        <div hidden={accountType !== AccountType.debugSessions} style={{ height: "100%" }}>
          <DebugSessionsTable
            roomName={room}
            isOpen={accountType === AccountType.debugSessions}
          />
        </div>

        <div hidden={accountType !== AccountType.serviceSessions} style={{ height: "100%" }}>
          <ServiceSessionsTable
            roomName={room}
            isOpen={accountType === AccountType.serviceSessions}
          />
        </div>

      </div>
    </div>
  </>
}

export default AccountsModule;