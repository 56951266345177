import { MaterialReactTable, MRT_ColumnDef, MRT_Row, useMaterialReactTable } from "material-react-table";
import { useAuthGroupsQuery, useDeleteAuthGroupsMutation } from "@/api/hooks/auth-groups-hooks";
import React, { useMemo, useState } from "react";
import { AuthGroupRegularResponseDto } from "@/shared/api";
import CreateAuthGroupModal from "@/components/create-auth-group-modal";
import { Button } from "@mui/material";
import { AddCircleOutlined, Delete } from "@mui/icons-material";
import { COMMON_REFETCH_INTERVAL } from "@/lib/constants";
import { enqueueSnackbar } from "notistack";


const AuthGroupsModule = () => {
  const {
    data: authGroups,
    isLoading: authGroupsIsLoading,
    isError: authGroupsIsError,
    error: authGroupsError,
    isRefetching: authGroupsIsRefetching,
    refetch: refetchAuthGroups,
  } = useAuthGroupsQuery({
    refetchInterval: COMMON_REFETCH_INTERVAL
  });

  const [createNew, setCreateNew] = useState<boolean>(false);
  const deleteAuthGroups = useDeleteAuthGroupsMutation();


  function handleDeleteAuthGroups(rows: MRT_Row<AuthGroupRegularResponseDto>[]) {
    deleteAuthGroups.mutate(
      {
        groups: rows.map((row) => row.original.name)
      },
      {
        onSuccess: (response) => {
          refetchAuthGroups().then()
          enqueueSnackbar(`${response.data} auth groups successfully deleted!`, { variant: "success" })
        },
        onError: (err) => {
          enqueueSnackbar(`Something went wrong during deleting auth groups`, { variant: "error" })
          console.error("deleting auth groups failure", err)
        }
      }
    )
  }


  const columns = useMemo<MRT_ColumnDef<AuthGroupRegularResponseDto>[]>(() => [
    {
      header: "Name",
      accessorKey: "name"
    }
  ], [])

  const table = useMaterialReactTable({
    data: authGroups?.data ?? [],
    columns: columns,
    getRowId: (originalRow) => originalRow.name ?? "",
    selectAllMode: "all",
    initialState: {
      pagination: { pageSize: 20, pageIndex: 0 },
      density: "compact",
    },
    enableGrouping: true,
    enableColumnDragging: false,
    enableColumnFilterModes: true,
    enablePagination: true,
    enableStickyHeader: true,
    enableSorting: true,
    autoResetPageIndex: false,
    enableSelectAll: true,
    enableRowSelection: true,
    muiToolbarAlertBannerProps: authGroupsIsError
      ? {
        color: 'error',
        children: authGroupsError.message ?? "Something went wrong while loading data",
      }
      : undefined,
    state: {
      isLoading: authGroupsIsLoading,
      showAlertBanner: authGroupsIsError,
      showProgressBars: authGroupsIsRefetching,
    },
    muiTablePaperProps: {
      sx: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
      },
    },
    muiTableContainerProps: {
      sx: { flexGrow: 10, overflowY: "scroll", height: "500px" },
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const rows = table.getSelectedRowModel().rows;
      return (
        <div>
          <Button
            variant="text"
            onClick={() => setCreateNew(true)}
            startIcon={<AddCircleOutlined/>}
          >New</Button>
          {rows.length > 0 && (
            <Button
              startIcon={<Delete/>}
              color="error"
              onClick={() => handleDeleteAuthGroups(rows)}
            >Delete ({rows.length})</Button>
          )}
        </div>
      )
    }
  });

  return (<>
    <CreateAuthGroupModal
      onClose={() => setCreateNew(false)}
      onSuccess={() => refetchAuthGroups()}
      open={createNew}
    />
    <MaterialReactTable table={table}/>
  </>)
}

export default AuthGroupsModule;