import { Button, message } from "antd";
import { ImportOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import ImportAccountsModal from "@/components/config-modals/import-accounts-modal";
import { useSysAccountCreateAccountsMutation } from "@/api/hooks/sys-account-hooks";
import { CreateAccountsDomainEnum, SysAccountCreateDto, SysAccountRegularDto } from "@/shared/api";


const ImportAccountsTableControl = (
  {
    roomName,
    onSuccess
  } : {
    roomName: string;
    onSuccess?: (data: SysAccountRegularDto[]) => void,
  }
) => {

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const {
    mutate: createAccountsMutate,
    isPending: createAccountsIsPending
  } = useSysAccountCreateAccountsMutation();

  function handleCreateAccounts(accounts: SysAccountCreateDto[]) {
    createAccountsMutate({
      domain: roomName as CreateAccountsDomainEnum,
      accounts: accounts
    }, {
      onSuccess: (response) => {
        setIsOpen(false);
        onSuccess && onSuccess(response.data)
      },
      onError: (error) => {
        message.error("something went wrong while creating elements, contact an administrator").then();
        console.error("Something went wrong while creating elements", error);
      }
    })
  }

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>
        <ImportOutlined /> Import csv
      </Button>
      {isOpen && (
        <ImportAccountsModal
          title="Add accounts bulk (csv)"
          confirmLoading={createAccountsIsPending}
          onSave={(accounts) => handleCreateAccounts(accounts)}
          onCancel={() => setIsOpen(false)}
        />
      )}
    </>
  )
}



export default ImportAccountsTableControl;