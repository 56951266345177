import {
  useSysAccountCreateAccountsMutation,
  useSysAccountGenerateAccountsByNumberMutation,
} from "@/api/hooks/sys-account-hooks";
import { useCallback } from "react";
import { CreateAccountsDomainEnum, GenerateAccountsDto, SysAccountCreateDto } from "@/shared/api";
import { innerStateOfConfigIsNotSatNotify } from "@/lib/notify";
import { enqueueSnackbar } from "notistack";
import { AxiosError } from "axios";

type HookOptions = {
  roomName: string,
  successCallback?: () => void
}

export const useSaveNewAccount = ({
  roomName,
  successCallback = undefined
} : HookOptions) => {
  const mutation = useSysAccountCreateAccountsMutation();

  const callback = useCallback((data: SysAccountCreateDto) => {
    if (!data) {
      innerStateOfConfigIsNotSatNotify();
      return;
    }

    mutation.mutate({
      domain: roomName as CreateAccountsDomainEnum,
      accounts: [data]
    }, {
      onSuccess: () => {
        enqueueSnackbar("New config successfully added", {variant: "success"});
        successCallback && successCallback()
      },
      onError: (err) => {
        enqueueSnackbar("Something went wrong while adding new config. Contact administrator", {variant: "error"})
        console.error("save account failure: ", err)
      },
    })
  }, [mutation, roomName, successCallback]);

  return {
    ...mutation,
    mutate: callback
  }
}

export const useGenerateNewAccounts = ({
                                    roomName,
                                    successCallback = undefined
                                  } : HookOptions) => {
  const mutation = useSysAccountGenerateAccountsByNumberMutation();

  const callback = useCallback((data: GenerateAccountsDto) => {
    if (!data) {
      innerStateOfConfigIsNotSatNotify();
      return;
    }

    mutation.mutate({
      domain: roomName as CreateAccountsDomainEnum,
      generateAccountsBuNumberDto: data
    }, {
      onSuccess: () => {
        enqueueSnackbar("New accounts successfully generated", {variant: "success"});
        successCallback && successCallback()
      },
      onError: (err) => {
        enqueueSnackbar((err as unknown as AxiosError<{message: string}>).response?.data?.message ?? "Something went wrong while generation new configs. Contact administrator", {variant: "error"})
        console.error("generate account failure: ", err)
      },
    })
  }, [mutation, roomName, successCallback]);

  return {
    ...mutation,
    mutate: callback
  }
}

