import {MaterialReactTable, MRT_ColumnDef, MRT_TableInstance, useMaterialReactTable} from "material-react-table";
import React, {useEffect, useMemo, useState} from "react";
import {Updater, VisibilityState} from "@tanstack/table-core";


import {GetAllCfgsDomainEnum,} from "@/shared/api";
import {useSysClusteringPatternResultsQuery} from "@/api/hooks/sys-clusteing-pattern-results-hooks";
import {IconButton} from "@mui/material";
import {FileDownload} from "@mui/icons-material";
// @ts-ignore
import {download, generateCsv, mkConfig} from "export-to-csv";
import {useAtom} from "jotai/index";
import {appCurrentRoom} from "@/store/store";


type ClusteringPatternResult = {
  pid: string,
  gameType: string,
  patterns: string
}

const ClusteringPatternResultsTable = (
  {
    setRowsAmount,
  } : {
    setRowsAmount: (amount: number) => void;
  }
) => {
  const [selectedRoom] = useAtom(appCurrentRoom);

  const {data: configsRs, isLoading: configsIsLoading} = useSysClusteringPatternResultsQuery({
    domain: selectedRoom?.name as GetAllCfgsDomainEnum,
    queryOptions: {
      enabled: !!selectedRoom as boolean,
    }
  });

  const results = useMemo<ClusteringPatternResult[]>(() => {
    const obj = configsRs?.data ?? {};
    return Object.entries(obj).flatMap(entry => {
      const pid = entry[0]
      const gameTypes = entry[1]

      return Object.entries(gameTypes).map( gtEntry => {
        const gameType = gtEntry[0]
        const patterns = gtEntry[1]

        return { pid: pid, gameType: gameType, patterns: patterns.join(", ")}
      })
    })
  }, [configsRs]);

  function handleExportRoutersToCsvClick(table: MRT_TableInstance<ClusteringPatternResult>) {
    const preparedForExport = new Array<any>()
    const patterns = table.getSelectedRowModel().rows.map((item) => item.original);

    patterns.forEach(pattern => {
      preparedForExport.push({
        pid: pattern.pid,
        game_type: pattern.gameType,
        pattern: pattern.patterns,
      })

    })
    
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });

    const csv = generateCsv(csvConfig)(preparedForExport);
    download(csvConfig)(csv);
  }

  //This logic for reset row selection while using filtering/grouping
  const [columnFilters, setColumnFilters] = useState<any>([]);
  const [grouping, setGrouping] = useState<any>([]);
  const [globalFilter, setGlobalFilter] = useState<any>(undefined);
  
  //Storing table setting into localStorage
  const loadTablesSettingsFromLocalStorage = () => {
    const item = localStorage.getItem("tablesSettings");
    return item ? JSON.parse(item) : {};
  }

  const [allTablesUserSetting, setAllTablesUserSetting] = useState<any>(() => loadTablesSettingsFromLocalStorage());

  useEffect(() => {
    localStorage.setItem("tablesSettings", JSON.stringify(allTablesUserSetting))
  }, [allTablesUserSetting]);

  useEffect(() => {
    setRowsAmount( Object.values(configsRs?.data ?? {}).length)
  }, [configsRs, setRowsAmount])

  const columns = useMemo<MRT_ColumnDef<ClusteringPatternResult>[]>(() => {
    return [
      {
        id: "pid",
        header: "Pid",
        filterFn: "contains",
        accessorFn: row => row.pid,
      },
      {
        id: "gameType",
        header: "Game type",
        accessorFn: (row) => row.gameType,
        filterFn: "contains",
      },
      {
        id: "pattern",
        header: "Pattern",
        accessorFn: (row) => row.patterns,
        filterFn: "contains",
      }
    ];
  }, []);


  const table = useMaterialReactTable({
    getRowId: row => row.pid + row.gameType,
    data: results ?? [],
    columns: columns,
    selectAllMode: "all",
    initialState: {
      pagination: { pageSize: 20, pageIndex: 0 },
      density: "compact",
    },
    enableRowSelection: true,
    enableGrouping: true,
    // enableColumnDragging: true,
    // enableColumnOrdering: true,
    enableColumnFilterModes: true,
    enablePagination: true,
    enableStickyHeader: true,
    enableSorting: true,
    autoResetPageIndex: false,
    state: {
      columnFilters,
      globalFilter,
      grouping,
      columnVisibility: allTablesUserSetting?.clusteringPattern?.columnVisibility ?? {},
      columnOrder: allTablesUserSetting?.clusteringPattern?.columnOrder ?? [],
      isLoading: configsIsLoading,
    },
    muiTablePaperProps: {
      sx: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
      },
    },
    muiTableContainerProps: {
      sx: {
        flexGrow: 10,
        overflowY: "scroll",
        height: "500px",
      },
    },

    onColumnFiltersChange: setColumnFilters,
    onGroupingChange: setGrouping,
    onGlobalFilterChange: setGlobalFilter,
    onColumnOrderChange: (newColumnOrder) => {
      setAllTablesUserSetting({
        ...allTablesUserSetting,
        clusteringPattern: {...allTablesUserSetting.clusteringPattern, columnOrder: (newColumnOrder as string[])},
      })
    },
    onColumnVisibilityChange: (updaterFn: Updater<VisibilityState> ) => {
      setAllTablesUserSetting((prev: any) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newVisibility = updaterFn(prev.clusteringPattern?.columnVisibility);
        return {
          ...prev,
          clusteringPattern: {...prev.clusteringPattern, columnVisibility: newVisibility},
        };
      })
    },


    renderTopToolbarCustomActions: () => {
      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          <IconButton
            size="small"
            style={{ fontSize: "15px" }}
            color="secondary"
            disabled={table.getSelectedRowModel().rows.length < 1}
            onClick={() => handleExportRoutersToCsvClick(table)}
          >
            <FileDownload/> Export csv
          </IconButton>
        </div>
      );
    },
  });

  useEffect(() => {
    table.resetRowSelection();
  }, [columnFilters, grouping, globalFilter, table]);

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};

export default ClusteringPatternResultsTable;
