import { useUpdateNicknameMutation, useUpdateNicknamesMutation } from "@/api/hooks/command-hooks";
import { MRT_Row, MRT_TableInstance } from "material-react-table";
import {
  KeyFilter,
  SysAccountSessionRegularDto,
  UpdateAccountsNicknamesDomainEnum,
} from "@/shared/api";
import React, { useState } from "react";
import { message, Popconfirm } from "antd";
import { IconButton } from "@mui/material";
import UpdateNicknameModal from "@/module/accounts-module/components/update-nickname-modal";
import { Badge } from "@mui/icons-material";
import { assembleParticularFilterCt } from "@/lib/cfg-utils";


const UpdateNicknamesTableControl = (
  {
    roomName,
    table,
    filters,
    totalElementsCount,
    onSuccess
  } : {
    roomName: string;
    table: MRT_TableInstance<SysAccountSessionRegularDto>;
    filters: Set<KeyFilter>;
    totalElementsCount: number;
    onSuccess?: () => void
  }
) => {
  const [updateOneIsOpen, setUpdateOneIsOpen] = useState<boolean>(false);

  const updateNicknameMutation = useUpdateNicknameMutation();
  const updateNicknamesMutation = useUpdateNicknamesMutation();

  function handleOneMutationAction(
    {
      row,
      newNickname,
    } : {
      row?: MRT_Row<SysAccountSessionRegularDto>,
      newNickname?: string,
    }
  ) {
    if (!row) {
      message.error("There are no one role selected").then()
      return
    }

    callOneMutation(row.original.id, newNickname)
  }

  function handleManyMutationAction(rows: MRT_Row<SysAccountSessionRegularDto>[]) {
    if(rows.length === 0) {
      callManyMutation(filters)
    } else {
      callManyMutation(assembleParticularFilterCt({
        rows: rows, keyName: "id", keySupplier: (val) => val.id
      }))
    }
  }

  function callOneMutation(sessionId: string, newNickname?: string) {
    updateNicknameMutation.mutate(
      {
        domain: roomName as UpdateAccountsNicknamesDomainEnum,
        sessionId: sessionId,
        newNickname: newNickname,
      },
      {
        onSuccess: () => {
          message.success("Update nickname command successfully sent").then();
          setUpdateOneIsOpen(false);
          onSuccess && onSuccess();
        },
        onError: (err) => {
          console.log("update nickname command failure", err)
          message.error("Something went wrong while updating nickname, contact the administrator").then();
        }
      }
    )
  }

  function callManyMutation(filters: Set<KeyFilter>) {
    updateNicknamesMutation.mutate(
      {
        domain: roomName as UpdateAccountsNicknamesDomainEnum,
        filters: filters
      },
      {
        onSuccess: () => {
          message.success("Update nicknames command successfully sent").then();
          onSuccess && onSuccess();
        },
        onError: (err) => {
          console.log("update nicknames command failure", err)
          message.error("Something went wrong while updating nicknames, contact the administrator").then();
        }
      }
    )
  }


  return (
    <>
      {table.getSelectedRowModel().rows.length === 1 ? (
        <IconButton
          size="small"
          style={{ fontSize: 12 }}
          color="primary"
          onClick={() => setUpdateOneIsOpen(true)}
        >
          <Badge/>&nbsp; Update nicknames
          ({
            table.getSelectedRowModel().rows.length > 0
              ? (table.getSelectedRowModel().rows.length)
              : (totalElementsCount)
          })
        </IconButton>
      ) : (
        <Popconfirm
          title="Are you sure you want to update nicknames?"
          onConfirm={() => handleManyMutationAction(table.getSelectedRowModel().rows)}
        >
          <IconButton
            size="small"
            style={{ fontSize: 12 }}
            color="primary"
          >
            <Badge/>&nbsp; Update nicknames
            ({
            table.getSelectedRowModel().rows.length > 0
              ? (table.getSelectedRowModel().rows.length)
              : (totalElementsCount)
          })
          </IconButton>
        </Popconfirm>
      )}

      <UpdateNicknameModal
        onCancel={() => setUpdateOneIsOpen(false)}
        onSave={(nickname) => handleOneMutationAction({
          row: table.getSelectedRowModel().rows[0],
          newNickname: nickname
        })}
        isOpen={updateOneIsOpen}
      />
    </>
  )
}


export default UpdateNicknamesTableControl;