import { IconButton } from "@mui/material";
import React, { useMemo, useState } from "react";
import ASelectPopover from "@/components/ASelectPopover";
import { MoveUp } from "@mui/icons-material";
import { ExploitationStage } from "@/lib/constants";
import { MRT_Row, MRT_TableInstance } from "material-react-table";
import { KeyFilter, MigrateAccountsDomainEnum, SysAccountSessionRegularDto } from "@/shared/api";
import { keepPreviousData } from "@tanstack/react-query";
import { message } from "antd";
import { useMigrateSessionsMutation } from "@/api/hooks/command-hooks";
import { useGetAllGSRawQuery } from "@/api/hooks/platform-service-hooks";
import { assembleParticularFilterCt } from "@/lib/cfg-utils";


const MigrateSessionsTableControl = (
  {
    roomName,
    table,
    filters,
    onSuccess,
    totalElementsCount
  } : {
    roomName: string;
    table: MRT_TableInstance<SysAccountSessionRegularDto>;
    filters: Set<KeyFilter>;
    onSuccess?: () => void;
    totalElementsCount: number;
  }
) => {
  const [open, setOpen] = useState<boolean>(false);
  const [gsId, setGsId] = useState<string | undefined>(undefined);

  const { data: gsListRs, isLoading: gsListIsLoading } = useGetAllGSRawQuery(
    {roomName},
    {refetchInterval: 15000, enabled: open, placeholderData: keepPreviousData}
  )

  const gsList = useMemo(() => {
    return gsListRs?.data ?? []
  }, [gsListRs]);

  const mutation = useMigrateSessionsMutation();

  const selectOptions = useMemo(() => {
    return gsList
      ?.filter(item => {
        const rows = table.getSelectedRowModel().rows;
        if(rows.length === 1) {
          return rows[0].original.tes !== item.id
        }
        return true;
      })
      ?.filter(item => item.mode !== ExploitationStage.UNAVAILABLE)
      .map(item => ({ value: item.id, label: item.id })) ?? []
  }, [gsList, table]);


  function handleMigrateAccountsActionIsDone() {
    setGsId(undefined);
    setOpen(false);
  }

  function handleMutationAction(rows: MRT_Row<SysAccountSessionRegularDto>[]) {
    if(rows.length > 0) {
      mutateParticular(rows)
    } else {
      mutateAll()
    }
  }

  function mutateAll() {
    callMutation(filters)
  }

  function mutateParticular(rows: MRT_Row<SysAccountSessionRegularDto>[]) {
    callMutation(assembleParticularFilterCt({
      rows: rows, keyName: "id", keySupplier: (val) => val.id
    }))
  }

  function callMutation(filters: Set<KeyFilter>) {
    if(!gsId || gsId === "") {
      console.error("sessions migrate error: there's no gs instance id")
      message.error("Something went wrong during migration sessions, contact the administrator").then()
    } else {
      mutation.mutate({ domain: roomName as MigrateAccountsDomainEnum, gsId: gsId, filters }, {
        onSuccess: () => {
          message.success(`migrate sessions command successfully sent`).then()
          handleMigrateAccountsActionIsDone()
          onSuccess && onSuccess()
        },
        onError: (err) => {
          console.error("accounts csv export error", err)
          message.error("Something went wrong during migrating sessions, contact the administrator").then()
        }
      })
    }
  }


  return (
    <ASelectPopover
      loading={ mutation.isPending }
      containerStyle={{ display: "flex" }}
      buttonText="Ok"
      disabled={!gsId || gsId.trim() === ""}
      onOk={() => handleMutationAction(table.getSelectedRowModel().rows)}
      popoverProps={{
        title: "Choose a gs instance",
        open: open,
        trigger: "click",
        onOpenChange: (visible) => {
          if (!visible) {
            handleMigrateAccountsActionIsDone();
          }
        },
      }}
      selectProps={{
        style: { width: 350 },
        value: gsId,
        onChange: (value) => setGsId(value),
        allowClear: true,
        options: selectOptions,
        placeholder: "Choose gs instance",
        loading: gsListIsLoading,
      }}
    >
      <IconButton
        size="small"
        style={{ fontSize: 12 }}
        color="primary"
        onClick={() => setOpen(true)}
      >
        <MoveUp /> &nbsp; Migrate
        ({
          table.getSelectedRowModel().rows.length > 0
            ? (table.getSelectedRowModel().rows.length)
            : (totalElementsCount)
        })
      </IconButton>
    </ASelectPopover>
  )
}


export default MigrateSessionsTableControl