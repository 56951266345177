import { CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  FormListFieldData,
  FormListOperation,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Select,
  Typography,
} from "antd";
import { isArray, isString, isUndefined } from "lodash";
import React, { useEffect, useMemo } from "react";
import { arrayNumberNormalizer } from "@/lib/common-utils";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";

import {
  aiType,
  beforeSendNormalizers,
  dataFieldName,
  formInitState,
  gameType,
  playerCountRange,
  required,
  room,
  sizeRange,
  straddle,
  technology,
} from "./constants";
import { EditedRoute, EditRoute } from "./types";
import { useSysCfgQuery } from "@/api/hooks/sys-cfg-hooks";
import { GetAllCfgsRowDomainEnum } from "@/shared/api";
import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import {useAtom} from "jotai/index";
import {meStore} from "@/store/store";
import {isOwner} from "@/lib/auth-predicates";

const RouterEditModal = ({
  open,
  initialData,
  onSave,
  onCancel,
  confirmLoading = false,
  disabled = false
}: {
  open: boolean;
  initialData?: EditRoute;
  onSave?: (data: EditedRoute) => void;
  onCancel?: () => void;
  confirmLoading?: boolean;
  disabled?: boolean;
}) => {

  const [me] = useAtom(meStore);

  const {
    data: authGroupsRs
  } = useAuthGroupsQuery()

  const defaultValues = useMemo<EditedRoute>(() => {
    let authGroups = initialData?.auth_groups ? Array.from(initialData.auth_groups) : []

    if(authGroupsRs?.data.length === 1 && !isOwner(me)) {
      authGroups = authGroupsRs.data.map(it => it.name)
    }

    return initialData ? {
      ...initialData,
      authGroups: authGroups
    } : {
      ...formInitState,
      authGroups: authGroups
    };
  }, [initialData]);

  const { data: clusteringPatternsRs } = useSysCfgQuery({
    domain: ROOM_NAMES.SERVICES as GetAllCfgsRowDomainEnum,
    config_name: ROOM_CONFIGS_NAMES.CLUSTERING_PATTERN
  })

  const { data: aiProfilesRs } = useSysCfgQuery({
    domain: ROOM_NAMES.SERVICES as GetAllCfgsRowDomainEnum,
    config_name: ROOM_CONFIGS_NAMES.AI_PROFILE
  })


  const authGroupsOptions = useMemo(() => {
    return authGroupsRs?.data.map(i => ( {label: i.name, value: i.name } ))
  }, [authGroupsRs?.data]);

  const [form] = Form.useForm<EditedRoute>();

  useEffect(() => {
    form.setFieldsValue({ ...defaultValues });
  }, [form, defaultValues]);

  const arrayNumbersInRangeValidator = (value: any, range: number[]) => {
    if (!isArray(value)) return Promise.reject("Not array");
    for (const itm of value) {
      if (isNaN(itm)) return Promise.reject("Not a number");
      if (itm < range[0] || itm > range[1])
        return Promise.reject(`Values must be in range from ${range[0]} to ${range[1]}`);
    }
    return Promise.resolve();
  };

  const instancesProbabilityValidator = (value: any) => {
    if (!isArray(value)) return Promise.reject("Not array");
    if (value.length === 0) return Promise.resolve();

    let probabilitySum = 0;

    for (const itm of value) {
      if (!isUndefined(itm)) {
        const instance = itm as { instance: string; probability: number };
        probabilitySum += instance.probability;
      }
    }

    if (probabilitySum !== 100) {
      return Promise.reject("Sum of probability must be equal 100");
    }

    return Promise.resolve();
  };

  const groupPatternsOptions = useMemo(() => {
    return clusteringPatternsRs?.data.content
      ?.filter(item => item.enabled)
      .map(item => ({ value: item.name, label: item.name }))
    ?? []
  }, [clusteringPatternsRs]);

  const aiProfileOptions = useMemo(() => {
    return aiProfilesRs?.data.content
        ?.filter(item => item.enabled)
        .map(item => ({ value: item.name, label: item.name }))
      ?? []
  }, [aiProfilesRs]);

  function handleSubmit() {
    form
      .validateFields()
      .then((value) => {

        if (!onSave) return;
        let result = value;
        beforeSendNormalizers.forEach((normalizer) => (result = normalizer(result)));

        onSave(result);
      })
      .catch((reason) => {
        message.error("Form data is invalid. Fix the errors and try again.").then();
        console.error("Form data is invalid. Fix the errors and try again.", reason);
      });
  }

  function handleAddInstance(subFields: FormListFieldData[], subOpt: FormListOperation) {
    subOpt.add();
  }

  return (
    <>
      <Modal
        open={open}
        okText="Submit"
        onCancel={() => onCancel && onCancel()}
        width={600}
        confirmLoading={confirmLoading}
        onOk={() => handleSubmit()}
        maskClosable={false}
        okButtonProps={{
          disabled: disabled
        }}
        title={
          <Typography.Title level={3} style={{ marginTop: 15 }}>
            {!defaultValues.name ? "Create Router Rule" : "Edit Router Rule"}
          </Typography.Title>
        }
      >
        <Form
          disabled={disabled}
          size="large"
          className="a-common-form"
          form={form}
          autoComplete="off"
          style={{ marginTop: 30 }}
          initialValues={{ ...initialData }}
        >
          <Form.Item
            hidden
            name="name"
            label="Name"
            rules={[...required]}
          >
            <Input placeholder="Name" />
          </Form.Item>

          <Form.Item
            name="authGroups"
            label="Auth Groups"
            rules={[
              {required: !isOwner(me)}
            ]}
          >
            <Select
              options={authGroupsOptions}
              disabled={disabled || (authGroupsRs?.data.length === 1 && !isOwner(me))}
              style={{width: "100%"}}
              mode="multiple"
              placeholder="Auth Groups"
            />
          </Form.Item>

          <Form.Item name={[dataFieldName, "priority"]} label="Priority" rules={required}>
            <InputNumber min={1} placeholder="Priority" style={{ width: "100%" }} onInput={(text) => {
              form.setFieldValue("name", text)
            }} />
          </Form.Item>

          <Form.Item name={[dataFieldName, "room"]} label="Rooms">
            <Select options={room} placeholder="Rooms" mode="tags" allowClear />
          </Form.Item>

          <Form.Item name={[dataFieldName, "technology"]} label="Technologies">
            <Select
              options={technology}
              allowClear
              mode="multiple"
              placeholder="Technologies"
            />
          </Form.Item>

          <Form.Item name={[dataFieldName, "only_bot"]} label="Only bot">
            <Radio.Group>
              <Radio value="" key="">
                None
              </Radio>
              <Radio value="true" key="true">
                True
              </Radio>
              <Radio value="false" key="false">
                False
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item name={[dataFieldName, "partner_ai_key"]} label="Partner AI Keys">
            <Select allowClear mode="tags" placeholder="Partner AI Key" />
          </Form.Item>

          <Form.Item name={[dataFieldName, "ai_profile"]} label="AI Profile">
            <Select
              options={aiProfileOptions}
              mode="multiple"
              allowClear
              placeholder="AI Profiles"
            />
          </Form.Item>

          <Form.Item name={[dataFieldName, "game_type"]} label="Game Types">
            <Select options={gameType} allowClear mode="multiple" placeholder="Game Types" />
          </Form.Item>

          <Form.Item
            name={[dataFieldName, "size"]}
            label="Sizes"
            normalize={(value) => arrayNumberNormalizer(value)}
            rules={[
              { validator: (_rule, value) => arrayNumbersInRangeValidator(value, sizeRange) },
            ]}
          >
            <Select
              allowClear
              mode="tags"
              placeholder={`From ${sizeRange[0]} to ${sizeRange[1]}`}
            />
          </Form.Item>

          <Form.Item name={[dataFieldName, "aid"]} label="AID's">
            <Select allowClear mode="tags" placeholder="AID's" />
          </Form.Item>

          <Form.Item name={[dataFieldName, "cid"]} label="CID's">
            <Select allowClear mode="tags" placeholder="CID's" />
          </Form.Item>

          <Form.Item name={[dataFieldName, "game_group"]} label="Game Groups">
            <Select allowClear mode="tags" placeholder="Game Groups" />
          </Form.Item>

          <Form.Item name={[dataFieldName, "ai_type"]} label="AI Type">
            <Select options={aiType} allowClear placeholder="Game Types" />
          </Form.Item>

          <Form.Item
            name={[dataFieldName, "limit"]}
            label="Limits"
            normalize={(value) => arrayNumberNormalizer(value)}
          >
            <Select allowClear mode="tags" placeholder="Limits" />
          </Form.Item>

          <Form.Item name={[dataFieldName, "straddle"]} label="Straddles">
            <Select options={straddle} allowClear mode="multiple" placeholder="Straddles" />
          </Form.Item>

          <Form.Item
            name={[dataFieldName, "ante"]}
            label="Ante"
            normalize={(value) => arrayNumberNormalizer(value)}
          >
            <Select allowClear mode="tags" placeholder="Ante" />
          </Form.Item>

          <Form.Item
            name={[dataFieldName, "players_count"]}
            label="Players Count"
            normalize={(value) => arrayNumberNormalizer(value)}
            rules={[
              {
                validator: (_rule, value) =>
                  arrayNumbersInRangeValidator(value, playerCountRange),
              },
            ]}
          >
            <Select
              allowClear
              mode="tags"
              placeholder={`From ${playerCountRange[0]} to ${playerCountRange[1]}`}
            />
          </Form.Item>

          <Form.Item name={[dataFieldName, "comment"]} label="Comments">
            <Input placeholder="Comment" />
          </Form.Item>

          <Form.Item name={[dataFieldName, "group_pattern"]} label="Group Patterns">
            <Select
              options={groupPatternsOptions}
              mode="multiple"
              allowClear
              placeholder="Group Patterns"
            />
          </Form.Item>

          <Form.Item name={[dataFieldName, "single_instance"]} label="Single instance">
            <Radio.Group>
              <Radio value key="true">
                True
              </Radio>
              <Radio value={false} key="false">
                False
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item label="Instances">
            <Form.List
              name={[dataFieldName, "instances"]}
              rules={[{message: "Sum of probability must be equal 100", validator: (_rule, value) => instancesProbabilityValidator(value) }]}
            >
              {(subFields, subOpt, {errors}) => (
                <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
                  {subFields.map((subField) => (
                    <div key={subField.name}>
                      <Form.Item
                        name={[subField.name, "ai_type"]}
                        style={{marginBottom: "10px"}}
                        rules={required}
                        initialValue={aiType[0].value}
                      >
                        <Select options={aiType} allowClear placeholder="AI type" />
                      </Form.Item>
                      <span
                        key={subField.key}
                        style={{ display: "flex", alignItems: "flex-start" }}
                      >
                        <Form.Item
                          name={[subField.name, "instance"]}
                          required
                          style={{ width: "100%" }}
                          normalize={(value) => (isString(value) ? value.trim() : value)}
                          rules={required}
                        >
                          <Input placeholder="Address" />
                        </Form.Item>
                        &nbsp;&nbsp;
                        <Form.Item
                          name={[subField.name, "probability"]}
                          required
                          rules={required}
                        >
                          <InputNumber placeholder="Probability" style={{ width: 50 }} />
                        </Form.Item>
                        &nbsp;&nbsp;&nbsp;
                        <Form.Item
                          name={[subField.name, "always_active"]}
                          valuePropName="checked"
                          initialValue={false}
                        >
                          <Checkbox style={{ width: 130 }}>Always active</Checkbox>
                        </Form.Item>
                        &nbsp;&nbsp;&nbsp;
                        <CloseOutlined
                          disabled={disabled}
                          onClick={() => {
                            !disabled && subOpt.remove(subField.name);
                          }}
                          style={{ marginTop: 13 }}
                        />
                      </span>
                    </div>
                  ))}
                  <Button
                    type="dashed"
                    onClick={() => handleAddInstance(subFields, subOpt)}
                    block
                  >
                    + Add Sub Item
                  </Button>
                  <Form.ErrorList errors={errors} />
                </div>
              )}
            </Form.List>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default RouterEditModal;
