import {
  GetAllCfgsRawCombinedDomainEnum,
  SysAccountRegularDto,
  SysAccountUpdateDto,
} from "@/shared/api";
import {
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import { Stack } from "@mui/system";
import { useSysCfgCombinedQuery } from "@/api/hooks/sys-cfg-hooks";
import { ACCOUNT_RELATION_CONFIGS_NAMES, ACCOUNT_RELATION_SERVICE_CONFIGS_NAMES } from "@/lib/constants";
import { useForm } from "react-hook-form";
import { SysAccountEditForm } from "@/types/accountTypes";
import { z } from "zod";
import { convertEditFormToUpdateDto, mapSysAccountToEditForm } from "@/lib/accountsUtils";
import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { AutocompleteElement, PasswordElement, TextFieldElement } from "react-hook-form-mui";
import { ACC_OS_TP, ACC_TP, ACC_TP_LB } from "@/components/config-modals/router-edit-modal/constants";
import {useAtom} from "jotai/index";
import {meStore} from "@/store/store";
import {isOwner} from "@/lib/auth-predicates";
import CustomSelectOption from "@/components/custom-select-option";

const SysAccountWriteModal = ({
  mode,
  accountType,
  isOpen = false,
  roomName,
  defaultValues,
  onSave,
  onClose,
  confirmLoading = false,
  disabled = false
} : {
  mode: "add" | "edit",
  accountType: "service" | "regular",
  isOpen?: boolean;
  roomName: string;
  defaultValues?: SysAccountRegularDto;
  onSave: (name: string, account: SysAccountUpdateDto) => void;
  onClose: () => void;
  confirmLoading?: boolean;
  disabled?: boolean;
}) => {
  const [me] = useAtom(meStore);

  const schema = useMemo(() => {
    return z.object({
      name: z.string().min(1),
      authGroups: isOwner(me)
        ? z.array(z.string())
        : z.array(z.string()).min(1, "must contain at least one group"),
      password: z.string().min(1),
      walletCode: z.string().min(1, "Field is required").regex(/^\d+$/, "Only digits"),
      source: z.string(),
      osType: z.number({ invalid_type_error: "Field is required" }).int().min(0).max(2),
      accountType: z.number(),
      comment: z.string().nullable(),
      gameGroup: z.string().nullable(),
      cid: z.coerce.string().nullable(),
      proxy: z.string().nullable(),
      behaviour: z.string().nullable(),
      schedule: z.string().nullable(),
      gameType: z.string().nullable(),
      aiProfile: z.string().nullable(),
      aiProfileOb: z.string().nullable(),
      timingProfile: z.string().nullable(),
      timingProfileOb: z.string().nullable(),
      insuranceProfile: z.string().nullable(),
      insuranceProfileOb: z.string().nullable()
    });
  }, [me]);

  const { data: authGroupsRs, isLoading: authGroupsIsLoading } = useAuthGroupsQuery({
    enabled: isOpen
  });

  const authGroups = useMemo(() => {
    return authGroupsRs?.data ?? []
  }, [authGroupsRs]);

  const initAccount = useMemo<SysAccountEditForm>(
    () => {
      const rs = mapSysAccountToEditForm(defaultValues);

      if(mode === "add" && accountType === "regular") {
        rs.accountType = ACC_TP.REGULAR
      }

      if(authGroups.length === 1 && !isOwner(me)) {
        rs.authGroups = authGroups.map(it => it.name)
      }

      return rs
    },
    [accountType, defaultValues, mode, me, authGroups]
  );

  const accountTypes = useMemo<number[]>(() => {
    if(mode === "edit")
      return [ACC_TP.REGULAR, ACC_TP.MANAGER, ACC_TP.SCANNER, ACC_TP.GRABBER]

    if(mode === "add") {
      return accountType === "service"
        ? [ACC_TP.MANAGER, ACC_TP.SCANNER, ACC_TP.GRABBER]
        : [ACC_TP.REGULAR]
    }

    return [];
  }, [accountType, mode]);

  const {data: selectConfigsRs} = useSysCfgCombinedQuery({
    domain: roomName as GetAllCfgsRawCombinedDomainEnum,
    config_names: ACCOUNT_RELATION_CONFIGS_NAMES,
  }, {
    enabled: isOpen
  });

  const {data: selectServiceConfigsRs} = useSysCfgCombinedQuery({
    domain: GetAllCfgsRawCombinedDomainEnum.Services,
    config_names: ACCOUNT_RELATION_SERVICE_CONFIGS_NAMES,
  }, {
    enabled: isOpen
  });

  const configs = useMemo(
    () => new Map(
      (selectConfigsRs?.data ?? [])
        .concat(selectServiceConfigsRs?.data ?? [])
        .map(config => [config.config_name, config.configs])
    ),
    [selectConfigsRs, selectServiceConfigsRs]
  );

  const { control, reset, handleSubmit, formState: { errors } } =
    useForm<SysAccountEditForm>({
      mode: "onChange",
      defaultValues: {...initAccount},
      resolver: zodResolver(schema),
    });

  useEffect(() => {
    reset({...initAccount})
  }, [isOpen, initAccount, reset]);

  const submit = handleSubmit(
    (data) => {
      onSave(data.name, convertEditFormToUpdateDto(data))
    }
  );

  const handleSave = () => {
    void submit()
  }


  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {mode === "add" ? "Create account" : (`Edit account ${initAccount.name}`)}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ paddingTop: "5px", width: "400px" }}>

          {mode === "add" && (
            <TextFieldElement
              disabled={disabled}
              required
              name="name"
              control={control}
              label="Name"
              error={!!errors.walletCode}
              helperText={errors.walletCode?.message ?? " "}
              variant="standard"
            />
          )}

          <AutocompleteElement
            name="authGroups"
            loading={authGroupsIsLoading}
            control={control}
            label="Auth Groups"
            options={authGroups.map(it => it.name)}
            multiple
            required={!isOwner(me)}
            textFieldProps={{
              disabled: disabled,
              error: !!errors.authGroups,
              helperText: errors.authGroups?.message ?? " ",
              variant: "standard"
            }}
            autocompleteProps={{
              disabled: disabled || (authGroups.length === 1 && !isOwner(me)),
              renderOption: (props, option, { selected }) =>
                <CustomSelectOption label={option} selected={selected} props={props}/>
            }}
          />

          <PasswordElement
            name="password"
            required
            control={control}
            label="Password"
            error={!!errors.password}
            helperText={errors.password?.message ?? " "}
            variant="standard"
            disabled={disabled}
            renderIcon={disabled ? () => <></> : undefined}
          />

          {disabled ? (
            <PasswordElement
              name="walletCode"
              required
              control={control}
              label="Wallet Code"
              error={!!errors.walletCode}
              helperText={errors.walletCode?.message ?? " "}
              variant="standard"
              disabled={disabled}
              renderIcon={disabled ? () => <></> : undefined}
            />
          ) : (
            <TextFieldElement
              required
              name="walletCode"
              control={control}
              label="Wallet Code"
              error={!!errors.walletCode}
              helperText={errors.walletCode?.message ?? " "}
              variant="standard"
              disabled={disabled}
            />
          )}



          <TextFieldElement
            name="source"
            control={control}
            label="Source"
            error={!!errors.source}
            helperText={errors.source?.message ?? " "}
            variant="standard"
            disabled={disabled}
          />

          <TextFieldElement
            type="number"
            name="cid"
            control={control}
            label="CID"
            error={!!errors.cid}
            helperText={errors.cid?.message ?? " "}
            variant="standard"
            disabled={disabled}
          />

          <AutocompleteElement
            name="osType"
            control={control}
            label="OS Type"
            options={[ACC_OS_TP.ANY, ACC_OS_TP.IOS, ACC_OS_TP.ANDROID]}
            autocompleteProps={{
              disabled: disabled,
              getOptionLabel: option => ["Any", "IOS", "Android"][option],
              disableClearable: true
            }}
            textFieldProps={{
              disabled: disabled,
              variant: "standard",
              error: !!errors.osType,
              helperText: errors.osType?.message ?? " "
            }}
          />

          {(mode === "edit" || accountType === "service") && (
            <AutocompleteElement
              name="accountType"
              control={control}
              label="Account Type"
              options={accountTypes}
              autocompleteProps={{
                disabled: disabled,
                getOptionLabel: option => ACC_TP_LB.get(option) ?? "Unknown",
                disableClearable: true
              }}
              textFieldProps={{
                disabled: disabled,
                variant: "standard",
                error: !!errors.accountType,
                helperText: errors.accountType?.message ?? " "
              }}
            />
          )}


          <AutocompleteElement
            name="gameGroup"
            control={control}
            label="Game Group"
            options={configs.get("game_group")?.map(i => i.name) ?? []}
            autocompleteProps={{
              disabled: disabled,
            }}
            textFieldProps={{
              disabled: disabled,
              variant: "standard",
              error: !!errors.gameGroup,
              helperText: errors.gameGroup?.message ?? " "
            }}
          />

          {accountType === "regular" && (
            <>
              <AutocompleteElement
                name="proxy"
                control={control}
                label="Proxy"
                options={configs.get("proxy")?.map(i => i.name) ?? []}
                autocompleteProps={{
                  disabled: disabled,
                }}
                textFieldProps={{
                  disabled: disabled,
                  variant: "standard",
                  error: !!errors.proxy,
                  helperText: errors.proxy?.message ?? " "
                }}
              />

              <AutocompleteElement
                name="behaviour"
                control={control}
                label="Behavior"
                options={configs.get("behaviour")?.map(i => i.name) ?? []}
                autocompleteProps={{
                  disabled: disabled,
                }}
                textFieldProps={{
                  disabled: disabled,
                  variant: "standard",
                  error: !!errors.behaviour,
                  helperText: errors.behaviour?.message ?? " "
                }}
              />

              <AutocompleteElement
                name="schedule"
                control={control}
                label="Schedule"
                options={configs.get("schedule")?.map(i => i.name) ?? []}
                autocompleteProps={{
                  disabled: disabled,
                }}
                textFieldProps={{
                  disabled: disabled,
                  variant: "standard",
                  error: !!errors.schedule,
                  helperText: errors.schedule?.message ?? " "
                }}
              />

              <AutocompleteElement
                name="gameType"
                control={control}
                label="Game Type"
                options={configs.get("game_type")?.map(i => i.name) ?? []}
                autocompleteProps={{
                  disabled: disabled,
                }}
                textFieldProps={{
                  disabled: disabled,
                  variant: "standard",
                  error: !!errors.gameType,
                  helperText: errors.gameType?.message ?? " "
                }}
              />

              <AutocompleteElement
                name="aiProfile"
                control={control}
                label="AI Profile"
                options={configs.get("ai_profile")?.map(i => i.name) ?? []}
                autocompleteProps={{
                  disabled: disabled,
                }}
                textFieldProps={{
                  disabled: disabled,
                  variant: "standard",
                  error: !!errors.aiProfile,
                  helperText: errors.aiProfile?.message ?? " "
                }}
              />

              <AutocompleteElement
                name="aiProfileOb"
                control={control}
                label="AI Profile OB"
                options={configs.get("ai_profile")?.map(i => i.name) ?? []}
                autocompleteProps={{
                  disabled: disabled,
                }}
                textFieldProps={{
                  disabled: disabled,
                  variant: "standard",
                  error: !!errors.aiProfileOb,
                  helperText: errors.aiProfileOb?.message ?? " "
                }}
              />

              <AutocompleteElement
                name="insuranceProfile"
                control={control}
                label="Insurance Profile"
                options={configs.get("insurance")?.map(i => i.name) ?? []}
                autocompleteProps={{
                  disabled: disabled,
                }}
                textFieldProps={{
                  disabled: disabled,
                  variant: "standard",
                  error: !!errors.insuranceProfile,
                  helperText: errors.insuranceProfile?.message ?? " "
                }}
              />

              <AutocompleteElement
                name="insuranceProfileOb"
                control={control}
                label="Insurance Profile OB"
                options={configs.get("insurance")?.map(i => i.name) ?? []}
                autocompleteProps={{
                  disabled: disabled,
                }}
                textFieldProps={{
                  disabled: disabled,
                  variant: "standard",
                  error: !!errors.insuranceProfileOb,
                  helperText: errors.insuranceProfileOb?.message ?? " "
                }}
              />

            </>
          )}

          {accountType === "service" && (
            <TextFieldElement
              name="comment"
              control={control}
              label="Comment"
              error={!!errors.walletCode}
              helperText={errors.walletCode?.message ?? " "}
              variant="standard"
              disabled={disabled}
            />
          )}

        </Stack>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{width: "100px"}}
          variant="outlined"
          onClick={onClose}
        >Cancel</Button>

        <Button
          sx={{width: "100px"}}
          variant="contained"
          disabled={disabled || confirmLoading}
          startIcon={confirmLoading ? <CircularProgress size={20}/> : undefined}
          onClick={handleSave}
        >Save</Button>
      </DialogActions>

    </Dialog>
  )
}

export default SysAccountWriteModal;