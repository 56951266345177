import { Button, CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Stack } from "@mui/system";
import { FormContainer, PasswordElement, TextFieldElement, useForm } from "react-hook-form-mui";

import { CreateUserRequestDto } from "@/shared/api";
import { useEffect } from "react";

const CreateUserModal = (
  {
    open,
    onClose,
    onSave,
    isLoading = false
  } : {
    open: boolean;
    onClose: () => void;
    onSave: (data: CreateUserRequestDto) => void;
    isLoading?: boolean;
  }
) => {
  const form = useForm<CreateUserRequestDto>({
    defaultValues: {username: "", password: ""}
  });

  const onCloseHandler = () => {
    form.reset()
    onClose()
  }

  useEffect(() => {
    form.reset()
  }, [open, form])


  return (
    <Dialog open={open} onClose={onCloseHandler} fullWidth maxWidth="sm">
      <DialogTitle title="Create User">Create User</DialogTitle>
      <DialogContent style={{paddingTop: "5px"}}>
        <FormContainer formContext={form} onSuccess={val => onSave(val)}>
          <Stack spacing={2}>
            <TextFieldElement name="username" label="Username" required fullWidth/>
            <PasswordElement name="password" label="Password" required fullWidth/>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isLoading}
              startIcon={isLoading && <CircularProgress size={18} />}
            >Create User</Button>
          </Stack>
        </FormContainer>
      </DialogContent>
    </Dialog>
  );
};

export default CreateUserModal;