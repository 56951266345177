/* tslint:disable */

/* eslint-disable */

/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { AxiosInstance, AxiosPromise, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";

// @ts-ignore
import {
  BASE_PATH,
  BaseAPI,
  COLLECTION_FORMATS,
  RequestArgs,
  RequiredError,
  operationServerMap,
} from "../base";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  createRequestFunction,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from "../common";
import type { Configuration } from "../configuration";
// @ts-ignore
import { JoinOrLeaveClubsRequestDto } from "../models";
// @ts-ignore
import { QueryFiltersDto } from "../models";
// @ts-ignore
import { RequestReplayRequestDto } from "../models";
// @ts-ignore
import { UpdateAccountAvatarRequest } from "../models";
// @ts-ignore
import { UpdateNicknameRequestDto } from "../models";

/**
 * CommandControllerApi - axios parameter creator
 * @export
 */
export const CommandControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {AccountsLeaveClubsDomainEnum} domain
     * @param {JoinOrLeaveClubsRequestDto} joinOrLeaveClubsRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountsLeaveClubs: async (
      domain: AccountsLeaveClubsDomainEnum,
      joinOrLeaveClubsRequestDto: JoinOrLeaveClubsRequestDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("accountsLeaveClubs", "domain", domain);
      // verify required parameter 'joinOrLeaveClubsRequestDto' is not null or undefined
      assertParamExists(
        "accountsLeaveClubs",
        "joinOrLeaveClubsRequestDto",
        joinOrLeaveClubsRequestDto
      );
      const localVarPath = `/api/v2/command/accounts-leave-clubs/{domain}`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        joinOrLeaveClubsRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {JoinAccountsToClubsDomainEnum} domain
     * @param {JoinOrLeaveClubsRequestDto} joinOrLeaveClubsRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    joinAccountsToClubs: async (
      domain: JoinAccountsToClubsDomainEnum,
      joinOrLeaveClubsRequestDto: JoinOrLeaveClubsRequestDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("joinAccountsToClubs", "domain", domain);
      // verify required parameter 'joinOrLeaveClubsRequestDto' is not null or undefined
      assertParamExists(
        "joinAccountsToClubs",
        "joinOrLeaveClubsRequestDto",
        joinOrLeaveClubsRequestDto
      );
      const localVarPath = `/api/v2/command/join-accounts-to-clubs/{domain}`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        joinOrLeaveClubsRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {MigrateAccountsDomainEnum} domain
     * @param {string} gsId
     * @param {QueryFiltersDto} queryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    migrateAccounts: async (
      domain: MigrateAccountsDomainEnum,
      gsId: string,
      queryFiltersDto: QueryFiltersDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("migrateAccounts", "domain", domain);
      // verify required parameter 'gsId' is not null or undefined
      assertParamExists("migrateAccounts", "gsId", gsId);
      // verify required parameter 'queryFiltersDto' is not null or undefined
      assertParamExists("migrateAccounts", "queryFiltersDto", queryFiltersDto);
      const localVarPath = `/api/v2/command/migrate-accounts/{domain}/{gs-id}`
        .replace(`{${"domain"}}`, encodeURIComponent(String(domain)))
        .replace(`{${"gs-id"}}`, encodeURIComponent(String(gsId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        queryFiltersDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {RequestReplayDomainEnum} domain
     * @param {string} sessionId
     * @param {RequestReplayRequestDto} requestReplayRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestReplay: async (
      domain: RequestReplayDomainEnum,
      sessionId: string,
      requestReplayRequestDto: RequestReplayRequestDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("requestReplay", "domain", domain);
      // verify required parameter 'sessionId' is not null or undefined
      assertParamExists("requestReplay", "sessionId", sessionId);
      // verify required parameter 'requestReplayRequestDto' is not null or undefined
      assertParamExists("requestReplay", "requestReplayRequestDto", requestReplayRequestDto);
      const localVarPath = `/api/v2/command/request-replay/{domain}/{session-id}`
        .replace(`{${"domain"}}`, encodeURIComponent(String(domain)))
        .replace(`{${"session-id"}}`, encodeURIComponent(String(sessionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestReplayRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {RunAccountsDomainEnum} domain
     * @param {string} tesId
     * @param {QueryFiltersDto} queryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runAccounts: async (
      domain: RunAccountsDomainEnum,
      tesId: string,
      queryFiltersDto: QueryFiltersDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("runAccounts", "domain", domain);
      // verify required parameter 'tesId' is not null or undefined
      assertParamExists("runAccounts", "tesId", tesId);
      // verify required parameter 'queryFiltersDto' is not null or undefined
      assertParamExists("runAccounts", "queryFiltersDto", queryFiltersDto);
      const localVarPath = `/api/v2/command/run-accounts/{domain}/{tes-id}`
        .replace(`{${"domain"}}`, encodeURIComponent(String(domain)))
        .replace(`{${"tes-id"}}`, encodeURIComponent(String(tesId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        queryFiltersDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {StopAccountsDomainEnum} domain
     * @param {QueryFiltersDto} queryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopAccounts: async (
      domain: StopAccountsDomainEnum,
      queryFiltersDto: QueryFiltersDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("stopAccounts", "domain", domain);
      // verify required parameter 'queryFiltersDto' is not null or undefined
      assertParamExists("stopAccounts", "queryFiltersDto", queryFiltersDto);
      const localVarPath = `/api/v2/command/stop-accounts/{domain}`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        queryFiltersDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SwitchGsModeDomainEnum} domain
     * @param {string} gsId
     * @param {SwitchGsModeModeEnum} mode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    switchGsMode: async (
      domain: SwitchGsModeDomainEnum,
      gsId: string,
      mode: SwitchGsModeModeEnum,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("switchGsMode", "domain", domain);
      // verify required parameter 'gsId' is not null or undefined
      assertParamExists("switchGsMode", "gsId", gsId);
      // verify required parameter 'mode' is not null or undefined
      assertParamExists("switchGsMode", "mode", mode);
      const localVarPath = `/api/v2/command/switch-gs-mode/{domain}/{gs-id}/{mode}`
        .replace(`{${"domain"}}`, encodeURIComponent(String(domain)))
        .replace(`{${"gs-id"}}`, encodeURIComponent(String(gsId)))
        .replace(`{${"mode"}}`, encodeURIComponent(String(mode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SwitchTesModeDomainEnum} domain
     * @param {string} tesId
     * @param {SwitchTesModeModeEnum} mode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    switchTesMode: async (
      domain: SwitchTesModeDomainEnum,
      tesId: string,
      mode: SwitchTesModeModeEnum,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("switchTesMode", "domain", domain);
      // verify required parameter 'tesId' is not null or undefined
      assertParamExists("switchTesMode", "tesId", tesId);
      // verify required parameter 'mode' is not null or undefined
      assertParamExists("switchTesMode", "mode", mode);
      const localVarPath = `/api/v2/command/switch-tes-mode/{domain}/{tes-id}/{mode}`
        .replace(`{${"domain"}}`, encodeURIComponent(String(domain)))
        .replace(`{${"tes-id"}}`, encodeURIComponent(String(tesId)))
        .replace(`{${"mode"}}`, encodeURIComponent(String(mode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateAccountAvatarDomainEnum} domain
     * @param {string} sessionId
     * @param {UpdateAccountAvatarRequest} [updateAccountAvatarRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountAvatar: async (
      domain: UpdateAccountAvatarDomainEnum,
      sessionId: string,
      updateAccountAvatarRequest?: UpdateAccountAvatarRequest,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("updateAccountAvatar", "domain", domain);
      // verify required parameter 'sessionId' is not null or undefined
      assertParamExists("updateAccountAvatar", "sessionId", sessionId);
      const localVarPath = `/api/v2/command/update-account-avatar/{domain}/{session-id}`
        .replace(`{${"domain"}}`, encodeURIComponent(String(domain)))
        .replace(`{${"session-id"}}`, encodeURIComponent(String(sessionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateAccountAvatarRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateAccountNicknameDomainEnum} domain
     * @param {string} sessionId
     * @param {UpdateNicknameRequestDto} updateNicknameRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountNickname: async (
      domain: UpdateAccountNicknameDomainEnum,
      sessionId: string,
      updateNicknameRequestDto: UpdateNicknameRequestDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("updateAccountNickname", "domain", domain);
      // verify required parameter 'sessionId' is not null or undefined
      assertParamExists("updateAccountNickname", "sessionId", sessionId);
      // verify required parameter 'updateNicknameRequestDto' is not null or undefined
      assertParamExists(
        "updateAccountNickname",
        "updateNicknameRequestDto",
        updateNicknameRequestDto
      );
      const localVarPath = `/api/v2/command/update-account-nickname/{domain}/{session-id}`
        .replace(`{${"domain"}}`, encodeURIComponent(String(domain)))
        .replace(`{${"session-id"}}`, encodeURIComponent(String(sessionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateNicknameRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateAccountsAvatarsDomainEnum} domain
     * @param {QueryFiltersDto} queryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountsAvatars: async (
      domain: UpdateAccountsAvatarsDomainEnum,
      queryFiltersDto: QueryFiltersDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("updateAccountsAvatars", "domain", domain);
      // verify required parameter 'queryFiltersDto' is not null or undefined
      assertParamExists("updateAccountsAvatars", "queryFiltersDto", queryFiltersDto);
      const localVarPath = `/api/v2/command/update-accounts-avatars/{domain}`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        queryFiltersDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateAccountsNicknamesDomainEnum} domain
     * @param {QueryFiltersDto} queryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountsNicknames: async (
      domain: UpdateAccountsNicknamesDomainEnum,
      queryFiltersDto: QueryFiltersDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("updateAccountsNicknames", "domain", domain);
      // verify required parameter 'queryFiltersDto' is not null or undefined
      assertParamExists("updateAccountsNicknames", "queryFiltersDto", queryFiltersDto);
      const localVarPath = `/api/v2/command/update-accounts-nicknames/{domain}`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        queryFiltersDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CommandControllerApi - functional programming interface
 * @export
 */
export const CommandControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CommandControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {AccountsLeaveClubsDomainEnum} domain
     * @param {JoinOrLeaveClubsRequestDto} joinOrLeaveClubsRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async accountsLeaveClubs(
      domain: AccountsLeaveClubsDomainEnum,
      joinOrLeaveClubsRequestDto: JoinOrLeaveClubsRequestDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.accountsLeaveClubs(
        domain,
        joinOrLeaveClubsRequestDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["CommandControllerApi.accountsLeaveClubs"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {JoinAccountsToClubsDomainEnum} domain
     * @param {JoinOrLeaveClubsRequestDto} joinOrLeaveClubsRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async joinAccountsToClubs(
      domain: JoinAccountsToClubsDomainEnum,
      joinOrLeaveClubsRequestDto: JoinOrLeaveClubsRequestDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.joinAccountsToClubs(
        domain,
        joinOrLeaveClubsRequestDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["CommandControllerApi.joinAccountsToClubs"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {MigrateAccountsDomainEnum} domain
     * @param {string} gsId
     * @param {QueryFiltersDto} queryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async migrateAccounts(
      domain: MigrateAccountsDomainEnum,
      gsId: string,
      queryFiltersDto: QueryFiltersDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.migrateAccounts(
        domain,
        gsId,
        queryFiltersDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["CommandControllerApi.migrateAccounts"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {RequestReplayDomainEnum} domain
     * @param {string} sessionId
     * @param {RequestReplayRequestDto} requestReplayRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async requestReplay(
      domain: RequestReplayDomainEnum,
      sessionId: string,
      requestReplayRequestDto: RequestReplayRequestDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.requestReplay(
        domain,
        sessionId,
        requestReplayRequestDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["CommandControllerApi.requestReplay"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {RunAccountsDomainEnum} domain
     * @param {string} tesId
     * @param {QueryFiltersDto} queryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async runAccounts(
      domain: RunAccountsDomainEnum,
      tesId: string,
      queryFiltersDto: QueryFiltersDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.runAccounts(
        domain,
        tesId,
        queryFiltersDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["CommandControllerApi.runAccounts"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {StopAccountsDomainEnum} domain
     * @param {QueryFiltersDto} queryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stopAccounts(
      domain: StopAccountsDomainEnum,
      queryFiltersDto: QueryFiltersDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.stopAccounts(
        domain,
        queryFiltersDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["CommandControllerApi.stopAccounts"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {SwitchGsModeDomainEnum} domain
     * @param {string} gsId
     * @param {SwitchGsModeModeEnum} mode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async switchGsMode(
      domain: SwitchGsModeDomainEnum,
      gsId: string,
      mode: SwitchGsModeModeEnum,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.switchGsMode(
        domain,
        gsId,
        mode,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["CommandControllerApi.switchGsMode"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {SwitchTesModeDomainEnum} domain
     * @param {string} tesId
     * @param {SwitchTesModeModeEnum} mode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async switchTesMode(
      domain: SwitchTesModeDomainEnum,
      tesId: string,
      mode: SwitchTesModeModeEnum,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.switchTesMode(
        domain,
        tesId,
        mode,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["CommandControllerApi.switchTesMode"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {UpdateAccountAvatarDomainEnum} domain
     * @param {string} sessionId
     * @param {UpdateAccountAvatarRequest} [updateAccountAvatarRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAccountAvatar(
      domain: UpdateAccountAvatarDomainEnum,
      sessionId: string,
      updateAccountAvatarRequest?: UpdateAccountAvatarRequest,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccountAvatar(
        domain,
        sessionId,
        updateAccountAvatarRequest,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["CommandControllerApi.updateAccountAvatar"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {UpdateAccountNicknameDomainEnum} domain
     * @param {string} sessionId
     * @param {UpdateNicknameRequestDto} updateNicknameRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAccountNickname(
      domain: UpdateAccountNicknameDomainEnum,
      sessionId: string,
      updateNicknameRequestDto: UpdateNicknameRequestDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccountNickname(
        domain,
        sessionId,
        updateNicknameRequestDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["CommandControllerApi.updateAccountNickname"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {UpdateAccountsAvatarsDomainEnum} domain
     * @param {QueryFiltersDto} queryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAccountsAvatars(
      domain: UpdateAccountsAvatarsDomainEnum,
      queryFiltersDto: QueryFiltersDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccountsAvatars(
        domain,
        queryFiltersDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["CommandControllerApi.updateAccountsAvatars"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {UpdateAccountsNicknamesDomainEnum} domain
     * @param {QueryFiltersDto} queryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAccountsNicknames(
      domain: UpdateAccountsNicknamesDomainEnum,
      queryFiltersDto: QueryFiltersDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccountsNicknames(
        domain,
        queryFiltersDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["CommandControllerApi.updateAccountsNicknames"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * CommandControllerApi - factory interface
 * @export
 */
export const CommandControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CommandControllerApiFp(configuration);
  return {
    /**
     *
     * @param {AccountsLeaveClubsDomainEnum} domain
     * @param {JoinOrLeaveClubsRequestDto} joinOrLeaveClubsRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountsLeaveClubs(
      domain: AccountsLeaveClubsDomainEnum,
      joinOrLeaveClubsRequestDto: JoinOrLeaveClubsRequestDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .accountsLeaveClubs(domain, joinOrLeaveClubsRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {JoinAccountsToClubsDomainEnum} domain
     * @param {JoinOrLeaveClubsRequestDto} joinOrLeaveClubsRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    joinAccountsToClubs(
      domain: JoinAccountsToClubsDomainEnum,
      joinOrLeaveClubsRequestDto: JoinOrLeaveClubsRequestDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .joinAccountsToClubs(domain, joinOrLeaveClubsRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {MigrateAccountsDomainEnum} domain
     * @param {string} gsId
     * @param {QueryFiltersDto} queryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    migrateAccounts(
      domain: MigrateAccountsDomainEnum,
      gsId: string,
      queryFiltersDto: QueryFiltersDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .migrateAccounts(domain, gsId, queryFiltersDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {RequestReplayDomainEnum} domain
     * @param {string} sessionId
     * @param {RequestReplayRequestDto} requestReplayRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestReplay(
      domain: RequestReplayDomainEnum,
      sessionId: string,
      requestReplayRequestDto: RequestReplayRequestDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .requestReplay(domain, sessionId, requestReplayRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {RunAccountsDomainEnum} domain
     * @param {string} tesId
     * @param {QueryFiltersDto} queryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runAccounts(
      domain: RunAccountsDomainEnum,
      tesId: string,
      queryFiltersDto: QueryFiltersDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .runAccounts(domain, tesId, queryFiltersDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {StopAccountsDomainEnum} domain
     * @param {QueryFiltersDto} queryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopAccounts(
      domain: StopAccountsDomainEnum,
      queryFiltersDto: QueryFiltersDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .stopAccounts(domain, queryFiltersDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SwitchGsModeDomainEnum} domain
     * @param {string} gsId
     * @param {SwitchGsModeModeEnum} mode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    switchGsMode(
      domain: SwitchGsModeDomainEnum,
      gsId: string,
      mode: SwitchGsModeModeEnum,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .switchGsMode(domain, gsId, mode, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SwitchTesModeDomainEnum} domain
     * @param {string} tesId
     * @param {SwitchTesModeModeEnum} mode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    switchTesMode(
      domain: SwitchTesModeDomainEnum,
      tesId: string,
      mode: SwitchTesModeModeEnum,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .switchTesMode(domain, tesId, mode, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UpdateAccountAvatarDomainEnum} domain
     * @param {string} sessionId
     * @param {UpdateAccountAvatarRequest} [updateAccountAvatarRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountAvatar(
      domain: UpdateAccountAvatarDomainEnum,
      sessionId: string,
      updateAccountAvatarRequest?: UpdateAccountAvatarRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateAccountAvatar(domain, sessionId, updateAccountAvatarRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UpdateAccountNicknameDomainEnum} domain
     * @param {string} sessionId
     * @param {UpdateNicknameRequestDto} updateNicknameRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountNickname(
      domain: UpdateAccountNicknameDomainEnum,
      sessionId: string,
      updateNicknameRequestDto: UpdateNicknameRequestDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateAccountNickname(domain, sessionId, updateNicknameRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UpdateAccountsAvatarsDomainEnum} domain
     * @param {QueryFiltersDto} queryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountsAvatars(
      domain: UpdateAccountsAvatarsDomainEnum,
      queryFiltersDto: QueryFiltersDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateAccountsAvatars(domain, queryFiltersDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UpdateAccountsNicknamesDomainEnum} domain
     * @param {QueryFiltersDto} queryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountsNicknames(
      domain: UpdateAccountsNicknamesDomainEnum,
      queryFiltersDto: QueryFiltersDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateAccountsNicknames(domain, queryFiltersDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CommandControllerApi - object-oriented interface
 * @export
 * @class CommandControllerApi
 * @extends {BaseAPI}
 */
export class CommandControllerApi extends BaseAPI {
  /**
   *
   * @param {AccountsLeaveClubsDomainEnum} domain
   * @param {JoinOrLeaveClubsRequestDto} joinOrLeaveClubsRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommandControllerApi
   */
  public accountsLeaveClubs(
    domain: AccountsLeaveClubsDomainEnum,
    joinOrLeaveClubsRequestDto: JoinOrLeaveClubsRequestDto,
    options?: RawAxiosRequestConfig
  ) {
    return CommandControllerApiFp(this.configuration)
      .accountsLeaveClubs(domain, joinOrLeaveClubsRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {JoinAccountsToClubsDomainEnum} domain
   * @param {JoinOrLeaveClubsRequestDto} joinOrLeaveClubsRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommandControllerApi
   */
  public joinAccountsToClubs(
    domain: JoinAccountsToClubsDomainEnum,
    joinOrLeaveClubsRequestDto: JoinOrLeaveClubsRequestDto,
    options?: RawAxiosRequestConfig
  ) {
    return CommandControllerApiFp(this.configuration)
      .joinAccountsToClubs(domain, joinOrLeaveClubsRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MigrateAccountsDomainEnum} domain
   * @param {string} gsId
   * @param {QueryFiltersDto} queryFiltersDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommandControllerApi
   */
  public migrateAccounts(
    domain: MigrateAccountsDomainEnum,
    gsId: string,
    queryFiltersDto: QueryFiltersDto,
    options?: RawAxiosRequestConfig
  ) {
    return CommandControllerApiFp(this.configuration)
      .migrateAccounts(domain, gsId, queryFiltersDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RequestReplayDomainEnum} domain
   * @param {string} sessionId
   * @param {RequestReplayRequestDto} requestReplayRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommandControllerApi
   */
  public requestReplay(
    domain: RequestReplayDomainEnum,
    sessionId: string,
    requestReplayRequestDto: RequestReplayRequestDto,
    options?: RawAxiosRequestConfig
  ) {
    return CommandControllerApiFp(this.configuration)
      .requestReplay(domain, sessionId, requestReplayRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RunAccountsDomainEnum} domain
   * @param {string} tesId
   * @param {QueryFiltersDto} queryFiltersDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommandControllerApi
   */
  public runAccounts(
    domain: RunAccountsDomainEnum,
    tesId: string,
    queryFiltersDto: QueryFiltersDto,
    options?: RawAxiosRequestConfig
  ) {
    return CommandControllerApiFp(this.configuration)
      .runAccounts(domain, tesId, queryFiltersDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StopAccountsDomainEnum} domain
   * @param {QueryFiltersDto} queryFiltersDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommandControllerApi
   */
  public stopAccounts(
    domain: StopAccountsDomainEnum,
    queryFiltersDto: QueryFiltersDto,
    options?: RawAxiosRequestConfig
  ) {
    return CommandControllerApiFp(this.configuration)
      .stopAccounts(domain, queryFiltersDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SwitchGsModeDomainEnum} domain
   * @param {string} gsId
   * @param {SwitchGsModeModeEnum} mode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommandControllerApi
   */
  public switchGsMode(
    domain: SwitchGsModeDomainEnum,
    gsId: string,
    mode: SwitchGsModeModeEnum,
    options?: RawAxiosRequestConfig
  ) {
    return CommandControllerApiFp(this.configuration)
      .switchGsMode(domain, gsId, mode, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SwitchTesModeDomainEnum} domain
   * @param {string} tesId
   * @param {SwitchTesModeModeEnum} mode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommandControllerApi
   */
  public switchTesMode(
    domain: SwitchTesModeDomainEnum,
    tesId: string,
    mode: SwitchTesModeModeEnum,
    options?: RawAxiosRequestConfig
  ) {
    return CommandControllerApiFp(this.configuration)
      .switchTesMode(domain, tesId, mode, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpdateAccountAvatarDomainEnum} domain
   * @param {string} sessionId
   * @param {UpdateAccountAvatarRequest} [updateAccountAvatarRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommandControllerApi
   */
  public updateAccountAvatar(
    domain: UpdateAccountAvatarDomainEnum,
    sessionId: string,
    updateAccountAvatarRequest?: UpdateAccountAvatarRequest,
    options?: RawAxiosRequestConfig
  ) {
    return CommandControllerApiFp(this.configuration)
      .updateAccountAvatar(domain, sessionId, updateAccountAvatarRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpdateAccountNicknameDomainEnum} domain
   * @param {string} sessionId
   * @param {UpdateNicknameRequestDto} updateNicknameRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommandControllerApi
   */
  public updateAccountNickname(
    domain: UpdateAccountNicknameDomainEnum,
    sessionId: string,
    updateNicknameRequestDto: UpdateNicknameRequestDto,
    options?: RawAxiosRequestConfig
  ) {
    return CommandControllerApiFp(this.configuration)
      .updateAccountNickname(domain, sessionId, updateNicknameRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpdateAccountsAvatarsDomainEnum} domain
   * @param {QueryFiltersDto} queryFiltersDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommandControllerApi
   */
  public updateAccountsAvatars(
    domain: UpdateAccountsAvatarsDomainEnum,
    queryFiltersDto: QueryFiltersDto,
    options?: RawAxiosRequestConfig
  ) {
    return CommandControllerApiFp(this.configuration)
      .updateAccountsAvatars(domain, queryFiltersDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpdateAccountsNicknamesDomainEnum} domain
   * @param {QueryFiltersDto} queryFiltersDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommandControllerApi
   */
  public updateAccountsNicknames(
    domain: UpdateAccountsNicknamesDomainEnum,
    queryFiltersDto: QueryFiltersDto,
    options?: RawAxiosRequestConfig
  ) {
    return CommandControllerApiFp(this.configuration)
      .updateAccountsNicknames(domain, queryFiltersDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * @export
 */
export const AccountsLeaveClubsDomainEnum = {
  HhEco: "HH_ECO",
  PwEco: "PW_ECO",
  WpcEco: "WPC_ECO",
  AaEco: "AA_ECO",
  Services: "SERVICES",
} as const;
export type AccountsLeaveClubsDomainEnum =
  (typeof AccountsLeaveClubsDomainEnum)[keyof typeof AccountsLeaveClubsDomainEnum];
/**
 * @export
 */
export const JoinAccountsToClubsDomainEnum = {
  HhEco: "HH_ECO",
  PwEco: "PW_ECO",
  WpcEco: "WPC_ECO",
  AaEco: "AA_ECO",
  Services: "SERVICES",
} as const;
export type JoinAccountsToClubsDomainEnum =
  (typeof JoinAccountsToClubsDomainEnum)[keyof typeof JoinAccountsToClubsDomainEnum];
/**
 * @export
 */
export const MigrateAccountsDomainEnum = {
  HhEco: "HH_ECO",
  PwEco: "PW_ECO",
  WpcEco: "WPC_ECO",
  AaEco: "AA_ECO",
  Services: "SERVICES",
} as const;
export type MigrateAccountsDomainEnum =
  (typeof MigrateAccountsDomainEnum)[keyof typeof MigrateAccountsDomainEnum];
/**
 * @export
 */
export const RequestReplayDomainEnum = {
  HhEco: "HH_ECO",
  PwEco: "PW_ECO",
  WpcEco: "WPC_ECO",
  AaEco: "AA_ECO",
  Services: "SERVICES",
} as const;
export type RequestReplayDomainEnum =
  (typeof RequestReplayDomainEnum)[keyof typeof RequestReplayDomainEnum];
/**
 * @export
 */
export const RunAccountsDomainEnum = {
  HhEco: "HH_ECO",
  PwEco: "PW_ECO",
  WpcEco: "WPC_ECO",
  AaEco: "AA_ECO",
  Services: "SERVICES",
} as const;
export type RunAccountsDomainEnum =
  (typeof RunAccountsDomainEnum)[keyof typeof RunAccountsDomainEnum];
/**
 * @export
 */
export const StopAccountsDomainEnum = {
  HhEco: "HH_ECO",
  PwEco: "PW_ECO",
  WpcEco: "WPC_ECO",
  AaEco: "AA_ECO",
  Services: "SERVICES",
} as const;
export type StopAccountsDomainEnum =
  (typeof StopAccountsDomainEnum)[keyof typeof StopAccountsDomainEnum];
/**
 * @export
 */
export const SwitchGsModeDomainEnum = {
  HhEco: "HH_ECO",
  PwEco: "PW_ECO",
  WpcEco: "WPC_ECO",
  AaEco: "AA_ECO",
  Services: "SERVICES",
} as const;
export type SwitchGsModeDomainEnum =
  (typeof SwitchGsModeDomainEnum)[keyof typeof SwitchGsModeDomainEnum];
/**
 * @export
 */
export const SwitchGsModeModeEnum = {
  Production: "PRODUCTION",
  Test: "TEST",
  Unavailable: "UNAVAILABLE",
} as const;
export type SwitchGsModeModeEnum =
  (typeof SwitchGsModeModeEnum)[keyof typeof SwitchGsModeModeEnum];
/**
 * @export
 */
export const SwitchTesModeDomainEnum = {
  HhEco: "HH_ECO",
  PwEco: "PW_ECO",
  WpcEco: "WPC_ECO",
  AaEco: "AA_ECO",
  Services: "SERVICES",
} as const;
export type SwitchTesModeDomainEnum =
  (typeof SwitchTesModeDomainEnum)[keyof typeof SwitchTesModeDomainEnum];
/**
 * @export
 */
export const SwitchTesModeModeEnum = {
  Production: "PRODUCTION",
  Test: "TEST",
  Unavailable: "UNAVAILABLE",
} as const;
export type SwitchTesModeModeEnum =
  (typeof SwitchTesModeModeEnum)[keyof typeof SwitchTesModeModeEnum];
/**
 * @export
 */
export const UpdateAccountAvatarDomainEnum = {
  HhEco: "HH_ECO",
  PwEco: "PW_ECO",
  WpcEco: "WPC_ECO",
  AaEco: "AA_ECO",
  Services: "SERVICES",
} as const;
export type UpdateAccountAvatarDomainEnum =
  (typeof UpdateAccountAvatarDomainEnum)[keyof typeof UpdateAccountAvatarDomainEnum];
/**
 * @export
 */
export const UpdateAccountNicknameDomainEnum = {
  HhEco: "HH_ECO",
  PwEco: "PW_ECO",
  WpcEco: "WPC_ECO",
  AaEco: "AA_ECO",
  Services: "SERVICES",
} as const;
export type UpdateAccountNicknameDomainEnum =
  (typeof UpdateAccountNicknameDomainEnum)[keyof typeof UpdateAccountNicknameDomainEnum];
/**
 * @export
 */
export const UpdateAccountsAvatarsDomainEnum = {
  HhEco: "HH_ECO",
  PwEco: "PW_ECO",
  WpcEco: "WPC_ECO",
  AaEco: "AA_ECO",
  Services: "SERVICES",
} as const;
export type UpdateAccountsAvatarsDomainEnum =
  (typeof UpdateAccountsAvatarsDomainEnum)[keyof typeof UpdateAccountsAvatarsDomainEnum];
/**
 * @export
 */
export const UpdateAccountsNicknamesDomainEnum = {
  HhEco: "HH_ECO",
  PwEco: "PW_ECO",
  WpcEco: "WPC_ECO",
  AaEco: "AA_ECO",
  Services: "SERVICES",
} as const;
export type UpdateAccountsNicknamesDomainEnum =
  (typeof UpdateAccountsNicknamesDomainEnum)[keyof typeof UpdateAccountsNicknamesDomainEnum];
