import {
  useSysCfgChangeEnabledMutation,
  useSysCfgCreateMutation,
  useSysCfgDeleteMutation,
  useSysCfgUpdateMutation,
} from "@/api/hooks/sys-cfg-hooks";
import { useCallback, useMemo } from "react";
import {
  ChangeCfgEnabledDomainEnum,
  CreateCfgDomainEnum, ResultDtoLong,
  SysCfgCreateRequestDto,
  SysCfgRegularDto,
  SysCfgUpdateRequestDto,
  UpdateConfigDomainEnum,
} from "@/shared/api";
import { enqueueSnackbar } from "notistack";
import { MRT_TableInstance } from "material-react-table";
import { useJsonFormByFiltersQuery } from "@/api/hooks/json-form-hooks";
import { UiSchema } from "@/components/JsonForm/types/wrapperTypes";
import { AxiosResponse } from "axios";
import { BadRequestError } from "@/api/config/config";

export const useSaveConfig = (
  {
    roomName, cfgName, onSuccessCallback
  } : {
    roomName: string, cfgName: string, onSuccessCallback?: () => void
  }
) => {
  const { mutate: createCfgMutate } = useSysCfgCreateMutation();

  return useCallback((config: SysCfgCreateRequestDto) => {
    createCfgMutate({
      domain: roomName as CreateCfgDomainEnum,
      cfgName: cfgName,
      body: config
    }, {
      onSuccess: () => {
        enqueueSnackbar(`Config successfully added`, {variant: 'success'});
        onSuccessCallback && onSuccessCallback()
      },
      onError: (err) => {
        if(err instanceof BadRequestError) {
          enqueueSnackbar(`${err.message}`, {variant: 'error'});
        } else {
          enqueueSnackbar(`Operation failure: ${err.message}. Contact an administrator`, {variant: 'error'});
        }
        console.error("Adding config failure", err);
      }
    })
  }, [createCfgMutate, roomName, cfgName, onSuccessCallback])
}

export const useUpdateConfig = (
  {
    roomName, cfgName, onSuccessCallback
  } : {
    roomName: string, cfgName: string, onSuccessCallback?: () => void
  }
) => {
  const { mutate: updateCfgMutate } = useSysCfgUpdateMutation();

  return useCallback((name: string, body: SysCfgUpdateRequestDto) => {
    updateCfgMutate({
      domain: roomName as UpdateConfigDomainEnum,
      cfgName: cfgName,
      name: name,
      body: body
    }, {
      onSuccess: () => {
        enqueueSnackbar(`Config successfully updated`, {variant: 'success'});
        onSuccessCallback && onSuccessCallback()
      },
      onError: (err) => {
        enqueueSnackbar(`Operation failure: ${err.message}. Contact an administrator`, {variant: 'error'});
        console.error("Config edit failure", err);
      }
    })
  }, [cfgName, onSuccessCallback, roomName, updateCfgMutate])
}

export const useConfigChangeEnabled = (
  {
    roomName, cfgName, onSuccessCallback
  } : {
    roomName: string, cfgName: string, onSuccessCallback?: <T extends SysCfgRegularDto> (table: MRT_TableInstance<T>) => void
  }
) => {
  const { mutate: changeEnabledMutate } = useSysCfgChangeEnabledMutation();

  return useCallback(
    <T extends SysCfgRegularDto>(table: MRT_TableInstance<T>, enabled: boolean) => {
    const names = table.getSelectedRowModel().rows.map((item) => item.original.name);

    changeEnabledMutate({
      domain: roomName as ChangeCfgEnabledDomainEnum,
      cfgName: cfgName,
      enabled: enabled,
      filters: new Set([{key: "name", mode: "STRICT_IN", value: names as unknown as object}])
    }, {
      onSuccess: (response) => {
        enqueueSnackbar(`${response.data} elements has been updated`, {variant: 'success'});
        onSuccessCallback && onSuccessCallback(table)
      },
      onError: (err) => {
        enqueueSnackbar(`Operation failure: ${err.message}. Contact an administrator`, {variant: 'error'});
        console.error("Change enabled operation failure", err);
      }
    })
  }, [cfgName, changeEnabledMutate, onSuccessCallback, roomName])
}

export const useDeleteConfigs = (
  {
    roomName, cfgName, onSuccessCallback
  } : {
    roomName: string, cfgName: string, onSuccessCallback?: <T extends SysCfgRegularDto> (table: MRT_TableInstance<T>, response: AxiosResponse<ResultDtoLong, any>) => void
  }
) => {
  const { mutate: deleteCfgsMutate } = useSysCfgDeleteMutation();

  return useCallback(
    <T extends SysCfgRegularDto>(table: MRT_TableInstance<T>) => {
    const names = table.getSelectedRowModel().rows.map((item) => item.original.name);

    deleteCfgsMutate({
      domain: roomName as ChangeCfgEnabledDomainEnum,
      cfgName: cfgName,
      filters: new Set([{key: "name", mode: "STRICT_IN", value: names as unknown as object}])
    }, {

      onSuccess: (response) => {
        response.data.dataOnError ?
          enqueueSnackbar(`Something went wrong`, {variant: 'error'}) :
          enqueueSnackbar(`${response.data.data?.toString()} elements has been deleted`, {variant: 'success'});
        onSuccessCallback && onSuccessCallback(table, response)
      },
      onError: (err) => {
        enqueueSnackbar(`Operation failure: ${err.message}. Contact an administrator`, {variant: 'error'});
        console.error("Delete operation failure", err);
      }
    })
  }, [cfgName, deleteCfgsMutate, onSuccessCallback, roomName])
}

export const useGetJsonForm = ({
  roomName,
  configName
} : {
  roomName: string,
  configName: string,
}) => {
  const query = useJsonFormByFiltersQuery({
    keyFilters: [
      {key: "room_name", mode: "EQUALS", value: roomName as unknown as object},
      {key: "collection_name", mode: "EQUALS", value: configName as unknown as object}
    ]
  });

  const jsonFormSchema = useMemo(() => {
    return query.data?.data[0]?.uiSchemas[0]?.schema as (UiSchema | undefined)
  }, [query.data]);

  return {
    ...query,
    data: jsonFormSchema
  }
}