import {
  MaterialReactTable,
  MRT_ColumnFilterFnsState,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { FindDomainEnum, KeyFilter, KeySort } from "@/shared/api";
import { useSysAccountsQuery } from "@/api/hooks/sys-account-hooks";
import { ACC_TP } from "@/components/config-modals/router-edit-modal/constants";
import { useAccountsTable } from "@/module/accounts-module/hooks/accounts-table-hook";
import {
  useServiceAccountsTableColumns,
} from "@/module/accounts-module/service-accounts-table/service-accounts-table-columns";
import { COMMON_REFETCH_INTERVAL } from "@/lib/constants";
import ServiceAccountsTopToolbar from "@/module/accounts-module/components/service-accounts-top-toolbar";
import { assembleKeyFilters, assembleSort } from "@/lib/cfg-utils";

const requiredFilters: KeyFilter[] = [
  { key: "data.account_type", mode: "NOT_EQUALS", value: ACC_TP.REGULAR as unknown as object }
]

const ServiceAccountsTable = (
  {
    roomName,
    isOpen = true
  } : {
    roomName: string;
    isOpen?: boolean;
  }
) => {
  const columns = useServiceAccountsTableColumns({room: roomName});

  const [sorting, setSorting] =
    useState<MRT_SortingState>([{id: "created_at", desc: true}])
  const [columnFilters, setColumnFilters] =
    useState<MRT_ColumnFiltersState>([]);
  const [columnFilterFns, setColumnFilterFns] = useState<MRT_ColumnFilterFnsState>(
    Object.fromEntries(columns.map(({ id }) => [ id, 'contains' ]))
  )
  const [pagination, setPagination] =
    useState<MRT_PaginationState>({ pageIndex: 0, pageSize: 30 });

  const filters = useMemo<Set<KeyFilter>>(
    () => assembleKeyFilters(columnFilters, columnFilterFns, requiredFilters),
    [columnFilters, columnFilterFns]
  );

  const sort = useMemo<KeySort[]>(() => assembleSort(sorting), [sorting])

  const {
    data: sysAccountsResponse,
    isError: isSysAccountsError,
    isLoading: isSysAccountsLoading,
    isRefetching: isSysAccountsRefetching,
    refetch: refetchSysAccounts
  } = useSysAccountsQuery(
    {
      domain: roomName as FindDomainEnum,
      filters: filters,
      pagination,
      sort: sort,
    },
    {
      enabled: isOpen,
      refetchInterval: COMMON_REFETCH_INTERVAL
    }
  );


  const table = useAccountsTable({
    columns: columns,
    data: sysAccountsResponse?.data.content ?? [],
    isDataLoading: isSysAccountsLoading,
    isDataRefetching: isSysAccountsRefetching,
    isDataLoadingError: isSysAccountsError,
    loadingErrorMessage: sysAccountsResponse?.statusText,
    totalElements: sysAccountsResponse?.data.totalElements,
    totalPages: sysAccountsResponse?.data.totalPages,
    pagination: pagination,
    setPagination: setPagination,
    columnFilters: columnFilters,
    setColumnFilters: setColumnFilters,
    columnFilterFns: columnFilterFns,
    setColumnFilterFns: setColumnFilterFns,
    sorting: sorting,
    setSorting: setSorting,
    renderToolbarCustomActions: ({ table }) => (
      <ServiceAccountsTopToolbar
        table={table}
        roomName={roomName}
        filters={filters}
        refetchAccounts={() => refetchSysAccounts()}
        totalElementsCount={sysAccountsResponse?.data.totalElements ?? 0}
      />
    )
  });

  useEffect(() => {
    table.resetRowSelection();
  }, [table, columnFilters, sorting, pagination]);

  return (<MaterialReactTable table={table} />);
};

export default ServiceAccountsTable;
