import React, { useMemo } from "react";
import { MRT_ColumnDef } from "material-react-table";
import { SysAccountRegularDto } from "@/shared/api";
import { NilObj } from "@/types/commonTypes";
import { Tooltip } from "antd";
import {
  ACCOUNT_STAT_GAME_TYPE_KEYS,
  ACCOUNT_STAT_GAME_TYPES, ACCOUNT_STAT_ROW_ID_PREFIX,
  ACCOUNT_STATUS,
  APP_GMT_DATE_FORMAT,
} from "@/lib/constants";
import moment from "moment/moment";
import { Checkbox } from "@mui/material";
import { getTabTypeBySessionType, renderClubsCell } from "@/lib/accountsUtils";
import { Link } from "@tanstack/react-router";


export const useRegularAccountsTableColumns = (
  {
    room
  } : {
    room: string
  }
) => useMemo<MRT_ColumnDef<SysAccountRegularDto>[]>(() => {

  const gameTypesStats: MRT_ColumnDef<SysAccountRegularDto>[] =
    ACCOUNT_STAT_GAME_TYPES.flatMap(gameType =>
      ACCOUNT_STAT_GAME_TYPE_KEYS.map(stat => (
        {
          id: `${ACCOUNT_STAT_ROW_ID_PREFIX}.${gameType}.${stat}`,
          header: `${gameType.toUpperCase()} ${stat.toUpperCase()}`,
          accessorFn: ({ data }) => (data as any)?.aid_stats?.[gameType]?.[stat] ?? "-"
        }
      ))
    )

  return [
    {
      id: "name",
      header: "Name",
      accessorFn: (row) => row.name ?? "-"
    },
    {
      id: "data.source",
      header: "Source",
      accessorFn: ({data}) => (data as NilObj)?.source ?? "-",
    },
    {
      id: "data.pid",
      header: "Pid",
      accessorFn: ({data}) => (data as NilObj)?.pid ?? "-",
    },
    {
      id: "data.clubs",
      header: "Clubs",
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ row: { original } }) => renderClubsCell(original.data)
    },
    {
      id: "data.cid",
      header: "Cid",
      accessorFn: ({data}) => (data as NilObj)?.cid ?? "-",
    },
    {
      id: "data.aid",
      header: "Aid",
      accessorFn: ({data}) => (data as NilObj)?.aid ?? "-",
    },
    {
      id: "data.alc",
      header: "Alc",
      accessorFn: ({data}) => (data as NilObj)?.alc ?? "-",
    },
    {
      id: "data.gold",
      header: "Gold",
      accessorFn: ({data}) => (data as NilObj)?.gold ?? "-",
    },
    {
      id: "data.status",
      header: "Status",
      accessorFn: ({data}) => (data as NilObj)?.status ?? "-",
      Cell: ({ row: { original } }) => {
        const status = (original.data as NilObj)?.status as unknown;

        return status !== undefined && typeof status === "number" ? (
          <Tooltip title={ACCOUNT_STATUS.get(status)}>{status}</Tooltip>
        ) : "-";
      }
    },
    {
      id: "data.nick",
      header: "Nick",
      accessorFn: ({data}) => (data as NilObj)?.nick ?? "-",
    },
    {
      id: "created_at",
      header: "Created at",
      accessorFn: (row) => (row.created_at ? new Date(row.created_at) : 0),
      Cell: ({ row: { original: { created_at } } }) => created_at
        ? moment(created_at).utc(false).format(APP_GMT_DATE_FORMAT)
        : "-",
    },
    {
      id: "data.game_group",
      header: "Group",
      accessorFn: ({ data }) => (data as NilObj)?.game_group ?? "-",
    },
    {
      id: "data.game_type",
      header: "Game type",
      accessorFn: ({ data }) => (data as NilObj)?.game_type ?? "-",
    },
    {
      id: "data.proxy",
      header: "Proxy",
      accessorFn: ({ data }) => (data as NilObj)?.proxy ?? "-",
    },
    {
      id: "data.behaviour",
      header: "Behaviour",
      accessorFn: ({ data }) => (data as NilObj)?.behaviour ?? "-",
    },
    {
      id: "data.schedule",
      header: "Schedule",
      accessorFn: ({ data }) => (data as NilObj)?.schedule ?? "-",
    },
    {
      id: "data.ai_profile",
      header: "AI profile",
      accessorFn: ({ data }) => (data as NilObj)?.ai_profile ?? "-",
    },
    {
      id: "data.ai_profile_ob",
      header: "AI profile Ob",
      accessorFn: ({ data }) => (data as NilObj)?.ai_profile_ob ?? "-",
    },
    {
      id: "data.timing_profile",
      header: "Timing",
      accessorFn: ({ data }) => (data as NilObj)?.timing_profile ?? "-",
    },
    {
      id: "data.timing_profile_ob",
      header: "Timing Ob",
      accessorFn: ({ data }) => (data as NilObj)?.timing_profile_ob ?? "-",
    },
    {
      id: "data.insurance_profile",
      header: "Insurance",
      accessorFn: ({ data }) => (data as NilObj)?.insurance_profile ?? "-",
    },
    {
      id: "data.insurance_profile_ob",
      header: "Insurance Ob",
      accessorFn: ({ data }) => (data as NilObj)?.insurance_profile_ob ?? "-",
    },
    ...gameTypesStats,
    {
      id: "enabled",
      header: "Enabled",
      accessorFn: ({ enabled }) => enabled ?? "-",
      Cell: ({ row: { original: { enabled } } }) => (
        <Checkbox checked={enabled} disabled />
      ),
    },
    {
      id: "session",
      header: "Session",
      enableSorting: false,
      enableColumnFilter: false,
      Cell: ({ row: { original: { session } } }) => (
        session ? (
          <Link
            to="/account/$room/$type"
            params={{
              room: room,
              type: getTabTypeBySessionType(session.session_type),
            }}
            search={{
              session_id: session.id
            }}
          />
        ) : ( "-" )
      )
    }
  ]

}, [room])