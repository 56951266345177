import { MaterialReactTable } from "material-react-table";
import React from "react";


import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import RouterConfigModule from "@/module/room-configs-module/router-config-module/router-config-module";
import AiProfileConfigTable from "@/module/room-configs-module/ai-profile-config-table";
import ClusteringTypeConfigTable from "@/module/room-configs-module/clustering-type-config-table";
import CompensatorConfigTable from "@/module/room-configs-module/compensator/compensator-config-table";
import CommonConfigTable from "@/module/room-configs-module/common-config-table";
import ClusteringPatternModule from "@/module/room-configs-module/clustering-pattern-module/clustering-pattern-module";
import StatserviceModule from "@/module/service-configs-module/statservice-module/statservice-module";
import EmailDomainConfigTable from "@/module/service-configs-module/email-domain-module/email-domain-config-table";

const MainTable = ({
  cfg,
} : {
  cfg: string;
}) => {
  if (!cfg) return <MaterialReactTable columns={[]} data={[]} />;

  switch (cfg) {
    case ROOM_CONFIGS_NAMES.ROUTER:
      return <RouterConfigModule/>;
    case ROOM_CONFIGS_NAMES.AI_PROFILE:
      return <AiProfileConfigTable />;
    case ROOM_CONFIGS_NAMES.CLUSTERING_TYPE:
      return <ClusteringTypeConfigTable />;
    case ROOM_CONFIGS_NAMES.CLUSTERING_PATTERN:
      return <ClusteringPatternModule />;
    case ROOM_CONFIGS_NAMES.COMPENSATOR:
      return <CompensatorConfigTable />;
    case ROOM_CONFIGS_NAMES.EMAIL_DOMAIN:
      return <EmailDomainConfigTable />;
     case ROOM_CONFIGS_NAMES.STATSERVICE:
      return <StatserviceModule />;
    default:
      return <CommonConfigTable room={ROOM_NAMES.SERVICES} cfg={cfg} />;
  }
};

export default MainTable;
