import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import RouterInstanceTable
  from "@/module/room-configs-module/router-config-module/instance-table/router-instance-table";
import RouterRuleTable from "@/module/room-configs-module/router-config-module/rule-table/router-rule-table";
import { Route } from "@/routes/service-config/$configName"


const RouterConfigModule = () => {
  const navigate = Route.useNavigate();
  const {routerTab: tabValue} = Route.useSearch();

  const setTabValue = (value: number) => {
    void navigate({ search: { routerTab: value } })
  }


  const [rulesAmount, setRulesAmount] = useState<number>(0);
  const [instanceAmount, setInstanceAmount] = useState<number>(0);

  return <>
    <div style={{height: "100%", display: "flex", flexDirection: "column"}}>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs onChange={(_e, value) => setTabValue(value)} value={tabValue}>
          <Tab label={`Rules (${rulesAmount})`} value={0}/>
          <Tab label={`Instances (${instanceAmount})`} value={1}/>
        </Tabs>
      </Box>


      <div style={{ height: "100%" }}>
        <div hidden={tabValue !== 0} style={{ height: "100%" }}>
          <RouterRuleTable
            setRowsAmount={(amount: number) => setRulesAmount(amount)}
          />
        </div>

       <div hidden={tabValue !== 1} style={{ height: "100%" }}>
          <RouterInstanceTable
            setRowsAmount={(amount: number) => setInstanceAmount(amount)}
          />
        </div>

      </div>
    </div>
  </>
}

export default RouterConfigModule;