import {
  MaterialReactTable,
  MRT_ColumnFilterFnsState,
  MRT_ColumnFiltersState,
  MRT_PaginationState, MRT_Row,
  MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";


import { useSysStatserviceExportCsvMutation, useSysStatserviceQuery } from "@/api/hooks/sys-statservice-hooks";
import { useSearch } from "@tanstack/react-router";
import { SearchParams } from "@/types/commonTypes";
import {
  assembleFiltersFns,
  assembleRegularAccountsDefaultFilters,
} from "@/lib/accountsUtils";
import { KeyFilter, KeyFilterModeEnum, KeySort, ResultStatsDto } from "@/shared/api";
import {
  useStatserviceTableColumns,
} from "@/module/service-configs-module/statservice-module/statservice-table-columns";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import { message } from "antd";
import { FileDownload } from "@mui/icons-material";
import {
  assembleKeyFilters,
  assembleParticularFilterForStatserviceCsv,
  assembleSort,
} from "@/lib/cfg-utils";
import { Updater, VisibilityState } from "@tanstack/table-core";
import { statServiceRoomVariants } from "@/module/service-configs-module/statservice-module/statservice-module";
import { COMMON_REFETCH_INTERVAL } from "@/lib/constants";


const StatserviceTable = (
  {
    setRowsAmount,
    period,
    selectedRoom,
    setSelectedRoom,
  }: {
    setRowsAmount: (amount: number) => void;
    period: number;
    selectedRoom: string | null;
    setSelectedRoom: (value: string | null) => void;
  },
) => {
  console.log(period)
  const requiredFilters: KeyFilter[] = [];
  const defaultFilters: KeyFilter[] = [{
    key: "room",
    mode: "equals" as KeyFilterModeEnum,
    value: selectedRoom as unknown as object,
  }, {
      key: "technology",
      mode: "equals" as KeyFilterModeEnum,
      value: "HUMAN" as unknown as object,
  },
  ];

  //Storing table setting into localStorage
  const loadTablesSettingsFromLocalStorage = () => {
   const item = localStorage.getItem("tablesSettings");
   return item ? JSON.parse(item) : {};
  }

  const [allTablesUserSetting, setAllTablesUserSetting] = useState<any>(() => loadTablesSettingsFromLocalStorage());

  useEffect(() => {
    localStorage.setItem("tablesSettings", JSON.stringify(allTablesUserSetting))
  }, [allTablesUserSetting]);

  ///////////////////////////
  const columns = useStatserviceTableColumns();

  const searchParams: SearchParams = useSearch({ strict: false });

  const [sorting, setSorting] =
    useState<MRT_SortingState>([]);

  const [columnFilters, setColumnFilters] =
    useState<MRT_ColumnFiltersState>(() => assembleRegularAccountsDefaultFilters(searchParams).filter(it => it.id !== "statserviceTab")); // Просто searchParams преобразуюстся в массив объектов { id: it[0], value: it[1] } где id =

  const [columnFilterFns, setColumnFilterFns] = useState<MRT_ColumnFilterFnsState>(
    assembleFiltersFns({ columns, searchParams: searchParams }),
  );

  const [pagination, setPagination] =
    useState<MRT_PaginationState>({ pageIndex: 0, pageSize: 30 });

  const filters = useMemo<Set<KeyFilter>>(
    () => assembleKeyFilters(columnFilters, columnFilterFns, requiredFilters, defaultFilters),
    [columnFilters, columnFilterFns, defaultFilters],
  );

  const sort = useMemo<KeySort[]>(() => assembleSort(sorting), [sorting]);

  const {
    data: configsRs,
    isError: isDataLoadingError,
    isLoading: isDataLoading,
    refetch: refetchData,
    isRefetching: isDataRefetching,
  } = useSysStatserviceQuery({
    period: period,
    filters: new Set([...filters].filter(f => f.key !== "period")),
    pagination,
    sort: sort,
  }, {
    enabled: true,
    refetchInterval: COMMON_REFETCH_INTERVAL,
  });

  useEffect(() => {
    setRowsAmount(configsRs?.data.total_elements ?? 0);
  }, [configsRs, setRowsAmount]);

  ///////////START -Export to CSV
  const mutation = useSysStatserviceExportCsvMutation();

  function handleMutationAction(rows: MRT_Row<ResultStatsDto>[]) {
    mutateAll()
    if(rows.length > 0) {
      mutateParticular(rows)
    } else {
      mutateAll()
    }
  }

  function mutateAll() {
    callMutation(filters)
  }

  function mutateParticular(rows: MRT_Row<ResultStatsDto>[]) {
    callMutation(assembleParticularFilterForStatserviceCsv(rows))
  }

  function callMutation(filters: Set<KeyFilter>) {
    mutation.mutate({ period, filters }, {
      onError: (err) => {
        console.error("accounts csv export error", err)
        message.error("Something went wrong during exporting, contact the administrator").then()
      }
    })
  }
  ////END -Export to CSV


  const table = useMaterialReactTable({
    data: configsRs?.data.results ?? [],
    columns: columns,
    initialState: {
      pagination: { pageSize: 20, pageIndex: 0 },
      density: "compact",
      //sorting: [{id: "priority", desc: false}],
    },
    enableSelectAll: true,
    enableRowSelection: true,
    enableColumnDragging: false,
    enableColumnFilterModes: true,
    muiToolbarAlertBannerProps: isDataLoadingError ? {
      color: "error",
      children: `Error loading data ${configsRs?.statusText ? ":" + configsRs?.statusText : ""}`,
    } : undefined,

    enablePagination: true,
    manualPagination: true,
    rowCount: configsRs?.data.total_elements ?? 0,
    pageCount: configsRs?.data.total_pages ?? 0,

    enableStickyHeader: true,
    enableSorting: true,
    autoResetPageIndex: false,
    enableGlobalFilter: false,
    state: {
      pagination,
      columnFilterFns,
      columnFilters,
      sorting,
      columnVisibility: allTablesUserSetting?.statService?.columnVisibility ?? {},
      isLoading: isDataLoading,
      showProgressBars: isDataRefetching,
    },
    defaultColumn: {
      minSize: 20, //allow columns to get smaller than default
      maxSize: 40, //allow columns to get larger than default
      size: 40, //make columns wider by default
    },

    muiTablePaperProps: {
      sx: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
      },
    },
    muiTableContainerProps: {
      sx: {
        flexGrow: 10,
        overflowY: "scroll",
        height: "500px",
      },
    },
    muiTableBodyRowProps: ({ row }) => {
      const data = row.original;

      return (data as any)?.status !== 0 && !isDataLoading
        ? { sx: { backgroundColor: "#e9b1cd" } }
        : {};
    },

    onPaginationChange: (value) => {
      setPagination(value);
    },
    onColumnFiltersChange: setColumnFilters,
    onColumnFilterFnsChange: setColumnFilterFns,
    onSortingChange: setSorting,
    onColumnOrderChange: (newColumnOrder) => {
      setAllTablesUserSetting({
        ...allTablesUserSetting,
        statService: {...allTablesUserSetting.statService, columnOrder: (newColumnOrder as string[])},
      })

    },
    onColumnVisibilityChange: (updaterFn: Updater<VisibilityState> ) => {
      setAllTablesUserSetting((prev: any) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newVisibility = updaterFn(prev.statService?.columnVisibility);
        return {
          ...prev,
          statService: {...prev.statService, columnVisibility: newVisibility},
        };
      })
    },
    renderTopToolbarCustomActions: () => {
      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }} >
          <Autocomplete

            onChange={(event, newValue) => {
              setSelectedRoom(newValue);
            }}
            value={selectedRoom}
            options={statServiceRoomVariants}
            renderInput={(params) =>
              <TextField
                {...params}
                size="small"
                style={{ width: 200 }}
                label="Select the room"
                variant="outlined"
              />}
          />
          <IconButton
            size="small"
            style={{ fontSize: 12 }}
            color="secondary"
            onClick={() =>  handleMutationAction(table.getSelectedRowModel().rows)}
          >
            <FileDownload/> Export csv
            ({
            table.getSelectedRowModel().rows.length > 0
              ? (table.getSelectedRowModel().rows.length)
              : (configsRs?.data.total_elements)
          })
          </IconButton>
        </div>

      );
    },
    selectAllMode: "page",
  });


  useEffect(() => {
    table.resetRowSelection();
  }, [table, columnFilters, sorting, pagination]);

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};

export default StatserviceTable;
