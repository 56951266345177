import {createFileRoute, Navigate} from "@tanstack/react-router";
import DashboardPage from "@/pages/dashboard-page";
import CommandJournalAccessTemplate from "@/template/command-journal-access-template";
import {useAtom} from "jotai/index";
import {meStore} from "@/store/store";
import {isOwner} from "@/lib/auth-predicates";

export const Route = createFileRoute('/dashboard/')({
  component: Component,
})

function Component() {
  const [me] = useAtom(meStore);

  if(!isOwner(me)) {
    return <Navigate to={'/'}/>
  }

  return (
    <CommandJournalAccessTemplate>
      <DashboardPage/>
    </CommandJournalAccessTemplate>
  )
}
