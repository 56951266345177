import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";

import { Route } from "@/routes/service-config/$configName"
import StatserviceTable from "@/module/service-configs-module/statservice-module/statservice-table";

export const statServiceRoomVariants = [
  'PokerMaster',
  'FFPoker',
  'AAPoker',
  'WePokerClub',
  'PokerWorldEco',
  'PokerLife',
  'DPZX',
  'WePoker',
  'DDPoker',
  'EPoker',
  'Tpuke',
  'RedDragon'
];

const StatserviceModule = () => {
  const navigate = Route.useNavigate();
  const {statserviceTab: tabValue} = Route.useSearch();

  const setTabValue = (value: number) => {
    void navigate({ search: { statserviceTab: value } })
  }

  const [oneDayAmount, setOneDayAmount] = useState<number>(0);
  const [sevenDaysAmount, setSevenDaysAmount] = useState<number>(0);
  const [thirtyDaysAmount, setThirtyDaysAmount] = useState<number>(0);

  const [selectedRoom, setSelectedRoom] = useState<string | null>(null);
  useEffect(() => {
    console.log(selectedRoom)
  }, [selectedRoom]);
  return <>
    <div style={{height: "100%", display: "flex", flexDirection: "column"}}>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs onChange={(_e, value) => setTabValue(value)} value={tabValue}>
          <Tab label={`1 Day (${oneDayAmount})`} value={0}/>
          <Tab label={`7 Days (${sevenDaysAmount})`} value={1}/>
          <Tab label={`30 Days (${thirtyDaysAmount})`} value={2}/>
        </Tabs>
      </Box>


      <div style={{ height: "100%" }}>
        {(tabValue === 0) && <div hidden={tabValue !== 0} style={{ height: "100%" }}>
          <StatserviceTable
            setRowsAmount={(amount: number) => setOneDayAmount(amount)}
            period={1}
            selectedRoom={selectedRoom}
            setSelectedRoom={setSelectedRoom}
          />
        </div>}

        {(tabValue === 1) && <div hidden={tabValue !== 1} style={{ height: "100%" }}>
          <StatserviceTable
            setRowsAmount={(amount: number) => setSevenDaysAmount(amount)}
            period={7}
            selectedRoom={selectedRoom}
            setSelectedRoom={setSelectedRoom}
          />
        </div>}

        {(tabValue === 2) && <div hidden={tabValue !== 2} style={{ height: "100%" }}>
          <StatserviceTable
            setRowsAmount={(amount: number) => setThirtyDaysAmount(amount)}
            period={30}
            selectedRoom={selectedRoom}
            setSelectedRoom={setSelectedRoom}
          />
        </div>}

      </div>
    </div>
  </>
}

export default StatserviceModule;