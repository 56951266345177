import { useMemo } from "react";
import { MRT_ColumnDef } from "material-react-table";
import { SysCfgRegularDto } from "@/shared/api";


export const useEmailDomainTableColumns = () => useMemo<MRT_ColumnDef<SysCfgRegularDto>[]>(
  () => [
    {
      header: "Name",
      accessorKey: "name",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    }
  ],
  []
);