import { NumberOption, StringOption } from "../../../types/commonTypes";
import { EditedRoute } from "./types";

export const dataFieldName = "data";
export const room: StringOption[] = [
  { value: "hh", label: "hh" },
  { value: "pw", label: "pw" },
  { value: "ff", label: "ff" },
  { value: "wpc", label: "wpc" },
  { value: "wpk", label: "wpk" },
  { value: "pl", label: "pl" },
];
export const technology: StringOption[] = [
  { value: "aicon", label: "aicon" },
  { value: "adv", label: "adv" },
  { value: "eco", label: "eco" },
];
export const gameType: StringOption[] = [
  { value: "nlh", label: "nlh" },
  { value: "nlhs", label: "nlhs" },
  { value: "nlo", label: "nlo" },
  { value: "plo", label: "plo" },
  { value: "plo5", label: "plo5" },
  { value: "nlo5", label: "nlo5" },
  { value: "nlp", label: "nlp" },
  { value: "ofc", label: "ofc" },
  { value: "mtt", label: "mtt" },
  { value: "nlhb", label: "nlhb" },
  { value: "plob", label: "plob" },
  { value: "plo5b", label: "plo5b" },

  { value: "nlo5b", label: "nlo5b" },
  { value: "blob", label: "blob" },
];
export const sizeRange = [2, 9];
export const straddle: NumberOption[] = [
  { value: 0, label: "0" },
  { value: 2, label: "2" },
  { value: 4, label: "4" },
  { value: 6, label: "6" },
  { value: 8, label: "8" },
];

export const ACC_OS_TP = {
  ANY: 0,
  IOS: 1,
  ANDROID: 2
}

//account
export const osType: NumberOption[] = [
  {value: ACC_OS_TP.ANY, label: "Any"},
  {value: ACC_OS_TP.IOS, label: "IOS"},
  {value: ACC_OS_TP.ANDROID, label: "Android"},
];

export const ACC_TP = {
  REGULAR: 0,
  MANAGER: 1,
  SCANNER: 2,
  GRABBER: 3
}

export const ACC_TP_LB = new Map<number, string>([
  [ACC_TP.REGULAR, "Regular"],
  [ACC_TP.MANAGER, "Manager"],
  [ACC_TP.SCANNER, "Scanner"],
  [ACC_TP.GRABBER, "Grabber"],
])

export const accountType: NumberOption[] = [
  {value: ACC_TP.REGULAR, label: "Regular"},
  {value: ACC_TP.MANAGER, label: "Manager"},
  {value: ACC_TP.SCANNER, label: "Scanner"},
  {value: ACC_TP.GRABBER, label: "Grabber"},
];

export const playerCountRange = [2, 9];
export const aiType: StringOption[] = [
  { value: "aws", label: "AWS" },
  { value: "balancer", label: "Balancer" },
  { value: "greek", label: "Greek" },
  { value: "ofc", label: "Ofc" },
];
export const formInitState: EditedRoute = {
  name: "",
  authGroups: [],
  data: {
    priority: undefined,
    room: [],
    technology: [],
    only_bot: "",
    partner_ai_key: [],
    game_type: [],
    size: [],
    aid: [],
    cid: [],
    game_group: [],
    limit: [],
    straddle: [],
    ante: [],
    players_count: [],
    comment: "",
    single_instance: false,
    ai_type: aiType[0].value,
    group_pattern: [],
    instances: [],
  },
};

export const required = [{ required: true, message: "Field is required" }];

export const beforeSendNormalizers: ((val: EditedRoute) => EditedRoute)[] = [
  (value: EditedRoute) => {
    value.name = value.name.trim();
    return value;
  },
];
