import React, { useMemo, useState } from "react";
import { MaterialReactTable, MRT_ColumnDef, MRT_Row, useMaterialReactTable } from "material-react-table";
import { UpdateUserRequestDto, UserResponseRegularDto } from "@/shared/api";
import Tooltip from "@mui/material/Tooltip";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import { red } from "@mui/material/colors";
import { Button, Checkbox } from "@mui/material";
import { Delete, LockReset, Undo } from "@mui/icons-material";
import { useDeleteUsersMutation, useRevokeTokensMutation } from "@/api/hooks/user-hooks";
import { enqueueSnackbar } from "notistack";
import ChangeUserPasswordModal from "@/components/change-user-password-modal";
import { Route as UsersRoute } from "@/routes/users/index"
import { useIsSuperAdmin } from "@/auth/hooks";
import { Popconfirm } from "antd";

const redColor = red[500];

const UsersListTable = (
  {
    users,
    usersIsLoading,
    userIsRefetching,
    onUpdate,
    onDeleted
  } : {
    users: UserResponseRegularDto[];
    usersIsLoading: boolean;
    userIsRefetching: boolean;
    onUpdate: (user: UpdateUserRequestDto) => void;
    onDeleted: () => void;
  }
) => {
  const navigate = UsersRoute.useNavigate();
  // const navigate = useNavigate();
  const revokeTokensMutation = useRevokeTokensMutation();
  const deleteUsersMutation = useDeleteUsersMutation()
  const isSuperAdmin = useIsSuperAdmin();

  const [userId, setUserId] = useState<number>(0);
  const [changePasswordIsOpen, setChangePasswordIsOpen] = useState<boolean>(false);

  const columns = useMemo<MRT_ColumnDef<UserResponseRegularDto>[]>(
    () => [
      {
        id: "username",
        header: "Username",
        accessorFn: (row) => row.username,
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {!row.original.enabled && (
              <Tooltip title="disabled">
                <ErrorOutlinedIcon
                  style={{ width: 15, height: 15, color: redColor, marginRight: 3 }}
                />
              </Tooltip>
            )}
            <span>{row.original.username}</span>
          </div>
        ),
      },
      {
        id: "is_owner",
        header: "Is Owner",
        accessorFn: (row) => row.is_owner,
        Cell: ({ row: { original } }) => "" + (original.is_owner ?? "-"),
      },
      {
        id: "enabled",
        header: "Enabled",
        accessorFn: (row) => row.enabled,
        Cell: ({ row: { original } }) => (
          <Checkbox
            checked={original.enabled}
            onClick={event => {
              event.stopPropagation();
            }}
            onChange={event => {
              event.stopPropagation()
              event.preventDefault()
              onUpdate({ ...original, enabled: event.target.checked, group: Array.from(original.group) })
            }}
          />
        )
      }
    ],
    [onUpdate]
  );

  function handleRevokeTokens(rows: MRT_Row<UserResponseRegularDto>[]) {
    revokeTokensMutation.mutate(
      {
        userIds: rows.map(item => item.original.id)
      },
      {
        onSuccess: () => {
          enqueueSnackbar("User's tokens successfully revoked!", { variant: "success" })
        },
        onError: (err) => {
          enqueueSnackbar(`Something went wrong tokens revoking`, { variant: "error" })
          console.error("revoke tokens failure", err)
        }
      }
    )
  }

  function handleDeleteUsers(rows: MRT_Row<UserResponseRegularDto>[]) {
    deleteUsersMutation.mutate(
      {
        ids: rows.map(item => item.original.id)
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Users successfully deleted!", { variant: "success" })
          onDeleted()
        },
        onError: (err) => {
          enqueueSnackbar(`Something went wrong during processing request`, { variant: "error" })
          console.error("Users deletion failure", err)
        }
      }
    )
  }

  const table = useMaterialReactTable({
    data: users,
    columns: columns,
    getRowId: (originalRow) => String(originalRow.id),
    selectAllMode: "all",
    initialState: {
      pagination: { pageSize: 20, pageIndex: 0 },
      density: "compact",
    },
    state: {
      isLoading: usersIsLoading,
      showProgressBars: usersIsLoading,
    },
    enableSelectAll: true,
    enableRowSelection: true,
    enableGrouping: true,
    enableColumnDragging: false,
    enableColumnFilterModes: true,
    enablePagination: true,
    enableStickyHeader: true,
    enableSorting: true,
    autoResetPageIndex: false,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => navigate({ to: "/users/$id", params: { id: row.original.id }}),
      sx: {
        cursor: "pointer",
      },
    }),
    muiTablePaperProps: {
      sx: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
      },
    },
    muiTableContainerProps: {
      sx: { flexGrow: 10, overflowY: "scroll", height: "500px" },
    },
    renderTopToolbarCustomActions: ({table}) => {
      const rows = table.getSelectedRowModel().rows;
      return (
        <div>
          {rows.length > 0 && (
            <>
              <Button
                startIcon={<Undo/>}
                onClick={() => handleRevokeTokens(rows)}
              >Revoke tokens ({rows.length})</Button>

              {isSuperAdmin && (
                <Popconfirm
                  title="Are you sure you want to delete these users?"
                  onConfirm={() => handleDeleteUsers(rows)}
                >
                  <Button startIcon={<Delete/>}>Delete users ({rows.length})</Button>
                </Popconfirm>
              )}
            </>
          )}

          {rows.length === 1 && (
            <Button
              startIcon={<LockReset/>}
              color="secondary"
              onClick={() => {
                const userId = rows[0]?.original.id;
                if(userId) {
                  setUserId(userId)
                  setChangePasswordIsOpen(true)
                } else {
                  enqueueSnackbar(`No one user has been selected`, { variant: "error" })
                }
              }}
            >Change password</Button>
          )}
        </div>
      )
    }
  });

  return (
    <>
      <MaterialReactTable table={table} />
      <ChangeUserPasswordModal
        open={changePasswordIsOpen}
        userId={userId}
        onClose={() => setChangePasswordIsOpen(false)}
      />
    </>
  );
};

export default UsersListTable;