import { useMemo } from "react";
import { MRT_ColumnDef } from "material-react-table";
import { RouterInstanceDto } from "@/shared/api";


export const useRouterInstanceTableColumns = () => useMemo<MRT_ColumnDef<RouterInstanceDto>[]>(
  () => [
    {
      id: "instance",
      header: "Instance",
      accessorFn: (row) => row.instance ?? "-",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      id: "always_active",
      header: "Is always active",
      accessorFn: (row) => row.always_active?.toString() ?? "-",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      id: "probability",
      header: "Probability",
      accessorFn: (row) => row.probability ?? "-",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
    {
      id: "Status",
      header: "Status",
      sortingFn: "alphanumeric",
      filterFn: "contains",
      accessorFn: (row) => row.Status ?? "-",
      Cell: ({ row: { original }}) => {
        const color = original.Status.toUpperCase() === "READY"
          ? {color: "green"}
          : {color: "red"}
        return original.Status ? (
          <p style={{...color}}>{original.Status}</p>
        ) : ( "-" )
      }
    },
    {
      id: "UpdateAt",
      header: "Update at",
      accessorFn: (row) =>
        new Date(row.UpdateAt)
          .toLocaleString('ru-RU', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            fractionalSecondDigits: 3,
            timeZone: 'UTC',
            hour12: false,
          })
          .replace(',', '').replace(' ', ' ') ?? "-",
      sortingFn: "alphanumeric",
      filterFn: "contains",
    },
  ],
  []
);